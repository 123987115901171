import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {useLocation} from "react-router-dom";
import {useEventProvider} from "../../ServiceProviders/EventProvider";
import ProgressBar from "../../Dashboard/ProgressBar";
import {
    getEventLocations,
    setEventDates,
    deleteEventDate,
} from "../../ServiceProviders/EventAPIService";
import {usePopup} from "../../ServiceProviders/PopupProvider";

const EditEventDateComponent = () => {
    const {onEventChange, eventsData} = useEventProvider();
    const routerLocation = useLocation();
    const setPopup = usePopup();
    const showPopup = (message, severity, position) => {
        setPopup({open: true, message, severity, position});
    };

    const [isModified, setIsModified] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(
        JSON.parse(sessionStorage.getItem('selectedEvent')) || ''
    );
    const organizer = JSON.parse(sessionStorage.getItem('user'));
    const [organizersLocations, setOrganizersLocations] = useState([]);
    const [errors, setErrors] = useState({});

    const [inputFields, setInputFields] = useState([]);

    useEffect(() => {
        if (Array.isArray(selectedEvent?.eventDates)) {
            setInputFields(selectedEvent.eventDates.map(eventDate => {
                const eventDateObj = new Date(eventDate.date);
                const localDate = `${eventDateObj.getFullYear()}-${(eventDateObj.getMonth() + 1).toString().padStart(2, '0')}-${eventDateObj.getDate().toString().padStart(2, '0')}`;
                const localTime = `${eventDateObj.getHours().toString().padStart(2, '0')}:${eventDateObj.getMinutes().toString().padStart(2, '0')}`;

                return {
                    id: eventDate.id || null,
                    date: eventDate.date ? localDate : "",
                    time: eventDate.date ? localTime : "",
                    location: eventDate.location || null,
                };
            }));
        } else {
            console.error("selectedEvent.eventDates ist kein Array:", selectedEvent.eventDates);
            setSelectedEvent(prevState => ({
                ...prevState,
                eventDates: Array.isArray(prevState.eventDates) ? prevState.eventDates : []
            }));
            setInputFields([]);
        }
    }, [selectedEvent]);

    // Update bei eventsData changes
    useEffect(() => {
        if (routerLocation.state?.selectedEvent) {
            const eventName = routerLocation.state.selectedEvent;
            const matchedEvent = eventsData.find(event => event.name === eventName);
            setSelectedEvent(matchedEvent || null);
            console.log("matchedEvent: " + JSON.stringify(matchedEvent));
        }
    }, [eventsData, routerLocation.state]);

    // Update bei location changes
    useEffect(() => {
        if (!organizersLocations.length && organizer?.id) {
            getEventLocations(organizer, setPopup).then(locations => {
                setOrganizersLocations(locations);
            });
        }
    }, [organizersLocations, organizer]);

    const updateInputFields = (index, field, value) => {
        setInputFields(prevFields => {
            const updatedFields = [...prevFields];
            if (field === "date") {
                updatedFields[index].date = value;
            } else if (field === "time") {
                updatedFields[index].time = value;
            } else if (field === "location") {
                updatedFields[index].location = value;
            }
            return updatedFields;
        });
        setIsModified(true);
    };

    const handleAddEventDate = () => {
        console.log("Neues Event wird hinzugefügt");
        setInputFields(prevFields => [
            ...prevFields,
            {id: null, date: "", time: "", location: null}
        ]);
        setIsModified(true);
    };

    const validateInputFields = () => {
        let isValid = true;
        const newErrors = {};

        inputFields.forEach((field, index) => {
            if (!field.date) {
                newErrors[`date-${index}`] = "Datum ist erforderlich";
                isValid = false;
            }
            if (!field.time) {
                newErrors[`time-${index}`] = "Uhrzeit ist erforderlich";
                isValid = false;
            }
            if (!field.location) {
                newErrors[`location-${index}`] = "Standort ist erforderlich";
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleEventSave = async () => {
        if (!selectedEvent) return;

        const isValid = validateInputFields();
        if (!isValid) {
            showPopup("Bitte alle erforderlichen Felder ausfüllen.", "error", "center");
            return;
        }

        try {
            const data = inputFields.map((field, index) => ({
                id: selectedEvent.eventDates[index]?.id || 0,
                date: `${field.date}T${field.time}:00Z`,
                locationId: field.location?.id || null,
            }));

            let response = {};
            if (data.length > 0) {
                response = await setEventDates(selectedEvent.id, data, setPopup);  // Sync mit Backend
            }
            onEventChange(response);  // Sync mit EventProvider
            setSelectedEvent(response);
            sessionStorage.setItem("selectedEvent", JSON.stringify(response));

            setIsModified(false);
            showPopup("Termine aktualisiert", "success", "center");

        } catch (error) {
            console.error(error);
            setIsModified(true);
            showPopup("Termine konnten nicht aktualisiert werden", "error", "center");
        }
    };

    const handleDeleteEventDate = (index) => {
        const eventToDelete = inputFields[index];

        if (!eventToDelete.id) {
            // Event existiert nur im Frontend, also direkt entfernen
            setInputFields(prevFields => prevFields.filter((_, i) => i !== index));
        } else {
            // Event existiert bereits in der DB -> API-Call
            deleteEventDate(eventToDelete.id, setPopup);
            setInputFields(prevFields => prevFields.filter((_, i) => i !== index));
        }

        setIsModified(true);
    };

    const handleEventSwitch = (newValue, reason) => {
        if (reason === "clear") {
            setSelectedEvent('');
            sessionStorage.removeItem("selectedEvent");
            return;
        }
        setSelectedEvent(newValue);

        if (newValue && newValue.eventDates && newValue.eventDates.length > 0) {
            newValue.eventDates.map(eventDate => {
                setInputFields([{
                    date: eventDate.date ? new Date(eventDate.date).toISOString().split('T')[0] : "",
                    time: eventDate.date ? new Date(eventDate.date).toISOString().split('T')[1].slice(0, 5) : "",
                    location: eventDate.location || null,
                }]);
            })
            sessionStorage.setItem("selectedEvent", JSON.stringify(newValue));
            showPopup("Event erfolgreich geladen", "success", "center");
        } else {
            setInputFields([{
                date: "",
                time: "",
                location: null,
            }]);
            console.log("inputFields ", JSON.stringify(inputFields));
        }
    }

    return (
        <Grid item xs={12} sm={12} style={{marginBottom: '3%'}}>
            <Card sx={{borderRadius: '1rem', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', padding: 2}}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} textAlign="center">
                        <Typography variant="h4" gutterBottom>
                            Event bearbeiten
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} sm={12} spacing={2} alignItems="center">
                        {/* Dropdown */}
                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                options={eventsData}
                                getOptionLabel={(option) => option?.name || ''}
                                onChange={(event, newValue, reason) => handleEventSwitch(newValue, reason)}
                                value={selectedEvent || null}
                                renderInput={(params) => (
                                    <TextField {...params} label="Event auswählen" fullWidth/>
                                )}
                                className="mb-6"
                            />
                        </Grid>
                        {/* Speichern */}
                        <Grid item xs={12} sm={9}
                              style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  marginBottom: 'auto'
                              }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleEventSave}
                                disabled={!isModified}
                                style={{margin: '15px'}}
                            >
                                Speichern
                            </Button>
                        </Grid>
                    </Grid>
                    {/* Input Fields */}
                    <Grid container item
                        sx={{
                            maxHeight: '300px',
                            overflowY: 'auto',
                            marginBottom: '20px',
                            paddingRight: '10px',
                            height: '300px'
                        }}
                    >
                        {inputFields?.map((eventDate, index) => (
                            <Grid container item spacing={2} alignItems="center" key={index} sx={{
                                      marginBottom: '3%',
                                  }}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Datum"
                                        type="date"
                                        value={inputFields[index]?.date || ''}
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        error={!!errors[`date-${index}`]}
                                        onChange={(e) => updateInputFields(index, "date", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label="Uhrzeit"
                                        type="time"
                                        fullWidth
                                        value={inputFields[index]?.time || ''}
                                        InputLabelProps={{shrink: true}}
                                        error={!!errors[`time-${index}`]}
                                        onChange={(e) => updateInputFields(index, "time", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={organizersLocations}
                                        getOptionLabel={(option) => option?.description || ''}
                                        value={inputFields[index]?.location || null}
                                        onChange={(event, newValue) => updateInputFields(index, "location", newValue || null)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Standort"
                                                fullWidth
                                                error={!!errors[`location-${index}`]}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ProgressBar eventDateId={eventDate.id}/>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleDeleteEventDate(index, eventDate.id)}>
                                        <Delete color="error"/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddEventDate}
                        >
                            Termin hinzufügen
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};

export default EditEventDateComponent;