import React, {useState} from "react";
import {Button, Box, Typography} from "@mui/material";
import StageEditor from "./ElementEditor/StageEditor";
import SeatingGroupEditor from "./ElementEditor/SeatingGroupEditor";
import AddSeatingGroup from "./ElementEditor/AddSeatingGroup";

const EditorPanel = ({addStage, selectedElement, updateElement, onAddSeatingGroup, seatClasses}) => {
    const [showAddSeatingGroup, setShowAddSeatingGroup] = useState(false);

    const handleAddSeatingGroup = (groupData) => {
        onAddSeatingGroup(groupData);
        setShowAddSeatingGroup(false);
    };

    return (
        <Box
            sx={{
                width: 275,
                padding: 2,
                height: "100vh", // Stellt sicher, dass der gesamte Editor-Bereich sichtbar ist
                overflowY: "hidden",
                position: "relative", // Falls zusätzliche Positionierungen erforderlich sind
                marginBottom: '50%',
                overflowX: 'hidden'
            }}
        >
            {!showAddSeatingGroup && (<Typography variant="h6" gutterBottom>
                    Tools
                </Typography>

            )}
            {!showAddSeatingGroup ? (


                    <Box display="flex" flexDirection="column" gap={2}>
                        <Button variant="contained" onClick={() => setShowAddSeatingGroup(true)}>
                            Sitzgruppe hinzufügen
                        </Button>
                        <Button variant="contained" onClick={addStage}>
                            Bühne hinzufügen
                        </Button>
                    </Box>
                )
                :
                (
                    <AddSeatingGroup
                        onAdd={handleAddSeatingGroup}
                        onCancel={() => setShowAddSeatingGroup(false)}
                        seatClasses={seatClasses}
                    />
                )
            }

            {
                selectedElement && (
                    <Box mt={4} sx={{flexGrow: 1}}>
                        {selectedElement.type === "stage" && (
                            <StageEditor stage={selectedElement} updateStage={updateElement}/>
                        )}

                        {selectedElement.type === "seatingGroup" && (
                            <SeatingGroupEditor
                                seatingGroup={selectedElement}
                                updateSeatingGroup={updateElement}
                                seatClasses={seatClasses}
                            />
                        )}
                    </Box>
                )
            }
        </Box>
    )
        ;
};

export default EditorPanel;
