import React, {useEffect, useRef, useState} from "react";
import SeatingGroup from "./SeatingGroup";
import Stage from "./Stage";

const SeatingPlan = ({
                         seatingGroups,
                         seatSize,
                         minSpacing,
                         svgWidth,
                         svgHeight,
                         toggleSeat,
                         selectedSeats,
                         stages,
                         handleElementClick,
                         selectedElement,
                         updateElement,
                         bringElementToFront,
                         elementOrder
                     }) => {

    const groupRefs = useRef([]); // Array of refs for each group
    const [groupDimensions, setGroupDimensions] = useState({}); // Store dimensions for each group

    useEffect(() => {
        // Measure each group's bounding box after rendering
        const updatedDimensions = {};
        groupRefs.current.forEach((ref, index) => {
            if (ref) {
                const bbox = ref.getBBox(); // Measure the bounding box
                updatedDimensions[index] = {
                    width: bbox.width,
                    height: bbox.height,
                    x: bbox.x,
                    y: bbox.y,
                };
            }
        });
        setGroupDimensions(updatedDimensions);
    }, [seatingGroups]);

    const handleSvgClick = (event) => {
        // Check if the click target is the <svg> itself
        if (event.target === event.currentTarget) {
            handleElementClick(selectedElement);
        }
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={svgWidth} height={svgHeight} onClick={handleSvgClick}>
            {/*

                <g>
                    {stages.map((stageInfo, index) => (
                            <Stage key={`stage-${stageInfo.svgId}`}
                                   stageInfo={stageInfo}
                                   svgWidth={svgWidth}
                                   svgHeight={svgHeight}
                                   handleElementClick={() => handleElementClick(stageInfo)}
                                   selectedElement={selectedElement}
                                   updateElement={updateElement}
                                   bringElementToFront={bringElementToFront}
                            />
                        )
                    )}
                </g>

                <g>
                    {seatingGroups.map((group, groupIndex) =>
                        <SeatingGroup
                            key={`group-${group.svgId}`}
                            group={group}
                            groupIndex={groupIndex}
                            svgWidth={svgWidth}
                            svgHeight={svgHeight}
                            seatSize={seatSize}
                            minSpacing={minSpacing}
                            toggleSeat={toggleSeat}
                            selectedSeats={selectedSeats}
                            handleElementClick={() => handleElementClick(group)}
                            selectedElement={selectedElement}
                            updateElement={updateElement}
                            bringElementToFront={bringElementToFront}
                        />)}
                </g>
            */}
            {
                elementOrder.map(element => {
                    if (element === "stages") {
                        return (
                            <g>
                                {stages.map((stageInfo, index) => (
                                        <Stage key={`stage-${stageInfo.svgId}`}
                                               stageInfo={stageInfo}
                                               svgWidth={svgWidth}
                                               svgHeight={svgHeight}
                                               handleElementClick={() => handleElementClick(stageInfo)}
                                               selectedElement={selectedElement}
                                               updateElement={updateElement}
                                               bringElementToFront={bringElementToFront}
                                        />
                                    )
                                )}
                            </g>
                        )
                    } else if (element === "groups") {
                        return (
                            <g>
                                {seatingGroups.map((group, groupIndex) =>
                                    <SeatingGroup
                                        key={`group-${group.svgId}`}
                                        group={group}
                                        groupIndex={groupIndex}
                                        svgWidth={svgWidth}
                                        svgHeight={svgHeight}
                                        seatSize={seatSize}
                                        minSpacing={minSpacing}
                                        toggleSeat={toggleSeat}
                                        selectedSeats={selectedSeats}
                                        handleElementClick={() => handleElementClick(group)}
                                        selectedElement={selectedElement}
                                        updateElement={updateElement}
                                        bringElementToFront={bringElementToFront}
                                    />)}
                            </g>
                        )
                    }
                })
            }
        </svg>
    );
};

export default SeatingPlan
