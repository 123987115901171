import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const images = [
    require('../../../assets/images/Fischer/group.jpg'),
    require('../../../assets/images/Fischer/closeUp.jpg'),
    require('../../../assets/images/Fischer/quadratic.jpg')
];

const Slideshow = () => {
    return (
        <div className="w-full max-w-4xl mx-auto overflow-hidden rounded-xl">
            <Slide
                autoplay={true}
                duration={3000}
                transitionDuration={700}
                arrows={false}
                indicators={true}
            >
                {images.map((image, index) => (
                    <div key={index} className="flex justify-center items-center w-full h-auto">
                        <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-auto object-cover rounded-xl"
                        />
                    </div>
                ))}
            </Slide>
        </div>
    );
};

export default Slideshow;