import React from 'react';
import {Link} from 'react-router-dom';
import {Box, List, ListItem, ListItemText} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import globalTheme from '../../../globalTheme';
import { useEventProvider } from '../ServiceProviders/EventProvider';

const Sidebar = () => {
    const { events } = useEventProvider();
    return (
        <ThemeProvider theme={globalTheme}> {/* Theme nur für die Sidebar anwenden */}
            <Box
                sx={{
                    width: '250px',
                    height: '100vh',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    //bgcolor: '#2d99a1',
                    bgcolor: '#dbdbdb',
                    color: '#292929',
                    padding: 2,
                    marginTop: '3%',
                    fontSize: '1.2rem',
                }}
            >
                <List>
                    <ListItem button component={Link} to="/organizer/overview">
                        <ListItemText primary="Übersicht"/>
                    </ListItem>
                    <ListItem button component={Link}
                              to={{
                                  pathname: "/organizer/events",
                                  state: {
                                      activeTab: 0,
                                      events: events,
                                  }
                              }}
                    >
                        <ListItemText primary="Events" />
                    </ListItem>
                    <ListItem button component={Link} to="/organizer/locationEditor">
                        <ListItemText primary="Standorte"/>
                    </ListItem>
                    <ListItem button component={Link} to="/organizer/members">
                        <ListItemText primary="Mitarbeiter"/>
                    </ListItem>
                    <ListItem button component={Link}
                        to={{
                            pathname: "/organizer/seatingPlanEditor",
                            state: {
                                selectedEvent: {
                                    events: events,
                                    name: "servus",
                                    eventDates: events?.[0]?.eventDates || [] // Beispiel für die Event-Daten
                                }
                            }
                        }}
                    >
                        <ListItemText primary="Saalplan Editor" />
                    </ListItem>
                </List>
            </Box>
        </ThemeProvider>
    );
};

export default Sidebar;