import React, {useEffect, useState} from "react";
import {Box, Slider, TextField, Typography} from "@mui/material";

const StageEditor = ({stage, updateStage}) => {
    const [inputValues, setInputValues] = useState({
        relativeSizeX: stage.relativeSizeX || 0.7,
        relativeSizeY: stage.relativeSizeY || 0.3,
        text: stage.text || "",
        fontSize: stage.fontSize || 40,
        stageOpacity: stage.stageOpacity || 70,
    });

    useEffect(() => {
        setInputValues(() => ({
            relativeSizeX: stage.relativeSizeX,
            relativeSizeY: stage.relativeSizeY,
            text: stage.text,
            fontSize: stage.fontSize,
            stageOpacity: stage.stageOpacity,
        }));
    }, [stage]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        const updatedValue = name === "text" || value === "" ? value : parseFloat(value);

        setInputValues({
            ...inputValues,
            [name]: updatedValue,
        });

        updateStage({
            ...stage,
            [name]: updatedValue,
        });
    };

    const handleSliderChange = (e, newValue) => {
        setInputValues({...inputValues, stageOpacity: newValue});
        updateStage({...stage, stageOpacity: newValue});
    };

    if (!stage) {
        return <Typography>Keine Bühne ausgewählt.</Typography>;
    }

    return (
        <Box sx={{
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 2,
            marginTop: 2,
            overflowY: 'auto',
            maxHeight: 370
        }}>
            <Typography variant="h6" gutterBottom>
                Bühne bearbeiten
            </Typography>

            <Box sx={{mb: 2}}>
                <TextField
                    label="Breite [%]"
                    type="number"
                    name="relativeSizeX"
                    value={inputValues.relativeSizeX}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{step: 1, min: 0}}
                />
            </Box>

            <Box sx={{mb: 2}}>
                <TextField
                    label="Höhe [%]"
                    type="number"
                    name="relativeSizeY"
                    value={inputValues.relativeSizeY}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{step: 1, min: 0}}
                />
            </Box>

            <Box sx={{mb: 2}}>
                <TextField
                    label="Text"
                    type="text"
                    name="text"
                    value={inputValues.text}
                    onChange={handleInputChange}
                    fullWidth
                />
            </Box>

            <Box sx={{mb: 2}}>
                <TextField
                    label="relative Schriftgröße"
                    type="number"
                    name="fontSize"
                    value={inputValues.fontSize}
                    onChange={handleInputChange}
                    fullWidth
                />
            </Box>

            <Box sx={{mb: 2}}>
                <Typography gutterBottom>Transparenz: {inputValues.stageOpacity}%</Typography>
                <Slider
                    value={inputValues.stageOpacity}
                    onChange={handleSliderChange}
                    aria-labelledby="stage-opacity-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100}
                />
            </Box>
        </Box>
    );
};

export default StageEditor;
