import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography,
} from "@mui/material";

const SeatingGroupEditor = ({seatingGroup, updateSeatingGroup, seatClasses}) => {
    const [localName, setLocalName] = useState(seatingGroup.name);
    const [localRotation, setLocalRotation] = useState(seatingGroup.designInformation.rotation);
    const [selectedRow, setSelectedRow] = useState(1);
    const [newRowSeats, setNewRowSeats] = useState(5);
    const [orientation, setOrientation] = useState(seatingGroup.designInformation.orientation);
    const [showName, setShowName] = useState(seatingGroup.designInformation.showName || false);
    const [selectedSeatClass, setSelectedSeatClass] = useState(seatingGroup.seatClass.name);
    useEffect(() => {
        updateSeatingGroup({...seatingGroup, selectedRow: selectedRow});
    }, [selectedRow]);

    useEffect(() => {
        setLocalName(seatingGroup.name)
        setLocalRotation(seatingGroup.rotation)
        setOrientation(seatingGroup.designInformation.orientation)
        setShowName(seatingGroup.designInformation.showName)
        setSelectedSeatClass(seatingGroup.seatClass.name)
    }, [seatingGroup])

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setLocalName(newName);
        updateSeatingGroup({...seatingGroup, name: newName});
    };

    const handleRotationChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === "" || inputValue === "-" || !isNaN(parseFloat(inputValue))) {
            const newRotation = parseFloat(inputValue) % 360;
            setLocalRotation(newRotation);
            updateSeatingGroup({
                ...seatingGroup,
                designInformation: {...seatingGroup.designInformation, rotation: newRotation},
            });
        }
    };

    const handleOrientationChange = (e) => {
        const newOrientation = e.target.value;
        setOrientation(newOrientation);
        updateSeatingGroup({
            ...seatingGroup,
            designInformation: {...seatingGroup.designInformation, orientation: newOrientation},
        });
    };

    const handleSeatClassChange = (e) => {
        const newSeatClass = seatClasses.find(seatClass => seatClass.name === e.target.value);
        setSelectedSeatClass(newSeatClass.name);
        updateSeatingGroup({...seatingGroup, seatClass: newSeatClass});
    };


    const handleSeatOffsetChange = (e) => {
        const offset = parseFloat(e.target.value) || 0;

        const updatedRows = seatingGroup.rows.map((row) => {
            if (row.rowNumber === selectedRow) {
                return {...row, seatOffset: offset};
            }
            return row;
        });

        updateSeatingGroup({...seatingGroup, rows: updatedRows});
    };

    const handleRowChange = (e) => {
        setSelectedRow(parseInt(e.target.value));
    };

    const addSeat = () => {
        const updatedRows = seatingGroup.rows.map((row) => {
            if (row.rowNumber === selectedRow) {
                const newSeat = {
                    seatNumber: row.seats.length + 1,
                    type: {name: "Standard", price: 0},
                    status: "AVAILABLE",
                };
                return {...row, seats: [...row.seats, newSeat]};
            }
            return row;
        });

        updateSeatingGroup({...seatingGroup, rows: updatedRows});
    };

    const removeSeat = () => {
        const updatedRows = seatingGroup.rows.map((row) => {
            if (row.rowNumber === selectedRow && row.seats.length > 0) {
                return {...row, seats: row.seats.slice(0, -1)};
            }
            return row;
        });

        updateSeatingGroup({...seatingGroup, rows: updatedRows});
    };

    const addRow = () => {
        const newRowNumber = seatingGroup.rows.length + 1;
        const newSeats = Array.from({length: newRowSeats}, (_, index) => ({
            seatNumber: index + 1,
            type: {name: "Standard", price: 0},
            status: "AVAILABLE",
        }));
        const newRow = {
            rowNumber: newRowNumber,
            seats: newSeats,
            seatOffset: 0,
            spaceTop: 0,
            spaceBottom: 0,
        };

        updateSeatingGroup({...seatingGroup, rows: [...seatingGroup.rows, newRow]});
    };

    const deleteRow = () => {
        const updatedRows = seatingGroup.rows.filter((row) => row.rowNumber !== selectedRow);
        const renumberedRows = updatedRows.map((row, index) => ({
            ...row,
            rowNumber: index + 1,
        }));

        updateSeatingGroup({...seatingGroup, rows: renumberedRows});
        setSelectedRow(renumberedRows.length > 0 ? 1 : null);
    };

    const handleShowNameChange = () => {
        const newShowName = !showName;
        setShowName(newShowName);
        updateSeatingGroup({
            ...seatingGroup,
            designInformation: {...seatingGroup.designInformation, showName: newShowName},
        });
    };

    return (
        <Box sx={{
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 2,
            marginTop: 2,
            overflowY: 'auto',
            maxHeight: 370
        }}>
            <Typography variant="h6" gutterBottom>
                Sitzgruppe bearbeiten
            </Typography>

            <Box sx={{mb: 2}}>
                <TextField
                    label="Name"
                    value={localName}
                    onChange={handleNameChange}
                    fullWidth
                />
            </Box>

            <Box sx={{mb: 2}}>
                <TextField
                    label="Rotation (°)"
                    type="number"
                    value={localRotation}
                    onChange={handleRotationChange}
                    fullWidth
                />
            </Box>

            <Box sx={{mb: 2}}>
                <FormControl fullWidth>
                    <InputLabel>Orientierung</InputLabel>
                    <Select value={orientation} onChange={handleOrientationChange}>
                        <MenuItem value="LEFT">Links</MenuItem>
                        <MenuItem value="CENTER">Mitte</MenuItem>
                        <MenuItem value="RIGHT">Rechts</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{mb: 2}}>
                <FormControl fullWidth>
                    <InputLabel>Sitzklasse</InputLabel>
                    <Select value={selectedSeatClass} onChange={handleSeatClassChange}>
                        {seatClasses.map((seatClass) => (
                            <MenuItem key={seatClass.id} value={seatClass.name}>
                                {seatClass.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{mb: 2}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showName}
                            onChange={handleShowNameChange}
                        />
                    }
                    label="Name anzeigen"
                />
            </Box>

            <Box sx={{mb: 2}}>
                <FormControl fullWidth>
                    <InputLabel>Reihe auswählen</InputLabel>
                    <Select value={selectedRow} onChange={handleRowChange}>
                        {seatingGroup.rows.map((row) => (
                            <MenuItem key={row.rowNumber} value={row.rowNumber}>
                                Reihe {row.rowNumber}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="error"
                    onClick={deleteRow}
                    sx={{mt: 2}}
                    disabled={seatingGroup.rows.length === 0}
                >
                    Reihe löschen
                </Button>
            </Box>

            <Box sx={{mb: 2}}>
                <TextField
                    label="Sitzversatz in Sitzen"
                    type="number"
                    value={
                        seatingGroup.rows.find((row) => row.rowNumber === selectedRow)?.seatOffset ||
                        0
                    }
                    onChange={handleSeatOffsetChange}
                    fullWidth
                    slotProps={{
                        input: {
                            step: 0.5,
                        },
                    }}
                />
            </Box>

            <Box sx={{display: "flex", gap: 2, mb: 2}}>
                <Button variant="contained" onClick={addSeat}>
                    neuer Sitz
                </Button>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={removeSeat}
                    disabled={seatingGroup.rows[selectedRow - 1]?.seats.length === 0}
                >
                    Sitz löschen
                </Button>
            </Box>

            <Box sx={{mb: 2}}>
                <TextField
                    label="Sitze in neuer Reihe"
                    type="number"
                    value={newRowSeats}
                    onChange={(e) => setNewRowSeats(parseInt(e.target.value) || 0)}
                    fullWidth
                />
                <Button
                    variant="contained"
                    onClick={addRow}
                    sx={{mt: 2}}
                >
                    Reihe hinzufügen
                </Button>
            </Box>
        </Box>
    );
};

export default SeatingGroupEditor;
