import React, {useState} from "react";
import api from "../../../api";
import {useGeneralPopup} from "../PopupComponents/GeneralPopupProvider";

const ForgotPassword = ({goBack}) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const showPopup = useGeneralPopup();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await api.post("/public/user/requestResetPassword", {email});
            showPopup("Falls diese E-Mail existiert, wurde eine Anleitung zum Zurücksetzen des Passworts gesendet.", "success");
        } catch (error) {
            showPopup("Fehler beim Zurücksetzen des Passworts. Bitte versuchen Sie es erneut.", "error");
        }
        setLoading(false);
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Passwort vergessen?</h2>
            <p className="text-gray-600 mb-4">Gib deine E-Mail-Adresse ein, um einen Link zum Zurücksetzen deines
                Passworts zu erhalten.</p>
            <form onSubmit={handleSubmit} className="flex flex-col items-center">
                <input
                    type="email"
                    placeholder="E-Mail-Adresse"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full p-3 mb-4 border-b-2 border-gray-500 focus:border-blue-600 focus:outline-none text-lg placeholder-gray-500"
                />
                <button
                    type="submit"
                    className={`w-full p-3 mb-4 font-semibold text-white rounded-lg text-lg focus:outline-none ${
                        loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
                    }`}
                    disabled={loading}
                >
                    {loading ? "Senden..." : "Passwort zurücksetzen"}
                </button>
            </form>
            <button onClick={goBack} className="text-blue-500 hover:underline">Zurück zum Login</button>
        </div>
    );
};

export default ForgotPassword;
