import React from "react";

const SeatRow = ({
                     row,
                     rowIndex,
                     seatSize,
                     minSpacing,
                     toggleSeat,
                     selectedSeats,
                     groupPosition,
                     groupDesign,
                     maxSeatsRow,
                     showTooltip,
                     hideTooltip
                 }) => {

    const orientation = groupDesign.orientation;
    const maxSeats=maxSeatsRow.seats.length

    return row.seats.map((seat, seatIndex) => {
        const isAvailable = seat.status === "AVAILABLE";
        const fillColor = isAvailable
            ? selectedSeats.some(s => s.id === seat.id)
                ? 'yellow'
                : 'white'
            : 'red';

        let x;
        const totalRowWidth = row.seats.length * seatSize + (row.seats.length - 1) * minSpacing; // Gesamte Breite der Sitzreihe

        switch (orientation) {
            case "LEFT":
                seatIndex=seatIndex+row.seatOffset
                x = groupPosition.x + seatIndex * (seatSize + minSpacing);
                break;

            case "RIGHT":
                seatIndex = (maxSeats - row.seats.length)+seatIndex+row.seatOffset;

                x = groupPosition.x + seatIndex * (seatSize + minSpacing);
                break;

            case "CENTER":
                const center=maxSeats/2
                seatIndex=(center-row.seats.length/2)+seatIndex+row.seatOffset;


                x = groupPosition.x + seatIndex * (seatSize + minSpacing);
                break;

            default:
                x = groupPosition.x + seatIndex * (seatSize + minSpacing); // Fallback auf LEFT
                break;
        }

        // y-Koordinate bleibt gleich
        const y = groupPosition.y + rowIndex * (seatSize + minSpacing);


        return (
            <g
                key={seat.id}
                onClick={() => toggleSeat(seat, isAvailable, row.rowNumber, row.group)}
                onMouseEnter={() => showTooltip(seat, x, y, row.rowNumber, row.group.name)}
                onMouseLeave={hideTooltip}
            >
                <rect
                    id={seat.seatNumber}
                    x={x}
                    y={y}
                    width={seatSize}
                    height={seatSize}
                    fill={fillColor}
                    stroke="black"
                />
            </g>
        );
    });
};

export default SeatRow;