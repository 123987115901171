import React, {useEffect, useState} from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    CircularProgress,
    Tabs,
    Tab,
    Box,
    Collapse
} from '@mui/material';
import Header from "../../General/HeaderComponents/Header";
import {useNavigate} from "react-router-dom";
import MyTicketsComponent from "./MyTicketsComponent";
import EditCustomerInformationComponent from "./EditCustomerInformationComponent";
import {GeneralPopupProvider, useGeneralPopup} from "../../General/PopupComponents/GeneralPopupProvider";
import {getAllTickets, getCustomersPaymentsWithTickets, getCustomersTicket} from "./CustomerAPIService";

const CustomerHomeView = () => {
    const [customersPayments, setCustomersPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();
    const showPopup = useGeneralPopup();
    const user = JSON.parse(sessionStorage.getItem("user"));

    useEffect(() => {

        getCustomersPaymentsWithTickets(user.id).then((data) => {
            setCustomersPayments(data);
            setLoading(false);
        })
    }, []);

    const handleTabChange = (event, newValue) => {
        try {
            if (newValue === 2) {
                showPopup("Sie wurden ausgeloggt", "info"); // Hier wird showPopup aufgerufen
                sessionStorage.clear();
                navigate('/');
            } else {
                setTabValue(newValue);
            }
        } catch (e) {
            console.log("Error changing Tab: " + e);
        }
    };

    if (loading) {
        return (
            <Container className="flex justify-center items-center h-screen">
                <CircularProgress/>
            </Container>
        );
    }

    return (
        <GeneralPopupProvider>
            <div>
                <Header/>
                <Container className="py-8 my-10 mx-auto">

                    <Tabs value={tabValue} onChange={handleTabChange} centered>
                        <Tab label="Meine Tickets"/>
                        <Tab label="Meine Informationen"/>
                        <Tab label="Logout" sx={{color: '#ff6961'}}/>
                    </Tabs>

                    <Box className="mt-4">
                        {tabValue === 0 && (
                            <MyTicketsComponent customersPayments={customersPayments}/>
                        )}
                        {tabValue === 1 && (
                            <EditCustomerInformationComponent />
                        )}
                    </Box>
                </Container>
            </div>
        </GeneralPopupProvider>
    );
};

export default CustomerHomeView;