import React, {useEffect, useState} from 'react';
import SeatingPlanEditor from "../SeatingPlanEditor/SeatingPlanEditor";
import {useLocation} from "react-router-dom";
import {useEventProvider} from "../ServiceProviders/EventProvider";

const SeatingPlanEditorOrganizer = () => {
    const location = useLocation();
    const {eventsData, onEventChange} = useEventProvider();
    const [isLoaded, setIsLoaded] = useState(false);

    const selectedEventObject = location.state?.selectedEvent;
    const selectedEventName = selectedEventObject?.name;

    const selectedDate = null;  // TODO richtigstellen

    useEffect(() => {
        window.scrollTo(0, 0);

        // Überprüfe, ob eventsData geladen ist
        if (eventsData) {
            setIsLoaded(true);
        }
    }, [eventsData]);

    if (!isLoaded) {
        return <div className="mt-[5%]">Loading...</div>; // Ladeanzeige, bis die Events geladen sind
    }


    return (
        <div className="mt-[3.5%]">
            <SeatingPlanEditor
                events={eventsData}
                selectedEvent={selectedEventName}
                selectedDate={selectedDate}
            />
        </div>
    );
};

export default SeatingPlanEditorOrganizer;
