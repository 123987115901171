import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Header from "../HeaderComponents/Header";
import urlConfig from "../../../urlConfig";
import GoogleLoginButtonComponent from "../LoginButtons/GoogleLoginButtonComponent";
import { useGeneralPopup } from "../PopupComponents/GeneralPopupProvider";
import api from "../../../api";
import ChangeInitialPassword from "./ChangeOrganizersInitialPassword";
import ForgotPassword from "./ForgotPassword"; // Importiere das neue Component

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [firstName, setFirstName] = useState("");
    const [userType, setUserType] = useState("");
    const location = useLocation();
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [emailForPasswordChange, setEmailForPasswordChange] = useState("");
    const showPopup = useGeneralPopup();

    const redirection = location.state?.redirectTo || "/";
    const seatingPlanState = location.state?.seatingPlanState || {};

    useEffect(() => {
        if (location.state?.popupMessage) {
            showPopup(location.state.popupMessage, location.state.popupSeverity);
        }
    }, [location]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const customerData = { email: formData.email, password: formData.password };

        try {
            const response = await api.post(`/auth/login`, customerData);
            const data = response.data;

            if (response.status === 200) {
                if (data.user.hasToRenewPassword) {
                    setEmailForPasswordChange(formData.email);
                    setShowChangePassword(true);
                    return;
                }

                const token = `${data.tokenType}${data.token}`;
                sessionStorage.setItem("token", token);
                const role = data.user.role;
                sessionStorage.setItem("userType", role);

                if (role === "CUSTOMER") {
                    const customer = data.user.customer;
                    setFirstName(customer.firstName);
                    const customerRelevantData = {
                        id: data.user.customer.id,
                        firstName: data.user.customer.firstName,
                        lastName: data.user.customer.lastName,
                        address: data.user.customer.address,
                        email: data.user.email,
                        lastLogin: data.user.lastLogin,
                    };
                    sessionStorage.setItem("user", JSON.stringify(customerRelevantData));
                    navigate("/");

                } else if (role === "ORGANIZER") {
                    setFirstName(data.user.organizer.name);
                    sessionStorage.setItem("user", JSON.stringify(data.user.organizer));
                    console.log("session storage user: " + JSON.stringify(data.user.organizer));
                    navigate("/organizer/overview", { state: { organizer: data.user.organizer } });

                } else if (role === "LOCALSELLER") {
                    setFirstName(data.user.email);
                    sessionStorage.setItem("user", JSON.stringify(data.user));
                    navigate("/localSeller", { state: data.user });

                } else if (role === "ADMIN") {
                    setFirstName("Master");
                    sessionStorage.setItem("admin", JSON.stringify(data.user))
                    navigate("/admin");
                }
                setUserType(role);
            }
        } catch (error) {
            if (error.response?.status === 401) {
                showPopup("Bitte prüfen Sie Ihre Eingabe", "error");
            } else {
                showPopup("Netzwerkfehler oder Server nicht erreichbar", "error");
            }
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = `${urlConfig.apiBaseUrl}/auth/oauth2/authorization/google`;
    };

    const updatedPassword = () => {
        setShowChangePassword(false);
    };

    return (
        <div className="flex flex-col bg-white-200 min-h-screen overflow-hidden">
            <Header firstName={firstName} userType={userType} />
            <div className="flex justify-center items-center h-screen">
                <div className="w-full max-w-md p-5">
                    <div className="bg-white p-10 shadow-xl rounded-xl text-center">
                        {showChangePassword ? (
                            <ChangeInitialPassword email={emailForPasswordChange} updatePassword={updatedPassword} />
                        ) : showForgotPassword ? (
                            <ForgotPassword goBack={() => setShowForgotPassword(false)} />
                        ) : (
                            <>
                                <h2 className="text-4xl font-bold mb-5 mt-7 ml-2">Login</h2>
                                <form onSubmit={handleSubmit} className="flex flex-col items-center">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={handleChange}
                                        required
                                        className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        onChange={handleChange}
                                        required
                                        className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <button
                                        type="submit"
                                        className="w-full p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                                    >
                                        Login
                                    </button>
                                </form>
                                <button
                                    onClick={() => setShowForgotPassword(true)}
                                    className="text-blue-500 hover:underline mb-4"
                                >
                                    Passwort vergessen?
                                </button>
                                <div className="mt-4 text-lg">
                                    <p>Oder</p>
                                    <div className="flex justify-between mt-4">
                                        <div className="w-full p-1 m-3" onClick={handleGoogleLogin}>
                                            <GoogleLoginButtonComponent />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 text-lg">
                                    <p>
                                        Noch kein Account?{" "}
                                        <Link to="/register" className="text-blue-500 hover:underline">
                                            Registrieren
                                        </Link>
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
