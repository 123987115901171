import {
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";

const TableComponent = ({ data, columns, actions, title }) => {
    const [filteredUsers, setFilteredUsers] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const results = data.filter(user =>
            Object.values(user).some(value => {
                if (value === null) {
                    return false;
                }
                return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
            })
        );
        setFilteredUsers(results);
    }, [searchTerm, data]);

    return (
        <div className="mx-4">
            <TextField
                label="Suche"
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-4"
                sx={{ width: '30%', zIndex: 0 }}
            />
            <h2 className="text-2xl font-bold text-left">{title}</h2>
            <TableContainer component={Paper} elevation={4} sx={{ marginTop: '1rem', maxHeight: '50dvh', overflowY: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 0 }}>
                            {columns.map((column) => (
                                <TableCell key={column.id} sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell sx={{ fontSize: 16, fontWeight: 'bold' }}>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="h-[10dvh] overflow-y-auto">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((user) => (
                                <TableRow key={user.id}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} className="w-[20%]">
                                            {user[column.id]}
                                        </TableCell>
                                    ))}
                                    <TableCell className="w-[5%] flex justify-center items-center">
                                        {actions(user)}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length + 1}>
                                    <Typography sx={{ color: 'gray', margin: '10px' }}>
                                        Keine Einträge gefunden!
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TableComponent;