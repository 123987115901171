import urlConfig from "../../../urlConfig";
import api from "../../../api";

// nicht verwendet
const getAllTickets = async () => {
    try {
        const response = await api.get(`/tickets`);
        return response.data;
    } catch (error) {
        console.log("Error fetching events: ", error);
    }
}

// nicht verwendet
const getCustomersTicket = async (id) => {
    try {
        const response = await api.get(`/customer/tickets/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error fetching tickets: ", error);
    }
};

const getCustomersPaymentsWithTickets = async (id) => {
    try {
        const response = await api.get(`/customer/payment/${id}`);
        if (response.status == 200) return response.data;
    } catch (error) {
        console.log("Error fetching customers information: ", error);
    }
}

const setCustomersData = async (data, id) => {
    try {
        console.log("data sent: " + JSON.stringify(data));
        const response = await api.patch(`/customer/${id}`, data);
        console.log("data returned: " + JSON.stringify(response.data));

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Unexpected response status:', response.status);
            return null;
        }
    } catch (error) {
        console.error('Error modifying customer data: ', error);
        return null;
    }
};

export {
    getAllTickets,
    getCustomersTicket,
    getCustomersPaymentsWithTickets,
    setCustomersData,
};