import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Paper} from "@mui/material";

const PopupModal = ({ message, onClose }) => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/login');
    };

    return (
        <Paper elevation={24} sx={{borderRadius: '0rem'}} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-w-md">
                <div className={`text-gray-800 mb-4`}>
                    {message}
                </div>
                <div className="flex justify-between space-x-4">
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded outline"
                        onClick={handleRedirect}
                    >
                        Login
                    </button>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Okay
                    </button>
                </div>
            </div>
        </Paper>
    );
};

export default PopupModal;