import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import urlConfig from '../../../urlConfig';

// TODO: schöner machen
const ChangeOrganizersInitialPassword = ({ email,updatePassword}) => {
    const [formData, setFormData] = useState({ oldPassword: '', newPassword: '' });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const passwordData = {
            email: email,
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword
        };

        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/auth/setPassword`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(passwordData),
            });

            if (response.ok) {
                setSuccess('Password updated successfully!');
                updatePassword()
            } else {
                const data = await response.text();
                setError(data || 'Password change failed.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred while changing the password.');
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="w-full max-w-md p-5">
                <div className="bg-white p-10 shadow-xl rounded-xl text-center">
                    <h2 className="text-4xl font-bold mb-5">Set New Password</h2>
                    {error && <p className="text-red-500">{error}</p>}
                    {success && <p className="text-green-500">{success}</p>}
                    <form onSubmit={handleSubmit} className="flex flex-col">
                        <input
                            type="password"
                            placeholder="Old Password"
                            name="oldPassword"
                            onChange={handleChange}
                            required
                            className="p-3 mb-4 border-b-2 border-gray-300 focus:border-blue-600 focus:outline-none"
                        />
                        <input
                            type="password"
                            placeholder="New Password"
                            name="newPassword"
                            onChange={handleChange}
                            required
                            className="p-3 mb-4 border-b-2 border-gray-300 focus:border-blue-600 focus:outline-none"
                        />
                        <button
                            type="submit"
                            className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Change Password
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeOrganizersInitialPassword;
