import Header from "../General/HeaderComponents/Header";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LocalSellerBookingComponent from "./LocalSellerBookingComponent";
import api from "../../api";

const LocalSellerView = () => {
    const location = useLocation();
    const userData = location.state || {};
    const { localSeller } = userData;
    const [events, setEvents] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            console.log(`fetch for: /localSeller/events/${localSeller.id}`)
            const response = await api.get(`/localSeller/events/${localSeller.id}`);
            setEvents(response.data);
        }

        fetchData();
    }, []);

    return (
        <div>
            <Header />
            <div className="mt-16">
                <LocalSellerBookingComponent events={events} />
            </div>
        </div>
    );
};

export default LocalSellerView;