import {Button, Grid, Input, TextField, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {usePopup} from "../ServiceProviders/PopupProvider";
import {
    addEventLocation,
    deleteEventLocation,
    getEventLocations,
    setOrganizersLocations
} from "../ServiceProviders/EventAPIService";
import {useEventProvider} from "../ServiceProviders/EventProvider";

const Location = () => {
    const [customLocations, setCustomLocations] = useState([]);
    const organizer = JSON.parse(sessionStorage.getItem('user'));
    const [isModified, setIsModified] = useState(false);
    const setPopup = usePopup();
    const {eventsData} = useEventProvider()

    useEffect(() => {
        const fetchLocations = async () => {
            const locations = await getEventLocations(organizer, setPopup);
            setCustomLocations(locations.map(location => ({
                id: location.id,
                description: location.description,
                address: location.address,
                image: location.image
            })));
        };
        fetchLocations();
    }, [organizer.id]);

    const addCustomLocation = async () => {
        try {
            const data = {
                description: "",
                address: "",
                image: null,
            }
            const response = await addEventLocation(organizer, data, setPopup);
            setCustomLocations([...customLocations, {
                id: response.id,
                description: response.description,
                address: response.address,
                image: response.image
            }]);
            const filteredLocations = customLocations.map(({id, description, address}) => ({
                id,
                description,
                address,
            }));
            sessionStorage.setItem('organizersLocations', JSON.stringify(filteredLocations));
        } catch (error) {
            console.log(error);
        }
    };

    const updateLocation = (index, field, value) => {
        const updatedLocations = [...customLocations];
        updatedLocations[index][field] = value;
        setCustomLocations(updatedLocations);
        setIsModified(true);
        //console.log(`Update für Standort ${index}: ${field} =`, value);
    };

    // set all locations for this organizer
    const handleSaveButton = () => {
        try {
            setOrganizersLocations(customLocations, setPopup);// API post call
            const filteredLocations = customLocations.map(({id, description, address}) => ({
                id,
                description,
                address,
            }));
            sessionStorage.setItem('organizersLocations', JSON.stringify(filteredLocations));
            setIsModified(false);
        } catch (error) {
            setIsModified(true);
            console.error(error);
        }
    };

    const removeLocation = async (index, id) => {
        try {
            const response = await deleteEventLocation(id, setPopup);
            if (response[0] === -1) {
                const newLocation = customLocations.filter((_, i) => i !== index)
                setCustomLocations(newLocation);
                const filteredLocations = newLocation.map(({id, description, address}) => ({
                    id,
                    description,
                    address,
                }));
                sessionStorage.setItem('organizersLocations', JSON.stringify(filteredLocations));
            } else {
                const groupedEvents = {};

                response.forEach(eventDateId => {
                    const event = eventsData.find(event =>
                        event.eventDates.some(date => date.id === eventDateId)
                    );

                    if (event) {
                        if (!groupedEvents[event.id]) {
                            groupedEvents[event.id] = {event: event.name, dates: []};
                        }

                        const date = event.eventDates.find(date => date.id === eventDateId);
                        if (date) {
                            groupedEvents[event.id].dates.push(date.date);
                        }
                    }
                });

                console.log(groupedEvents);


            }
        } catch (error) {
            console.error("Fehler beim Löschen des Standorts:", error);
        }
    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                updateLocation(index, 'image', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="mt-[8%]">
            <Grid item xs={12}>
                <Grid item xs={12} sm={12} fullwidth="true">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant="h4" gutterBottom>
                            Standorte verwalten
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveButton}
                            disabled={!isModified}
                            style={{marginLeft: 'auto', fontSize: '16px'}}
                        >
                            Speichern
                        </Button>
                    </div>
                </Grid>

                <Grid sx={{
                    maxHeight: 'calc(100vh - 290px)',
                    height: 'calc(100vh - 290px)',
                    overflowY: eventsData?.length > 3 ? 'auto' : 'hidden'
                }}>
                    {customLocations.length === 0 ? (
                        <Typography variant="body1" color="textSecondary">
                            Sie müssen noch <strong>mindestens einen</strong> Standort anlegen!
                        </Typography>
                    ) : (
                        customLocations.map((customLocation, index) => (
                                <Grid container spacing={2} alignItems="center" key={index} style={{marginTop: '15px'}}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <TextField
                                                    label="Name des Standorts"
                                                    fullWidth
                                                    value={customLocation.description}
                                                    onChange={(e) => updateLocation(index, 'description', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    label="Adresse des Standorts"
                                                    fullWidth
                                                    value={customLocation.address}
                                                    onChange={(e) => updateLocation(index, 'address', e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            {!customLocation.image ? (
                                                <Input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleImageChange(index, e)}
                                                    fullWidth
                                                />
                                            ) : (
                                                <>
                                                    <p>{customLocation.image.name}</p>
                                                    <img
                                                        src={customLocation.image || null}
                                                        alt={`Vorschau für ausgewähltes Bild ${customLocation.description}`}
                                                        style={{
                                                            marginTop: '10px',
                                                            width: '100%',
                                                            maxHeight: '150px',
                                                            objectFit: 'cover',
                                                            display: 'block',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            fullWidth
                                            onClick={() => removeLocation(index, customLocation.id)}
                                        >
                                            Entfernen
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        )
                    )}

                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{marginTop: 2}}
                    onClick={addCustomLocation}
                    style={{marginTop: '2%'}}
                >
                    Neuen Standort hinzufügen
                </Button>
            </Grid>
        </div>
    );
};

export default Location;