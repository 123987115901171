import React, {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import Sidebar from './componets/Sidebar';
import Header from "../General/HeaderComponents/Header";
import {EventProvider} from './ServiceProviders/EventProvider';
import {PopupProvider} from './ServiceProviders/PopupProvider';
import {Typography} from "@mui/material";

const OrganizerView = () => {
    const location = useLocation();
    const {firstName} = location.state || {};

    useEffect(() => {
        // Verhindert das Scrollen auf der gesamten Seite
        document.body.style.overflow = 'hidden';

        // Cleanup: Wiederherstellen des Scrollens, wenn der Benutzer die Seite verlässt
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <PopupProvider>
            <EventProvider>
                <div>
                    <Header />
                    <div className="flex">
                        <div className="hidden lg:block">
                            <Sidebar/>
                        </div>
                        {/* style={{marginLeft: '250px', padding: '20px', width: '100%'}} */}
                        <div className="w-screen lg:ml-[250px] p-5 lg:mt-0 mt-14">
                            <div className="lg:hidden block">
                                <Typography className="text-gray-500">
                                    Hinweis: Für Änderungen bitte die Desktop Version verwenden.
                                </Typography>
                            </div>
                            <Outlet/> 
                        </div>
                    </div>
                </div>
            </EventProvider>
        </PopupProvider>
    );
};

export default OrganizerView;