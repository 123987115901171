import React, {useEffect, useRef, useState} from "react";
import SeatRow from "./Row";

const SeatingGroupLabels = ({rows, groupPosition, seatSize, minSpacing, groupName}) => {
    // Ermittlung der Reihe mit den meisten Sitzen
    const maxSeatsRow = rows.reduce((maxRow, currentRow) =>
            currentRow.seats.length > maxRow.seats.length ? currentRow : maxRow
        , rows[0]);

    const rowLabels = rows.map((row, rowIndex) => {
        const y = groupPosition.y + rowIndex * (seatSize + minSpacing) + seatSize / 2;
        return (
            <text
                key={`row-label-${rowIndex}`}
                x={groupPosition.x - seatSize / 3} // Links von den Sitzen
                y={y}
                fontSize={seatSize / 2.5}
                textAnchor="end"
                alignmentBaseline="middle"
                fill="black"
            >
                {row.rowNumber}
            </text>
        );
    });

    const columnLabels = maxSeatsRow?.seats.map((_, colIndex) => {
        const x = groupPosition.x + colIndex * (seatSize + minSpacing) + seatSize / 2;
        return (
            <text
                key={`col-label-${colIndex}`}
                x={x}
                y={groupPosition.y - seatSize / 3} // Über den Sitzen
                fontSize={seatSize / 2.5}
                textAnchor="middle"
                fill="black"
            >
                {colIndex + 1}
            </text>
        );
    });

    return (
        <g>
            {/* Gruppenname */}
            {groupName &&
                <text
                    x={groupPosition.x}
                    y={groupPosition.y - seatSize} // Über der Gruppe
                    fontSize={seatSize / 1.8}
                    fontWeight="bold"
                    fill="black"
                >
                    {groupName}
                </text>
            }
            {/* Reihen-Beschriftungen */}
            {rowLabels}
            {/* Spalten-Beschriftungen */}
            {columnLabels}
        </g>
    );
};

const SeatingGroup = ({
                          group,
                          groupIndex,
                          svgWidth,
                          svgHeight,
                          seatSize,
                          minSpacing,
                          toggleSeat,
                          selectedSeats
                      }) => {
    const groupRef = useRef(null);
    const [groupDimensions, setGroupDimensions] = useState({width: 0, height: 0});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMouseDevice, setIsMouseDevice] = useState(false);

    const groupDesign = group.designInformation;
    const centerX = groupDesign.relativeCenterX * svgWidth;
    const centerY = groupDesign.relativeCenterY * svgHeight;

    const [tooltip, setTooltip] = useState({
        visible: false,
        x: 0,
        y: 0,
        text: '',
        price: '',
        groupRotation: 0,
    });

    const showTooltip = (seat, x, y, row) => {
        const rowNumber = row ?? 'N/A';
        const seatNumber = seat.seatNumber ?? 'N/A';
        const groupName = group.name ?? 'unbekannt';
        const price = `€${group.seatClass.price.toFixed(2)}`;

        const text = `Reihe: ${rowNumber}, Platz: ${seatNumber}\nGruppe: ${groupName}`;

        setTooltip({
            visible: true,
            x: x + seatSize / 2,
            y,
            text,
            price,
            groupRotation: group.designInformation.rotation
        });
    };

    const hideTooltip = () => {
        setTooltip({visible: false, x: 0, y: 0, text: '', price: '', groupRotation: 0});
    };

    // Measure dimensions of the group
    useEffect(() => {
        if (groupRef.current) {
            const bbox = groupRef.current.getBBox();
            setGroupDimensions({
                width: bbox.width,
                height: bbox.height,
            });
        }
    }, [group]);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        const handleMouseMove = () => {
            setIsMouseDevice(true);
        };
        window.addEventListener("resize", handleResize);
        window.addEventListener("mousemove", handleMouseMove);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const groupPosition = {
        x: centerX - (groupDimensions.width - 16.47) / 2,
        y: centerY - (groupDimensions.height - 26) / 2,
    };

    const rotationAngle = groupDesign.rotation || 0;

    const maxSeatsRow = group.rows.reduce((maxRow, currentRow) =>
            (currentRow.seats.length + currentRow.seatOffset) > (maxRow.seats.length + maxRow.seatOffset) ? currentRow : maxRow
        , group.rows[0]);


    return (
        <g
            ref={groupRef}
            transform={`rotate(${rotationAngle}, ${centerX}, ${centerY})`}
        >
            {<SeatingGroupLabels
                rows={group.rows}
                groupPosition={groupPosition}
                seatSize={seatSize}
                minSpacing={minSpacing}
                groupName={groupDesign.showName ? group.name : null}
            />}
            {group.rows.map((row, rowIndex) => (
                <SeatRow
                    key={`group-${group.id}-row-${rowIndex}`}
                    row={{...row, group: group}}
                    rowIndex={rowIndex}
                    seatSize={seatSize}
                    minSpacing={minSpacing}
                    toggleSeat={toggleSeat}
                    selectedSeats={selectedSeats}
                    groupPosition={groupPosition}
                    groupDesign={groupDesign}
                    maxSeatsRow={maxSeatsRow}
                    showTooltip={(seat, x, y, row) => showTooltip(seat, x, y, row)}
                    hideTooltip={hideTooltip}
                />
            ))}

            {tooltip.visible && windowWidth > 768 && isMouseDevice && (
                <g>
                    <rect
                        x={tooltip.x - 80}
                        y={tooltip.y - 90}
                        width={160}
                        height={90}
                        rx={8}
                        ry={8}
                        fill="white"
                        stroke="lightgray"
                        strokeWidth={1}
                        filter="url(#tooltipShadow)"
                    />
                    <text
                        x={tooltip.x}
                        y={tooltip.y - 60}
                        fontSize={14}
                        fill="black"
                        textAnchor="middle"
                    >
                        {tooltip.text.split('\n')[0]} {/* Reihe und Platz */}
                    </text>
                    <text
                        x={tooltip.x}
                        y={tooltip.y - 40}
                        fontSize={14}
                        fill="black"
                        textAnchor="middle"
                    >
                        {tooltip.text.split('\n')[1]} {/* Gruppe */}
                    </text>
                    <line
                        x1={tooltip.x - 70}
                        y1={tooltip.y - 30}
                        x2={tooltip.x + 70}
                        y2={tooltip.y - 30}
                        stroke="lightgray"
                        strokeWidth={1}
                    />
                    <text
                        x={tooltip.x}
                        y={tooltip.y - 10}
                        fontSize={14}
                        fill="black"
                        textAnchor="middle"
                    >
                        Preis: {tooltip.price}
                    </text>
                    <polygon
                        points={`${tooltip.x - 5},${tooltip.y} ${tooltip.x + 5},${tooltip.y} ${tooltip.x},${tooltip.y + 10}`}
                        fill="white"
                        stroke="lightgray"
                        strokeWidth={1}
                    />
                </g>
            )}
        </g>
    );
};

export default SeatingGroup;