import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    Checkbox, TextField, Paper
} from "@mui/material";
import api from "../../../../api";
import {Delete as DeleteIcon, Save as SaveIcon} from "@mui/icons-material";
import {EditIcon} from "lucide-react";
import {usePopup} from "../../ServiceProviders/PopupProvider";

const LocalSellerComponent = () => {
    const organizer = JSON.parse(sessionStorage.getItem('user'));
    const [localSellers, setLocalSellers] = useState([]);
    const [events, setEvents] = useState([]);
    const [popupState, setPopupState] = useState({
        open: false,
        title: "",
        submitText: ""
    });
    const [seller, setSeller] = useState({
        name: "",
        address: "",
        phoneNumber: "",
        email: "",
        eventIds: []
    });

    const setPopup = usePopup();
    const showPopup = (message, severity, position) => {
        setPopup({open: true, message, severity, position});
    };

    // data getter
    useEffect(() => {
        // get localSellers by organizerId
        api.get(`/organizer/localSeller/${organizer.id}`).then((response) => {
            setLocalSellers(response.data);
        }).catch((error) => {
            console.error('Fehler beim Abrufen der Daten:', error)
        });

        // get events by organizerId
        api.get(`/organizer/events/${organizer.id}`).then((response) => {
            setEvents(response.data);
        })
    }, []);

    const handleAddSeller = () => {
        setPopupState({
            open: true,
            title: "Neuen Verkäufer anlegen",
            submitText: "Hinzufügen"
        });
        setSeller({
            name: "",
            address: "",
            phoneNumber: "",
            email: "",
            eventIds: []
        });
    };

    const handleSaveNewSeller = () => {
        api.post(`/organizer/localSeller/${organizer.id}`, seller)
            .then(response => {
                const newSeller = {
                    id: response.data.localSeller.id,
                    name: response.data.localSeller.name,
                    address: response.data.localSeller.address,
                    phoneNumber: response.data.localSeller.phoneNumber,
                    email: response.data.email,
                    eventIds: response.data.localSeller.eventIds ?? [],
                };
                setLocalSellers(prevSellers => [...prevSellers, newSeller]);
                setPopupState(prevState => ({...prevState, open: false}));
            })
            .catch(error => {
                console.error("Fehler beim Hinzufügen des Verkäufers: ", error);
            });
    };

    const openEditPopup = (sellerToEdit) => {
        setSeller({
            ...sellerToEdit,
            eventIds: sellerToEdit.eventIds ?? []
        });
        setPopupState({
            open: true,
            title: "Verkäufer bearbeiten",
            submitText: "Aktualisieren"
        });
    };

    const saveUpdatedSeller = () => {
        const updatedSeller = {
            id: seller.id,
            name: seller.name,
            address: seller.address,
            phoneNumber: seller.phoneNumber,
            email: seller.email,
            eventIds: seller.eventIds ?? []
        };
        api.patch(`/organizer/localSeller/${seller.id}`, updatedSeller)
            .then(() => {
                const updatedSellers = localSellers.map(seller =>
                    seller.id === updatedSeller.id ? {...seller, ...updatedSeller} : seller
                );
                setLocalSellers(updatedSellers);
                setPopupState(prevState => ({...prevState, open: false}));
                showPopup("Erfolgreich aktualisiert", "success", "center");
            })
            .catch(error => {
                showPopup("Ein Fehler ist aufgetreten", "error", "center");
                console.error("Fehler beim Aktualisieren des Verkäufers:", error);
            });
    }

    const handleDeleteSeller = (id) => {
        // TODO: wollen Sie sicher entfernen?
        setLocalSellers(prevSellers => {
            const updatedSellers = prevSellers.filter(seller => seller.id !== id);
            return updatedSellers;
        });

        api.delete(`/organizer/localSeller/${id}`).then((response) => {
            console.log(response);
            if (response.status === 200) {
                showPopup("Der Verkäufer wurde entfernt", "success", "center");
            } else {
                showPopup("Ein Fehler ist aufgetreten", "error", "center");
            }
        })
            .catch(error => {
                console.error("Fehler beim Löschen des Verkäufers:", error);
            });
    };

    return (
        <div className="mx-4">
            <Grid item>
                <Card sx={{
                    borderRadius: '1rem',
                    marginTop: '2rem',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
                }}>
                    <CardContent>
                        <h2 className="text-2xl font-semibold mb-4">Angelegte externe Verkäufer</h2>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontSize: 16, fontWeight: 'bold'}}>Name</TableCell>
                                        <TableCell sx={{fontSize: 16, fontWeight: 'bold'}}>Adresse</TableCell>
                                        <TableCell sx={{fontSize: 16, fontWeight: 'bold'}}>Telefonnummer</TableCell>
                                        <TableCell sx={{fontSize: 16, fontWeight: 'bold'}}>Email</TableCell>
                                        <TableCell sx={{fontSize: 16, fontWeight: 'bold'}}>Bearbeitbare
                                            Events</TableCell>
                                        <TableCell sx={{fontSize: 16, fontWeight: 'bold'}}>Aktionen</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {localSellers.length > 0 ? (
                                        localSellers.map((localSeller) => (
                                            <TableRow key={localSeller.id}>
                                                <TableCell className="w-[20%]">{localSeller.name}</TableCell>
                                                <TableCell className="w-[20%]">{localSeller.address}</TableCell>
                                                <TableCell className="w-[15%]">{localSeller.phoneNumber}</TableCell>
                                                <TableCell className="w-[15%]">{localSeller.email}</TableCell>
                                                <TableCell className="w-[25%]">
                                                    {localSeller.eventIds.length > 0 ? (
                                                        localSeller.eventIds.map((id) => {
                                                            const event = events.find((e) => e.id === id);
                                                            return event ?
                                                                <Typography key={id}>{event.name}</Typography> : null;
                                                        })
                                                    ) : (
                                                        <Typography>Keine Events zugewiesen</Typography>
                                                    )}
                                                </TableCell>
                                                {/* Edit & Delete Icons */}
                                                <TableCell className="w-[5%] flex justify-center items-center">
                                                    <div className="flex items-center gap-2">
                                                        <button onClick={() => openEditPopup(localSeller)}
                                                                className="text-gray-600 hover:text-gray-700 flex items-center">
                                                            <EditIcon/>
                                                        </button>
                                                        <button onClick={() => handleDeleteSeller(localSeller.id)}
                                                                className="text-red-500 hover:text-red-700 flex items-center">
                                                            <DeleteIcon/>
                                                        </button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography sx={{color: 'gray', margin: '10px'}}>Noch keine externen
                                                    Verkäufer angelegt!</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <button
                            onClick={handleAddSeller}
                            className="mt-4 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg shadow-md flex items-center justify-center mx-auto gap-2"
                        >
                            Neuer Verkäufer
                        </button>
                    </CardContent>
                </Card>
            </Grid>
            {popupState.open && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-transparent border border-gray-400 shadow-3xl rounded-lg p-4">
                    <Paper elevation={24} className="bg-white-100 p-6 rounded-lg shadow-lg w-1/3"
                           sx={{borderRadius: '1rem'}}>
                        <h2 className="text-xl font-semibold mb-4 text-gray-800">{popupState.title}</h2>
                        <TextField type="text" label="Name" value={seller.name} sx={{marginBottom: '1rem'}}
                                   onChange={(e) => setSeller({...seller, name: e.target.value})}
                                   className="w-full p-2 border border-gray-600 bg-gray-100 text-gray-700 rounded placeholder-gray-700"/>
                        <TextField type="text" label="Adresse" value={seller.address} sx={{marginBottom: '1rem'}}
                                   onChange={(e) => setSeller({...seller, address: e.target.value})}
                                   className="w-full p-2 border border-gray-600 bg-gray-100 text-gray-700 rounded placeholder-gray-700"/>
                        <TextField type="text" label="Telefonnummer" value={seller.phoneNumber}
                                   sx={{marginBottom: '1rem'}}
                                   onChange={(e) => setSeller({...seller, phoneNumber: e.target.value})}
                                   className="w-full p-2 border border-gray-600 bg-gray-100 text-gray-700 rounded placeholder-gray-700"/>
                        <TextField type="email" label="E-Mail" value={seller.email} sx={{marginBottom: '1rem'}}
                                   onChange={(e) => setSeller({...seller, email: e.target.value})}
                                   className="w-full p-2 border border-gray-600 bg-gray-100 text-gray-700 rounded placeholder-gray-700"/>

                        <Select
                            className="w-full"
                            multiple
                            value={seller.eventIds}
                            onChange={(event) => setSeller({...seller, eventIds: event.target.value})}
                            renderValue={(selected) => selected.map(id => events.find(e => e.id === id)?.name).join(', ')}
                        >
                            {events.map((event) => (
                                <MenuItem key={event.id} value={event.id}>
                                    <Checkbox checked={seller.eventIds.includes(event.id)}/>
                                    <Typography>{event.name}</Typography>
                                </MenuItem>
                            ))}
                        </Select>

                        <div className="flex justify-between gap-2 mt-4">
                            <button onClick={() => setPopupState(prevState => ({...prevState, open: false}))}
                                    className="bg-gray-300 px-4 py-2 rounded shadow-2xl">Abbrechen
                            </button>
                            <button
                                onClick={() => {
                                    if (popupState.submitText === "Hinzufügen") {
                                        handleSaveNewSeller();
                                    } else {
                                        saveUpdatedSeller();  // Aufruf der Methode zum Speichern
                                    }
                                }}
                                className="bg-blue-500 text-white px-4 py-2 rounded shadow-2xl">
                                {popupState.submitText}
                            </button>
                        </div>
                    </Paper>
                </div>
            )}
        </div>
    )
}

export default LocalSellerComponent;