import React from "react";

const SeatRow = ({
                     row,
                     rowIndex,
                     seatSize,
                     minSpacing,
                     groupPosition,
                     groupDesign,
                     maxSeatsRow,
                 }) => {
    const orientation = groupDesign.orientation;
    const maxSeats=maxSeatsRow.seats.length

    return row.seats.map((seat, seatIndex) => {
        // Berechnung der x-Koordinate basierend auf der Orientierung
        let x;
        const totalRowWidth = row.seats.length * seatSize + (row.seats.length - 1) * minSpacing; // Gesamte Breite der Sitzreihe

        switch (orientation) {
            case "LEFT":
                seatIndex=seatIndex+row.seatOffset
                x = groupPosition.x + seatIndex * (seatSize + minSpacing);
                break;

            case "RIGHT":
                seatIndex = (maxSeats - row.seats.length)+seatIndex+row.seatOffset;

                x = groupPosition.x + seatIndex * (seatSize + minSpacing);
                break;

            case "CENTER":
                const center=maxSeats/2
                seatIndex=(center-row.seats.length/2)+seatIndex+row.seatOffset;


                x = groupPosition.x + seatIndex * (seatSize + minSpacing);
                break;

            default:
                x = groupPosition.x + seatIndex * (seatSize + minSpacing); // Fallback auf LEFT
                break;
        }

        // y-Koordinate bleibt gleich
        const y = groupPosition.y + rowIndex * (seatSize + minSpacing);

        return (
            <g key={`${row.key} - ${seatIndex}`}>
                <rect
                    id={seat.seatNumber}
                    x={x}
                    y={y}
                    width={seatSize}
                    height={seatSize}
                    fill="white"
                    stroke="black"
                />
            </g>
        );
    });
};

export default SeatRow;
