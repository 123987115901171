import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Grid, Paper } from "@mui/material";
import { setCustomersData } from "./CustomerAPIService";

const EditCustomerInformationComponent = () => {
    const [user, setUser] = useState(null);
    const [storedUser, setStoredUser] = useState(null);

    useEffect(() => {
        const temp = JSON.parse(sessionStorage.getItem("user"));
        if (temp) {
            setUser(temp);
            setStoredUser(temp);
        }
    }, []);

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        const response = await setCustomersData(user, user.id);
        if (response !== null) {
            const customerRelevantData = {
                id: response.id,
                firstName: response.firstName,
                lastName: response.lastName,
                address: response.address,
                email: response.email,
                lastLogin: response.lastLogin,
            };
            setUser(customerRelevantData);
            console.log("Gespeicherte Benutzerdaten:", JSON.stringify(customerRelevantData));
            sessionStorage.setItem("user", JSON.stringify(customerRelevantData));
        } else {
            setUser(storedUser);  // use previous data before changes for backup
        }
    };

    if (!user) return <Typography>Keine Benutzerdaten verfügbar.</Typography>;

    return (
        <Grid container justifyContent="center" marginTop="2rem">
            <Grid item xs={11} md={6}>
                <Paper elevation={3} className="p-5">
                    <Typography variant="h5" className="mb-4">
                        📝 Benutzerdaten bearbeiten
                    </Typography>
                    <TextField
                        fullWidth
                        label="Vorname"
                        name="firstName"
                        value={user.firstName}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Nachname"
                        name="lastName"
                        value={user.lastName}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Adresse"
                        name="address"
                        value={user.address}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="E-Mail"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Letzter Login"
                        value={new Date(user.lastLogin).toLocaleString()}
                        margin="normal"
                        disabled
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleSave} sx={{ marginTop: '1rem' }}>
                        Speichern
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default EditCustomerInformationComponent;