import React, {useEffect, useState} from "react";
import {getStatusInformation} from "../ServiceProviders/EventAPIService";

const ProgressBarComponent = ({eventDateId}) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (eventDateId === 0 || eventDateId === undefined || eventDateId === null) {
                setPercentage(0);
                return;
            }
            ;
            const data = await getStatusInformation(eventDateId);
            if (data) {
                // Gegenwahrscheinlichkeit berechnen damit bereits besetzte angezeigt werden
                setPercentage(100 - data.freeSeatPercent);
            }
        };
        fetchData();
    }, [eventDateId]);

    return (
        <div className="w-full bg-gray-200 rounded-full h-4 relative">
            <div
                className="bg-blue-600 h-4 rounded-full transition-all"
                style={{width: `${percentage}%`}}
            ></div>
            <span className="absolute inset-0 flex justify-center items-center text-sm font-semibold text-gray-900">
                <span className="hidden sm:inline mr-1">Verkauft: </span> {percentage}%
            </span>
        </div>
    );
};

export default ProgressBarComponent;
