import React from 'react';
import {useNavigate} from 'react-router-dom';

const Event = ({id, imgSrc, title, description, detailedDescription, organizer, eventDates, dateRange}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        const eventDetails = {
            id,
            imgSrc,
            title,
            description,
            detailedDescription,
            organizer,
            eventDates,
            name: title,
            picture: imgSrc
        };
        navigate('/eventDetails', {state: eventDetails});
    };

    /*                       BEFORE
    return (
        <div className="event" onClick={handleClick}>
            <img src={imgSrc} alt="Event Picture missing..."/>
            <div className="event-details">
                <h3>{title}</h3>
                <p>{description}</p>
                <p>{`Organisiert von: ${organizer}`}</p>
                <p>{dateRange}</p>
            </div>
        </div>
    )                                                            */

    return (
        <div className="event" onClick={handleClick} className="p-5 pb-7 pt-7">
            <div className="relative flex w-80 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <div
                    className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
                    <img src={imgSrc} alt="Event Picture missing..."/>
                </div>
                <div className="p-6">
                    <h5 className="mb-2 block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                        {title}
                    </h5>
                    <p className="block font-sans text-base font-light leading-relaxed text-inherit antialiased">
                        {description} <br/> {`Organisiert von: ${organizer}`} <br/> {dateRange}
                    </p>
                </div>
                <div className="p-6 pt-0">
                    <button data-ripple-light="true" type="button"
                            className="select-none rounded-lg bg-blue-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
                        Mehr erfahren
                    </button>
                </div>
            </div>

        </div>
    );
};

export default Event;