import React, {useEffect} from 'react';
import urlConfig from "../../../urlConfig";
import {useNavigate} from "react-router-dom";

const OauthResponse = () => {
    const navigate = useNavigate()
    useEffect(() => {
        function setUser(data) {
            console.log(data)
            const token=`${data.tokenType}${data.token}`
            const role=data.user.role;
            sessionStorage.setItem('userType', role);

            const customerRelevantData = {
                id: data.user.customer.id,
                firstName: data.user.customer.firstName,
                lastName: data.user.customer.lastName,
                address: data.user.customer.address,
                email: data.user.email,
                lastLogin: data.user.lastLogin,
            };

            sessionStorage.setItem('user', JSON.stringify(customerRelevantData))
            sessionStorage.setItem('token', token)
            navigate("/")
        }

        fetch(`${urlConfig.apiBaseUrl}/auth/oauth2`, {credentials: "include"})
            .then((response) => response.text()) // Statt direkt `.json()`
            .then((text) => {
                try {
                    const data = JSON.parse(text);
                    setUser(data);
                } catch (error) {
                    console.error("Fehler beim JSON-Parsing:", text, error);
                }
            })
            .catch((error) => console.error("Fehler beim Laden der Benutzerdaten:", error));

    }, []);
}
export default OauthResponse