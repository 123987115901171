const urlConfig = {
    apiBaseUrl: process.env.NODE_ENV === 'production'
        ? 'https://ticket4u.at/api'
        : 'http://localhost:8080',
        //: 'http://172.18.10.141:8080',
        //: 'http://5840-89-144-206-54.ngrok-free.app',
    reactBaseUrl:process.env.NODE_ENV === 'production'
        ? 'https://ticket4u.at/'
        : 'http://localhost:3000',
};

export default urlConfig;
