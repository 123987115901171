import React from "react";
import {useNavigate} from "react-router-dom";
import {X, Search, Home, Ticket, Mail, Info, Shield} from "lucide-react";

const Sidebar = ({isSidebarOpen, toggleSidebar}) => {
    const navigate = useNavigate();
    return (
        <div
            className={`fixed top-0 left-0 h-full w-64 bg-gray-900 text-white transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out lg:hidden`}>
            <div className="p-5 flex justify-between items-center border-b border-gray-700">
                <span className="text-xl font-bold">Menü</span>
                <button onClick={toggleSidebar}>
                    <X size={24}/>
                </button>
            </div>
            <ul className="p-5 space-y-4">
                <li className="cursor-pointer flex items-center space-x-2 hover:text-gray-400"
                    onClick={() => navigate("/")}><Home size={20}/> <span>Hauptseite</span></li>
                <li className="cursor-pointer flex items-center space-x-2 hover:text-gray-400"
                    onClick={() => navigate("/search")}><Search size={20}/> <span>Event suchen</span></li>
                <li className="cursor-pointer flex items-center space-x-2 hover:text-gray-400"
                    onClick={() => navigate("/tickets")}><Ticket size={20}/> <span>Meine Tickets</span></li>

                <hr className="border-gray-700 my-4"/>

                <li className="cursor-pointer flex items-center space-x-2 hover:text-gray-400"
                    onClick={() => navigate("/contact")}><Mail size={20}/> <span>Kontakt</span></li>
                <li className="cursor-pointer flex items-center space-x-2 hover:text-gray-400"
                    onClick={() => navigate("/impressum")}><Info size={20}/> <span>Über uns / Impressum</span></li>
                <li className="cursor-pointer flex items-center space-x-2 hover:text-gray-400"
                    onClick={() => navigate("/legal")}><Shield size={20}/> <span>Rechtliches</span></li>
            </ul>
        </div>
    );
};

export default Sidebar;