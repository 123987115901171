import React, {useState, useRef, useEffect, useLayoutEffect, useMemo} from "react";
import {Box, Grid, Select, MenuItem, Button, FormControl, InputLabel, Paper, Slider, Typography} from "@mui/material";
import SeatingPlan from "./components/SeatingPlan";
import EditorPanel from "./EditorPanel";
import {useEventProvider} from "../ServiceProviders/EventProvider";
import urlConfig from "../../../urlConfig";
import seatingPlan from "./components/SeatingPlan";
import api from "../../../api";

let idCounter = 0;
const generateSvgId = () => {
    idCounter += 1;
    return idCounter;
};

const SeatingPlanEditor = ({events, selectedEvent: selectedEventName, selectedDate: selectedDate}) => {

    const {fetchData, onEventChange} = useEventProvider();
    const [seatingGroups, setSeatingGroups] = useState([]);
    const [stages, setStages] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);
    const [svgDimensions, setSvgDimensions] = useState({width: 0, height: 0});
    const [currentEvent, setCurrentEvent] = useState(
        events.find((event) => event.name === selectedEventName) || null
    );
    const [currentDate, setCurrentDate] = useState(selectedDate || "");
    const [loadedEvent, setLoadedEvent] = useState(null);

    const [seatScale, setSeatScale] = useState(currentEvent?.seatScale || 1);
    const [elementOrder, setElements] = useState(["stages", "groups"])

    const seatingPlanContainerRef = useRef(null);


    const seatSize = useMemo(() => svgDimensions.width * 0.03 * seatScale, [svgDimensions.width, seatScale]);
    const minSpacing = useMemo(() => seatSize / 3, [seatSize]);


    useLayoutEffect(() => {
        const updateDimensions = () => {
            // Ensure the ref is available before attempting to read the dimensions
            if (seatingPlanContainerRef.current) {
                const {offsetWidth, offsetHeight} = seatingPlanContainerRef.current;
                setSvgDimensions({width: offsetWidth, height: offsetWidth / (16 / 9)});
            }
        };

        // Initially set the dimensions
        updateDimensions();

        // Add resize event listener
        window.addEventListener("resize", updateDimensions);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [currentEvent, currentDate]);  // Empty dependency array ensures it runs once after initial render


    useEffect(() => {
        const handleKeyDown = (event) => {
            // Überprüfe, ob der Fokus auf einem Texteingabefeld liegt
            const activeElement = document.activeElement;
            const isInputField =
                activeElement.tagName === "INPUT" ||
                activeElement.tagName === "TEXTAREA" ||
                activeElement.isContentEditable;

            if (isInputField) {
                // Wenn der Fokus auf einem Eingabefeld liegt, keine Aktion ausführen
                return;
            }

            if (!selectedElement) return; // Wenn kein Element ausgewählt ist, tue nichts

            if (event.key === "Delete" || event.key === "Backspace") {
                event.preventDefault(); // Verhindere Standardaktionen der Taste

                if (selectedElement.type === "seatingGroup") {
                    // Entferne das ausgewählte Sitzgruppen-Element
                    setSeatingGroups((prev) =>
                        prev.filter((group) => group.svgId !== selectedElement.svgId)
                    );
                } else if (selectedElement.type === "stage") {
                    // Entferne das ausgewählte Bühnen-Element
                    setStages((prev) =>
                        prev.filter((stage) => stage.svgId !== selectedElement.svgId)
                    );
                }

                setSelectedElement(null); // Setze das ausgewählte Element zurück
            }
        };

        // Event-Listener hinzufügen
        window.addEventListener("keydown", handleKeyDown);

        // Event-Listener bei Component-Demontage entfernen
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedElement, setSeatingGroups, setStages]); // Füge Abhängigkeiten hinzu


    useEffect(() => {
        setSelectedElement(null)

        if (currentEvent && loadedEvent === currentEvent.name) {
            return
        }
        const newSeatingGroups = seatingGroups.map(group => ({
            ...group,
            seatClass: currentEvent.seatClasses[0]
        }));

        console.log(newSeatingGroups)
        setSeatingGroups(newSeatingGroups)

    }, [currentEvent]);

    const handleSeatScaleChange = (event, newValue) => {
        setSeatScale(newValue);
    };


    const loadSeatingPlan = () => {
        const setIds = (seatingPlan) => {
            return {
                ...seatingPlan,
                stages: seatingPlan.stages.map((stage) => ({
                    ...stage,
                    type: "stage",
                    svgId: generateSvgId(),
                    id: 0,
                })),
                seatingGroups: seatingPlan.seatingGroups.map((group) => ({
                    ...group,
                    type: "seatingGroup",
                    svgId: generateSvgId(),
                    id: 0,
                    designInformation: {
                        ...group.designInformation,
                        id: 0
                    },
                    rows: group.rows.map((row) => ({
                        ...row,
                        id: 0,
                        seats: row.seats.map((seat) => ({
                            ...seat,
                            id: 0,
                            status: 'AVAILABLE'
                        }))

                    }))
                })),
            };
        };

        setLoadedEvent(currentEvent.name)

        setSeatingGroups([]);
        setStages([]);

        setTimeout(() => {
            const tempEvent = {...events.find((event) => event.name === currentEvent.name)};
            setCurrentEvent(tempEvent);

            // Falls currentDate nicht existiert, das erste verfügbare Datum nehmen
            const selectedEventDate = tempEvent.eventDates.find((date) => date.date === currentDate)
                || tempEvent.eventDates[0]; // Falls kein Datum gewählt ist, nimm das erste

            if (selectedEventDate && selectedEventDate.seatingPlan) {
                const loadedSeatingPlan = setIds(selectedEventDate.seatingPlan);
                console.log(loadedSeatingPlan)
                setSeatScale(loadedSeatingPlan.seatScale)
                setSeatingGroups([...loadedSeatingPlan.seatingGroups] || []);
                setStages([...loadedSeatingPlan.stages] || []);
                console.log("Seating Plan geladen:", loadedSeatingPlan);
                alert("Sitzplan erfolgreich geladen!");
            } else {
                console.log("Kein Sitzplan für das ausgewählte Datum gefunden.");
                alert("Kein Sitzplan für dieses Datum gefunden.");
            }
        }, 0);

    };

    const saveSeatingPlan = async () => {
        if (currentEvent) {
            let isBooked = false
            currentDate?.seatingPlan?.seatingGroups.forEach(group => {
                group.rows.forEach((row) => {
                    row.seats.forEach(seat => {
                        if (seat.status !== 'AVAILABLE') {
                            isBooked = true;
                        }
                    })
                })
            })
            if (isBooked) {
                alert("At least one seat of your seatingPlan was already booked")
                return
            }
            // Falls currentDate gesetzt ist, suche das entsprechende EventDate
            const tempDate = currentDate
                ? currentEvent.eventDates.find((date) => date.date === currentDate)
                : null;

            // Falls kein currentDate oder kein passendes EventDate gefunden wurde, nutze den Fallback-Endpoint
            const endpoint = tempDate
                ? `${urlConfig.apiBaseUrl}/organizer/date/seatingPlan/${tempDate.id}`
                : `${urlConfig.apiBaseUrl}/organizer/dates/seatingPlan/${currentEvent.id}`;

            console.log("Seating Plan wird gespeichert an:", endpoint);

            // Sitzplan-Daten erstellen
            const seatingPlanData = {
                seatScale: seatScale,
                seatingGroups: seatingGroups,
                stages: stages,
            };

            // API-Request senden
            /*fetch(endpoint, {
            //                 method: 'PUT',
            //                 headers: {
            //                     'Content-Type': 'application/json',
            //                 },
            //                 body: JSON.stringify(seatingPlanData),
            //             })*/
            await api.put(endpoint, seatingPlanData).then((response) => {
                if (response.status === 200) {
                    console.log("Seating Plan erfolgreich gespeichert!");
                    alert("Sitzplan erfolgreich gespeichert!");
                    fetchData();
                } else {
                    console.error("Fehler beim Speichern des Sitzplans:", response.statusText);
                    alert("Fehler beim Speichern des Sitzplans.");
                }
            }).catch((error) => {
                console.error("Netzwerkfehler:", error);
                alert("Fehler beim Speichern des Sitzplans.");
            });
        }
    };

    const handleAddSeatingGroup = ({name, rotation, rows, seatsPerRow, seatClass}) => {
        const newGroup = {
            type: "seatingGroup",
            svgId: generateSvgId(),
            name: name,
            seatClass: seatClass,
            rows: Array.from({length: rows}, (_, rowIndex) => ({
                rowNumber: rowIndex + 1,
                seatOffset: 0,
                seats: Array.from({length: seatsPerRow}, (_, seatIndex) => ({
                    seatNumber: seatIndex + 1,
                    type: {name: "Standard", price: 0},
                    status: "AVAILABLE",
                })),
            })),
            designInformation: {
                rotation: rotation,
                relativeCenterX: 0.5,
                relativeCenterY: 0.5,
                orientation: "LEFT",
                showName: true,
            },
        };

        setSeatingGroups([...seatingGroups, newGroup]);
        setSelectedElement(newGroup);
    };

    const addStage = () => {
        const newStage = {
            svgId: generateSvgId(),
            type: "stage",
            relativeCenterX: 0.6,
            relativeCenterY: 0.2,
            relativeSizeX: 50,
            relativeSizeY: 20,
            text: "Stage",
            fontSize: 0.1,
            stageOpacity: 70,
        };
        setStages([...stages, newStage]);
        setSelectedElement(newStage);
    };

    const handleElementClick = (element) => {
        if (!element) {
            return;
        }
        setSelectedElement((prev) => (prev?.svgId === element.svgId ? null : element));
    };

    const updateElement = (updatedElement) => {

        if (updatedElement.type === "seatingGroup") {
            setSeatingGroups((prev) =>
                prev.map((group) => (group.svgId === updatedElement.svgId ? {...updatedElement} : group))
            );
        } else if (updatedElement.type === "stage") {
            setStages((prev) =>
                prev.map((stage) => (stage.svgId === updatedElement.svgId ? {...updatedElement} : stage))
            );
        }
        setSelectedElement({...updatedElement});
    };

    const bringElementToFront = (element) => {
        if (element.type === "stage") {
            setStages(prevStages => [
                ...prevStages.filter(s => s.svgId !== element.svgId),
                element
            ]);
            setElements(["groups", "stages"])
        } else {
            setSeatingGroups(prevGroups => [
                ...prevGroups.filter(g => g.svgId !== element.svgId),
                element
            ]);
            setElements(["stages", "groups"])
        }
    };
    return (
        <Box sx={{display: "flex", flexDirection: "column", height: "100vh", bgcolor: "#f7f9fc"}}>
            <Paper sx={{padding: 2, display: "flex", gap: 2, alignItems: "center", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: 2}}>
                    <FormControl sx={{minWidth: 200}}>
                        <InputLabel>Event</InputLabel>
                        <Select
                            value={currentEvent?.name || ""}
                            onChange={(e) => {
                                const selectedEvent = events.find((event) => event.name === e.target.value);
                                setCurrentEvent(selectedEvent || null);
                                setCurrentDate("");
                            }}
                            label="Event"
                        >
                            <MenuItem value="">
                                <em>Wähle ein Event</em>
                            </MenuItem>
                            {events.map((event) => (
                                <MenuItem key={event.id} value={event.name}>
                                    {event.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{minWidth: 200}}>
                        <InputLabel>Datum</InputLabel>
                        <Select
                            value={currentDate}
                            onChange={(e) => setCurrentDate(e.target.value)}
                            label="Datum"
                            disabled={!currentEvent}
                        >
                            <MenuItem value="">
                                <em>Wähle ein Datum</em>
                            </MenuItem>
                            {currentEvent?.eventDates?.map((dateObj) => (
                                <MenuItem key={dateObj.id} value={dateObj.date}>
                                    {new Date(dateObj.date).toLocaleString()}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {currentEvent && (
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", width: 200}}>
                            <Typography variant="caption">Sitzgrößen-Skalierung</Typography>
                            <Slider
                                value={seatScale}
                                min={0.5}
                                max={1.5}
                                step={0.05}
                                onChange={handleSeatScaleChange}
                                valueLabelDisplay="auto"
                            />
                        </Box>
                    )}
                    {currentEvent && (
                        <Typography variant="body2" color="textSecondary" sx={{marginLeft: 2}}>
                            Sitzpläne können nur bearbeitet werden, wenn noch keine Tickets verkauft wurden.
                        </Typography>
                    )}
                </Box>

                <Box sx={{display: "flex", gap: 2}}>
                    <Button
                        onClick={loadSeatingPlan}
                        variant="contained"
                        color="secondary"
                        disabled={!currentEvent}
                    >
                        Laden
                    </Button>
                    <Button
                        onClick={saveSeatingPlan}
                        variant="contained"
                        color="primary"
                        disabled={!currentEvent}
                    >
                        Speichern
                    </Button>
                </Box>
            </Paper>

            <Grid container sx={{flex: 1}}>
                {currentEvent && (
                    <>
                        <Grid
                            item
                            xs={3}
                            sx={{
                                bgcolor: "#e8f0fe",
                                padding: 2,
                                height: "100vh",
                                position: "relative",
                                overflowY: "hidden",
                            }}
                        >
                            <EditorPanel
                                addStage={addStage}
                                selectedElement={selectedElement}
                                updateElement={updateElement}
                                onAddSeatingGroup={handleAddSeatingGroup}
                                seatClasses={currentEvent.seatClasses}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={9}
                            ref={seatingPlanContainerRef}
                            sx={{position: "relative", overflow: "hidden"}}
                        >
                            <SeatingPlan
                                seatingGroups={seatingGroups}
                                stages={stages}
                                seatSize={seatSize}
                                minSpacing={minSpacing}
                                handleElementClick={handleElementClick}
                                selectedElement={selectedElement}
                                svgWidth={svgDimensions.width}
                                svgHeight={svgDimensions.height}
                                updateElement={updateElement}
                                bringElementToFront={bringElementToFront}
                                elementOrder={elementOrder}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default SeatingPlanEditor;
