import React, {useEffect, useRef, useState} from "react";
import {QRCodeCanvas} from "qrcode.react";
import {useLocation} from "react-router-dom";
import Header from "../../General/HeaderComponents/Header";
import urlConfig from '../../../urlConfig';
import jsPDF from "jspdf";
import api from "../../../api";
import {CircularProgress, Container} from "@mui/material";
//import html2canvas from "html2canvas";

const QRCodeDemo = () => {
    const location = useLocation();
    const [ticketDetails, setTicketDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const qrRefs = useRef([]);


    // Extract paymentID from URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const paymentID = searchParams.get("paymentId");

    // Fetch customerId from sessionStorage
    const customerId = JSON.parse(sessionStorage.getItem("user"))?.id;

    useEffect(() => {
        sessionStorage.removeItem("reservationToken")

        const fetchTicketDetails = async () => {
            if (!paymentID) {
                setError("Payment ID is missing in the URL.");
                setLoading(false);
                return;
            }

            try {
                // Fetch ticket details using the paymentID
                const response = await api.get(`/customer/tickets/paymentID?paymentId=${paymentID}`);

                if (!response.status == 200) {
                    console.log("response: " + JSON.stringify(response));
                    throw new Error(`Failed to fetch ticket details for paymentID: ${paymentID}`);
                }

                const data = response.data;
                setTicketDetails(data);
            } catch (err) {
                console.error("Error fetching ticket details:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        setTimeout(fetchTicketDetails, 1000);
    }, [paymentID]);

    // ---- PDF-Generieren ----

    const generatePDF = () => {
        const pdf = new jsPDF();

        ticketDetails.forEach((ticket, index) => {
            // Greife auf das Canvas-Element für den QR-Code zu
            const canvas = qrRefs.current[index]?.querySelector("canvas");

            if (canvas) {
                const qrImageData = canvas.toDataURL("image/png");

                // Füge eine neue Seite hinzu (außer für die erste Seite)
                if (index > 0) {
                    pdf.addPage();
                }

                // Ticket-Header: Ticket4U
                pdf.setFontSize(16);
                pdf.setFont("helvetica", "bold");
                pdf.text(`Ticket4U - ${ticket.eventName}`, 10, 10);

                // Ticket-Inhalt (obere Hälfte)
                pdf.setFontSize(12);
                pdf.setFont("helvetica", "normal");
                pdf.text(`Ticket ${index + 1}`, 10, 20);
                pdf.addImage(qrImageData, "PNG", 10, 30, 50, 50); // QR Code

                pdf.text(`Eventname: ${ticket.eventName}`, 70, 40);
                pdf.text(`Datum: ${new Date(ticket.eventDate).toLocaleDateString("de-DE")}`, 70, 50);
                pdf.text(`Kaufdatum: ${new Date(ticket.buyingDate).toLocaleDateString("de-DE")}`, 70, 60);
                pdf.text(`Preis: €${ticket.price},-`, 70, 70);
                pdf.text(`Sitzgruppe: ${ticket.seatingGroup}`, 130, 40);
                pdf.text(`Sitztyp: ${ticket.seatType}`, 130, 50);
                pdf.text(`Reihe: ${ticket.rowNumber}`, 130, 60);
                pdf.text(`Sitznummer: ${ticket.seatNumber}`, 130, 70);

                // Gestrichelte Linie (zum Abschneiden)
                pdf.setLineWidth(0.5);
                pdf.setDrawColor(0, 0, 0);

                // Position der Linie und Abstand
                let x1 = 10;
                let y1 = 120;
                let x2 = 200;
                let y2 = 120;
                let dashLength = 5; // Länge der Striche
                let gapLength = 3;  // Abstand zwischen den Strichen

                for (let i = x1; i < x2; i += dashLength + gapLength) {
                    pdf.line(i, y1, i + dashLength, y2); // Zeichnet jeweils einen Strich
                }


                // Ticket-Unterseite (mit Kontaktinformationen)
                pdf.setFontSize(10);
                pdf.text("Ticket4U Diplomarbeitsgruppe", 10, 130);
                pdf.text("Anton Ehrenfried-Straße 10", 10, 140);
                pdf.text("2020 Hollabrunn", 10, 150);
            }

            if (!canvas) {
                console.warn(`QR-Code-Canvas für Ticket ${index + 1} konnte nicht gefunden werden.`);
                return; // Überspringt dieses Ticket
            }
        });

        // PDF zum Download bereitstellen
        pdf.save("Tickets.pdf");
    };

    const downloadPDF = async (paymentID, type) => {
        try {
            const url = type === 'ticket'
                ? `${urlConfig.apiBaseUrl}/customer/getTicketPDF?paymentId=${paymentID}`
                : `${urlConfig.apiBaseUrl}/customer/getInvoicePDF?paymentId=${paymentID}&customerId=${customerId}`;

            const response = await api.get(url, {responseType: 'blob'}); // Blobs direkt erhalten

            if (response.status !== 200) {
                throw new Error("Fehler beim Abrufen des PDFs");
            }

            const pdfBlob = response.data;
            const downloadUrl = window.URL.createObjectURL(pdfBlob);

            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `${type}_${paymentID}.pdf`;
            link.click();

            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("PDF-Download fehlgeschlagen:", error);
        }
    };

    if (loading) {
        return (
            <Container className="flex justify-center items-center h-screen">
                <CircularProgress/>
            </Container>
        );
    }
    if (error) {
        return <p className="text-red-500">Error: {error}</p>;
    }

    return (
        <div className="flex flex-col items-center pb-5 h-[100vh] overflow-y-auto">

            <div className="w-full mx-0 mb-[6%]">
                <Header/>
            </div>

            <h2 className="mb-5 text-xl font-bold">Ihre Tickets! (Scan beim Eingang)</h2>

            <div className="flex flex-col gap-8 w-full max-w-4xl">
                {ticketDetails.map((ticket, index) => (
                    <div
                        ref={(el) => (qrRefs.current[index] = el)}
                        key={ticket.uuid}
                        className="flex items-center justify-between bg-gray-100 p-6 rounded-lg shadow-md"
                    >
                        <div className="mr-6">
                            <QRCodeCanvas value={ticket.uuid} size={128}/>
                        </div>

                        {/* Ticket Informationen */}
                        <div className="flex flex-col w-full">
                            {/* Ticket-Header */}
                            <h3 className="text-2xl font-extrabold underline mb-4 font-serif">
                                Ticket {index + 1}
                            </h3>
                            <div className="grid grid-cols-2 gap-x-8">
                                {/* Linke Spalte */}
                                <div className="space-y-2">
                                    <p>
                                        <span className="font-semibold">Event:</span> {ticket.eventName}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Datum:</span>{" "}
                                        {new Date(ticket.eventDate).toLocaleDateString("de-DE")}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Kaufdatum:</span>{" "}
                                        {new Date(ticket.buyingDate).toLocaleDateString("de-DE")}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Preis:</span> €{ticket.price},-
                                    </p>
                                </div>

                                {/* Rechte Spalte */}
                                <div className="space-y-2">
                                    <p>
                                        <span className="font-semibold">Sitzgruppe:</span>{" "}
                                        {ticket.seatingGroup}
                                    </p>
                                    <p>
                                        <span className="font-semibold">Sitztyp:</span> {ticket.seatType}
                                    </p>

                                    <p>
                                        <span className="font-semibold">Reihe:</span> {ticket.rowNumber}
                                    </p>

                                    <p>
                                        <span className="font-semibold">Sitznummer:</span> {ticket.seatNumber}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
                <button
                    onClick={() => downloadPDF(paymentID, 'ticket')}
                    className="bg-blue-500 text-white p-4 mt-6 rounded-lg"
                >
                    Tickets herunterladen
                </button>
                <button
                    onClick={() => downloadPDF(paymentID, 'invoice')}
                    className="bg-blue-500 text-white p-4 mt-6 rounded-lg"
                >
                    Rechnung herunterladen
                </button>
            </div>
        </div>
    );
};

export default QRCodeDemo;
