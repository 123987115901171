import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import React from "react";

const Actions = ({ user, type, onOpenPaper }) => {
    function renderActions() {
        switch (type) {
            case "customer":
                return (
                    <button onClick={() => onOpenPaper("customer")}
                            className="text-gray-600 hover:text-gray-700 flex items-center">
                        <VisibilityIcon style={{ color: "green" }} />
                    </button>
                );
            case "organizer":
                return (
                    <>
                        <button onClick={() => onOpenPaper("organizer_accessOrgView")}
                                className="text-gray-600 hover:text-gray-700 flex items-center">
                            <VisibilityIcon style={{ color: "green" }} />
                        </button>
                        <button onClick={() => onOpenPaper("organizer_edit")}
                                className="text-gray-600 hover:text-gray-700 flex items-center">
                            <EditIcon style={{ color: "black" }} />
                        </button>
                    </>
                );
            case "seller":
                return (
                    <button onClick={() => onOpenPaper("seller")}
                            className="text-gray-600 hover:text-gray-700 flex items-center">
                        <VisibilityIcon style={{ color: "green" }} />
                    </button>
                );
            default:
                return null;
        }
    }

    return <div className="flex items-center gap-2">{renderActions()}</div>;
};

export default Actions;