import axios from 'axios';
import urlConfig from './urlConfig';
import {history} from "./navigate";


const tokenTimers = new Map();  // Token-Timer Map

// Axios-Instanz erstellen
const api = axios.create({
    baseURL: urlConfig.apiBaseUrl,
});

// Token aus sessionStorage holen und automatisch an alle Anfragen anhängen
api.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Antwort-Interceptor, um den neuen Token zu aktualisieren
api.interceptors.response.use(
    (response) => {
        const newToken = response.headers['authorization'];
        if (newToken) {
            sessionStorage.setItem('token', newToken);
            startTokenExpirationTimer(newToken);  // Übergebe den Token zur Ablaufüberwachung
        }
        return response;
    },
    (error) => Promise.reject(error)
);

function startTokenExpirationTimer(token, onTokenExpired) {
    try {
        const payload = JSON.parse(atob(token.split('.')[1])); // JWT Payload extrahieren
        const expiration = payload.exp * 1000; // `exp` in Millisekunden umwandeln
        const now = Date.now();
        const timeLeft = expiration - now; // Verbleibende Zeit berechnen

        // Debug Ausgabe der Token Ablaufzeit
        //console.log(`Token läuft ab in: ${Math.round(timeLeft / 1000)} Sekunden`);

        if (timeLeft > 0) {
            // Stoppe alte Timer
            for (const [, timeoutId] of tokenTimers) {
                clearTimeout(timeoutId);
            }
            tokenTimers.clear();

            // Setze neuen Timer
            const timeoutId = setTimeout(() => {
                console.warn("⚠️ Token ist abgelaufen! User wird ausgeloggt.");
                sessionStorage.clear();
                history.push("/?message=Ihre%20Session%20ist%20abgelaufen.%20Sie%20müssen%20sich%20erneut%20anmelden.");                window.location.reload();
                }, timeLeft);

            tokenTimers.set(token, timeoutId);  // Timer in Map speichern
        } else {
            console.warn("⚠️ Token ist bereits abgelaufen!");
            sessionStorage.clear();
            history.push("/?message=Ihre%20Session%20ist%20abgelaufen.%20Sie%20müssen%20sich%20erneut%20anmelden.");            window.location.reload();
        }
    } catch (error) {
        console.error("Fehler beim Parsen des Tokens:", error);
    }
}

export default api;