import {createContext, useContext, useState} from "react";
import {Alert, Snackbar} from "@mui/material";

const GeneralPopupContext = createContext(null);

export const GeneralPopupProvider = ({ children }) => {

    const [generalPopup, setGeneralPopup] = useState({
        open: false,
        message: "",
        severity: "info",
        position: { vertical: "bottom", horizontal: "center" }  // Setze eine Standardposition
    });

    const showPopup = (message, severity = "info", position = { vertical: "bottom", horizontal: "center" }) => {
        if (typeof message !== 'string') {
            console.error("Message must be a string");
            return;
        }
        setGeneralPopup({ open: true, message: message, severity: severity, position: position });
    };

    const handleClose = () => {
        setGeneralPopup({ ...generalPopup, open: false });
    };

    return (
        <GeneralPopupContext.Provider value={showPopup}>
            {children}
            <Snackbar
                open={generalPopup.open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={generalPopup.position}
            >
                <Alert onClose={handleClose} severity={generalPopup.severity} sx={{ width: "100%" }}>
                    {generalPopup.message}
                </Alert>
            </Snackbar>
        </GeneralPopupContext.Provider>
    );
};

export const useGeneralPopup = () => useContext(GeneralPopupContext);