import React, {createContext, useState, useContext} from "react";
import {Alert, Snackbar} from "@mui/material";

const PopupContext = createContext();

export const PopupProvider = ({children}) => {
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "success",
        position: "center"
    });

    return (
        <PopupContext.Provider value={setAlert}>
            {children}
            <Snackbar
                open={alert.open}
                autoHideDuration={4000}
                onClose={() => setAlert({...alert, open: false})}
                anchorOrigin={{vertical: "bottom", horizontal: alert.position}}
            >
                <Alert onClose={() => setAlert({...alert, open: false})} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </PopupContext.Provider>
    );
};

export const usePopup = () => useContext(PopupContext);

/*
import React, { createContext, useState, useContext } from "react";
import { Alert, Snackbar } from "@mui/material";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "success",
        position: "center",
        size: "small",  // Standardwert "small"
    });

    return (
        <PopupContext.Provider value={setAlert}>
            {children}
            <Snackbar
                open={alert.open}
                autoHideDuration={2000}
                onClose={() => setAlert({ ...alert, open: false })}
                anchorOrigin={{ vertical: "bottom", horizontal: alert.position }}
                sx={{
                    "& .MuiSnackbarContent-root": {
                        //minHeight: alert.size === "big" ? "100px" : "56px",
                        minHeight: "100px",
                    },
                }}
            >
                <Alert
                    onClose={() => setAlert({ ...alert, open: false })}
                    severity={alert.severity}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
        </PopupContext.Provider>
    );
};

export const usePopup = () => {
    const setAlert = useContext(PopupContext);

    return (message, severity = "success", position = "center", size = "small") => {
        setAlert({
            open: true,
            message,
            severity,
            position,
            size,
        });
    };
};
*/