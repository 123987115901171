import React, {createContext, useContext, useState, useEffect} from 'react';
import urlConfig from "../../../urlConfig";
import {modifyEvent, getEventsByOrganizer, getEventLocations} from './EventAPIService';
import {usePopup} from "./PopupProvider";

const EventsContext = createContext();

export const EventProvider = ({children}) => {
    const [eventsData, setEventsData] = useState(null);
    const organizer = JSON.parse(sessionStorage.getItem('user'));
    const setPopup = usePopup();

    const fetchData = async () => {
        try {
            const events = await getEventsByOrganizer(organizer);
            setEventsData(events);
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    };

    const checkOrganizersLocations = async () => {
        try {
            if (!sessionStorage.getItem('organizersLocations')) {
                const locations = await getEventLocations(organizer);
                // Bild aus location object entfernen
                const locationsWithoutImages = locations.map(location => {
                    const { image, ...locationWithoutImage } = location;
                    return locationWithoutImage; // Gib nur die notwendigen Daten zurück
                });
                sessionStorage.setItem('organizersLocations', JSON.stringify(locationsWithoutImages));
            }
        } catch (error) {
            console.error('Fehler beim Abrufen oder Speichern der Event-Locations:', error);
        }
    };

    useEffect( () => {
        fetchData();
        checkOrganizersLocations();
    }, [organizer.id]);

    const onNewEvent = async (newEvent) => {
        setEventsData(prevEventsData => [...prevEventsData, newEvent]);
    }

    const onEventChange = async (updatedEvent, eventId) => {
        try {
            let eventToUse = updatedEvent;

            if (eventId) {
                eventToUse = await modifyEvent(eventId, updatedEvent, setPopup);
            }

            setEventsData((prevEvents) => {
                if (!Array.isArray(prevEvents)) return [eventToUse];

                const eventIndex = prevEvents.findIndex(event => event.id === eventToUse.id);
                if (eventIndex > -1) {
                    const updatedEvents = [...prevEvents];
                    updatedEvents[eventIndex] = {...prevEvents[eventIndex], ...eventToUse};
                    return updatedEvents;
                }
                // DEBUG OUTPUT: console.log([...prevEvents, eventToUse])
                return [...prevEvents, eventToUse];
            });
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };

    const removeEvent = (eventId) => {
        setEventsData((prevEvents) => prevEvents.filter(event => event.id !== eventId));
    };

    const extractAnEventsImageData = (event) => {
        const locationsWithoutImage = event.organizer.locations.map(location => {
            return {id: location.id, description: location.description,}
        });
        event.organizer.locations = locationsWithoutImage;
        event.eventDates = event.eventDates.map(date => {
            return {id: date.id, date: date.date, location: locationsWithoutImage.find(l => l.id === date.location.id)}
        })
    }

    return (
        <EventsContext.Provider value={{eventsData, onNewEvent, onEventChange, removeEvent, fetchData, extractAnEventsImageData}}>
            {children}
        </EventsContext.Provider>
    );
};

// Hook zum Zugriff auf den Kontext
export const useEventProvider = () => {
    const context = useContext(EventsContext);
    if (!context) {
        throw new Error('useEventProvider must be used within an EventProvider');
    }
    return context;
};