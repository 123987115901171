import React, {useEffect, useState} from 'react';
import {Card, CardContent, Grid, Typography} from '@mui/material';
import TicketValidatorComponent from "../componets/memberComponents/TicketValidatorComponent";
import LocalSellerComponent from "../componets/memberComponents/LocalSellerComponent";

const Members = () => {
    return (
        <div className="mt-[5%] h-[90dvh] overflow-y-auto pb-10 mx-[-1rem]">
            <div className="">
                <TicketValidatorComponent/>
            </div>
            <div className="">
                <LocalSellerComponent/>
            </div>
        </div>
    );
};

export default Members;