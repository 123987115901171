import {Button, Card, CardActions, CardContent, Collapse, Grid, Typography, TextField, IconButton} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useState} from "react";
import urlConfig from "../../../urlConfig";
import api from "../../../api";

const MyTicketsComponent = ({customersPayments}) => {
    const [expandedEvent, setExpandedEvent] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [searchTerm, setSearchTerm] = useState("");
    const user = JSON.parse(sessionStorage.getItem("user"));

    const toggleExpand = (eventId) => {
        setExpandedEvent(expandedEvent === eventId ? null : eventId);
    };

    const toggleSortOrder = () => {
        setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    };

    const sortPayments = (payments) => {
        return [...payments].sort((a, b) => {
            return sortOrder === "asc"
                ? new Date(a.buyingDate) - new Date(b.buyingDate)
                : new Date(b.buyingDate) - new Date(a.buyingDate);
        });
    };
    console.log(customersPayments)
    const filteredPayments = sortPayments(customersPayments).filter((event) =>
        event.eventName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const downloadPDF = async (paymentID, type) => {
        try {
            const url = type === 'ticket'
                ? `${urlConfig.apiBaseUrl}/customer/getTicketPDF?paymentId=${paymentID}`
                : `${urlConfig.apiBaseUrl}/customer/getInvoicePDF?paymentId=${paymentID}&customerId=${user.id}`;

            const response = await api.get(url, {responseType: 'blob'});

            if (response.status !== 200) {
                throw new Error("Fehler beim Abrufen des PDFs");
            }

            const pdfBlob = response.data;
            const downloadUrl = window.URL.createObjectURL(pdfBlob);

            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `${type}_${paymentID}.pdf`;
            link.click();

            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("PDF-Download fehlgeschlagen:", error);
        }
    };

    return (
        <Grid container spacing={4} justifyContent="center">

            {/* Suchleiste */}
            <Grid item xs={8} md={4} className="text-center">
                <TextField
                    label="Event suchen"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    margin="normal"
                    sx={{width: '100%'}}
                />
            </Grid>

            {/* Pfeil zum Sortieren */}
            <Grid item xs={1} md={1} className="text-center"
                  sx={{display: "flex", justifyContent: "left", alignItems: "center"}}>
                <IconButton onClick={toggleSortOrder}>
                    <ExpandMoreIcon sx={{transform: sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)"}}/>
                </IconButton>
            </Grid>

            {filteredPayments.length === 0 ? (
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                    <Typography variant="h5" className="text-gray-600 text-center" sx={{marginTop: '3rem'}}>
                        Sie haben noch keine Tickets gekauft
                    </Typography>
                </Grid>
            ) : (
                filteredPayments.map((event) => (
                    <Grid item key={event.paymentID} xs={11} md={9} lg={9}>
                        <Card
                            className="shadow-lg hover:shadow-xl transition-shadow duration-300 bg-gray-900 text-white cursor-pointer">
                            <CardContent onClick={() => toggleExpand(event.paymentID)}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h6" className="font-bold text-darkgray">
                                            🎟️ {event.eventName}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" className="text-gray-800">
                                            <strong>Kaufdatum:</strong> {new Date(event.buyingDate).toLocaleDateString()}, {new Date(event.buyingDate).toLocaleTimeString()} Uhr
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <Collapse in={expandedEvent === event.paymentID}>
                                <CardContent className="text-left">
                                    <Typography className="text-gray-700">
                                        <strong>📅 Event
                                            am:</strong> {new Date(event.eventDate).toLocaleDateString()}, {new Date(event.eventDate).toLocaleTimeString()} Uhr
                                    </Typography>
                                    <Typography className="text-gray-700">
                                        <strong>💰 Gesamtpreis: </strong> {event.amount} €
                                    </Typography>
                                    <Typography className="text-gray-700 font-bold mt-2">
                                        <strong>🪑 Sitzplätze:</strong>
                                    </Typography>
                                    {event.tickets.map((ticket) => (
                                        <Typography key={ticket.id} className="text-gray-700">
                                            • Reihe {ticket.rowNumber}, Platz {ticket.seatNumber} ({ticket.seatingGroup})
                                            – {ticket.price} €
                                        </Typography>
                                    ))}
                                </CardContent>
                                <CardActions className="flex justify-start p-4 mb-3">
                                    <Button onClick={() => downloadPDF(event.paymentID, "invoice")} size="small"
                                            color="primary" variant="outlined">
                                        Rechnung herunterladen
                                    </Button>
                                    <Button onClick={() => downloadPDF(event.paymentID, "ticket")} size="small"
                                            color="primary" variant="outlined">
                                        Tickets herunterladen
                                    </Button>
                                </CardActions>
                            </Collapse>
                        </Card>
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default MyTicketsComponent;