import React from "react";
import { Paper, Button } from "@mui/material";

const PaperComponent = ({ open, onClose, children }) => {
    if (!open) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center">
            <Paper elevation={24} sx={{ width: '80%', height: '80%', padding: 2, position: 'fixed', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {children}
                <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={onClose} variant="contained" color="primary">
                        Schließen
                    </Button>
                </div>

            </Paper>
        </div>
    );
};

export default PaperComponent;