import React, {useEffect, useRef, useState} from 'react';
import Header from "../../General/HeaderComponents/Header";
import {useNavigate, useLocation} from 'react-router-dom';
import urlConfig from '../../../urlConfig';
import {CircularProgress, Container} from "@mui/material";
import api from "../../../api";

const CheckoutPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const {
        selectedSeats,
        date,
        eventid,
        totalAmount
    } = location.state || JSON.parse(sessionStorage.getItem('reservationData')) || {};

    const [formType, setFormType] = useState(null); // 'guest', 'register', 'login'

    const [Customer, setCustomer] = useState(JSON.parse(sessionStorage.getItem("user")));

    const [timer, setTimer] = useState(sessionStorage.getItem("timer") || 600); // Timer für 10 Minuten
    const [reservationExpired, setReservationExpired] = useState(false); // Reservierung abgelaufen?

    const hasFetched = useRef(false);
    const hasConfirmed = useRef(false)

    const [isLeaving, setIsLeaving] = useState(false); // Ob der Benutzer die Seite verlassen möchte

    const shouldWarnRef = useRef(true);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (shouldWarnRef.current) {  // Bedingung, ob gewarnt werden soll
                e.preventDefault();
                e.returnValue = ''; // für Chrome
            }
        };

        const handleBackButton = (e) => {
            if (hasConfirmed.current) return
            const confirmLeave = window.confirm('Wenn Sie die Seite verlassen sind Ihre Tickets nicht mehr für Sie reserviert');
            if (confirmLeave) {
                releaseSeats()
                hasConfirmed.current = true
                window.history.go(-2);
            } else {
                e.preventDefault();
            }
        };

        window.history.pushState(null, '', window.location.href);

        window.addEventListener('popstate', handleBackButton);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (selectedSeats || date || eventid || totalAmount) {
            const dataToStore = {
                selectedSeats,
                date,
                eventid,
                totalAmount
            };
            sessionStorage.setItem('reservationData', JSON.stringify(dataToStore));
        }
    }, [selectedSeats, date, eventid, totalAmount]);


    const releaseSeats = async () => {
        try {
            await fetch(`${urlConfig.apiBaseUrl}/public/ticket/cancelReservation`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    seatIDs: selectedSeats.map(seat => seat.id),
                    reservationToken: sessionStorage.getItem("reservationToken")
                })
            });
            sessionStorage.removeItem("reservationToken")
        } catch (error) {
            console.error("Fehler beim Freigeben der Sitze:", error);
        }
    };
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = ''; // Standardverhalten, um die Bestätigung anzuzeigen
    };

    useEffect(() => {
        if (hasFetched.current) return
        hasFetched.current = true;
        const reserveSeats = async () => {
            try {
                const response = await fetch(`${urlConfig.apiBaseUrl}/public/ticket/reserve`, {
                    method: "POST",
                    headers: {
                        "Accept": "*/*",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(selectedSeats.map((seat) => seat.id)),
                });

                if (!response.ok) {
                    if (response.status === 400) {
                        alert("Tickets sind nicht mehr verfügbar.");
                        navigate("/seatingPlan", {
                            state: {date, eventid},
                        });
                    }
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                if (result && result.reservationToken) {
                    sessionStorage.setItem("reservationToken", result.reservationToken);
                }
            } catch (error) {
                console.error("Fehler bei der Reservierung:", error);
            }
        };

        reserveSeats();


    }, []);

    useEffect(() => {
        // Timer für die Reservierung
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    setReservationExpired(true);
                    clearInterval(countdown);
                }
                sessionStorage.setItem("timer", prevTimer)
                return prevTimer - 1;
            });
        }, 1000);

        // Cleanup und Freigabe von Plätzen, wenn der Benutzer die Seite verlässt
        return () => {
            clearInterval(countdown);
            if (!reservationExpired) {
                //releaseSeats();
            }
            sessionStorage.removeItem("timer")
        };
    }, []);


    useEffect(() => {
        if (reservationExpired) {
            alert('Die Reservierung ist abgelaufen. Die Plätze werden freigegeben.');
            navigate("/seatingPlan", {
                state: {
                    date: date,
                    eventid: eventid
                }
            });
        }
    }, [reservationExpired]);


    // ---- REGISTER ----

    const [formDataRegister, setFormDataRegister] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        password: '',
    });

    const handleChangeRegister = (e) => {
        setFormDataRegister({
            ...formDataRegister,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmitRegister = async (e) => {
        e.preventDefault();

        const customerDataRegister = {
            firstName: formDataRegister.firstName,
            lastName: formDataRegister.lastName,
            address: formDataRegister.address,
            email: formDataRegister.email,
            password: formDataRegister.password,
        };
        console.log(JSON.stringify(customerDataRegister));
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerDataRegister)
            });

            if (response.ok) {
                alert('Registrierung erfolgreich!');
            } else {
                alert('Registrierung fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };

    // ---- LOGIN ----

    const [formDataLogin, setFormDataLogin] = useState({username: '', password: ''});

    const handleChangeLogin = (e) => {
        setFormDataLogin({...formDataLogin, [e.target.name]: e.target.value});
    };

    const handleSubmitLogin = async (e) => {
        e.preventDefault();

        const customerDataLogin = {username: formDataLogin.username, password: formDataLogin.password};


        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/auth/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(customerDataLogin),
            });

            const data = await response.json();

            if (response.ok) {
                const token = `${data.tokenType}${data.token}`;
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('userType', data.type);

                if (data.type === 'CUSTOMER') {
                    sessionStorage.setItem('user', JSON.stringify(data.customer));
                    setCustomer(data.customer)
                } else if (data.type === 'ORGANIZER') {
                    sessionStorage.setItem('user', JSON.stringify(data.organizer));
                } else if (data.type === 'ADMIN') {
                    sessionStorage.setItem('user', "Master");
                }
            } else {
                alert('Login fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };

    // ---- GUEST ----

    const [formDataGuest, setFormDataGuest] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
    });

    const handleChangeGuest = (e) => {
        setFormDataGuest({
            ...formDataGuest,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmitGuest = async (e) => {
        e.preventDefault();

        const customerDataGuest = {
            firstName: formDataGuest.firstName,
            lastName: formDataGuest.lastName,
            address: formDataGuest.address,
            email: formDataGuest.email,
            guest: true
        };
        console.log(JSON.stringify(customerDataGuest));
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/admin/customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerDataGuest)
            });

            const data = await response.json();

            if (response.ok) {
                alert('Gast-Registrierung erfolgreich!');

                setCustomer(data);

                /*if (data.type === 'CUSTOMER') {
                    sessionStorage.setItem('user', JSON.stringify(data.customer));
                } else if (data.type === 'ORGANIZER') {
                    sessionStorage.setItem('user', JSON.stringify(data.organizer));
                } else if (data.type === 'ADMIN') {
                    sessionStorage.setItem('user', "Master");
                }

                 */

            } else {
                alert('Gast-Registrierung fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };


    // ---- PAYMENT ----

    const handlePayment = async () => {

        if (!Customer) {
            navigate("/login", {
                state: {
                    redirectTo: "/orderoverview",
                    seatingPlanState: {selectedSeats, date, eventid},
                }
            });
            return;
        }

        setLoading(true);

        const data = {
            amount: totalAmount,
            customerID: Customer.id,
            eventID: eventid,
            dateID: date.id,
            seatIDs: selectedSeats.map(seat => seat.id),
            price: selectedSeats.map(seat => seat.price)
        };

        // Payment erstellen via Backend
        await api.post(`/payment/create`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            shouldWarnRef.current = false

            if (res.data.checkoutUrl) {
                window.location.assign(res.data.checkoutUrl);
            } else {
                throw new Error('No checkout URL received');
            }
        }).catch(err => {
            console.error('Payment initiation failed:', err);
            alert("Failed to start the payment process. Please try again.");
        })
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `Tickets reserviert für: ${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };

    // ---- RETURN ----

    return (
        <div>{loading && (
            <Container className="flex justify-center items-center h-screen">
                <CircularProgress/>
            </Container>
        )}
            <div className="flex">
                <Header/>
                <div className="mt-16 w-1/2 p-4">

                    {Customer ? (
                        <div>
                            <h2>Hallo {Customer.firstName}!</h2>
                            <p>Sie sind bereits angemeldet.</p>
                        </div>
                    ) : (
                        <>

                            <h2>Registrierung/Login</h2>

                            {/* Auswahl zwischen Gast, Registrierung und Anmeldung */}
                            <div className="mb-4 mt-5">
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="formType"
                                        value="register"
                                        onChange={() => setFormType('register')}
                                    /> Registrieren
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="formType"
                                        value="login"
                                        onChange={() => setFormType('login')}
                                    /> Anmelden
                                </label>
                                <label className="mr-4">
                                    <input
                                        type="radio"
                                        name="formType"
                                        value="guest"
                                        onChange={() => setFormType('guest')}
                                    /> Als Gast bestellen
                                </label>
                            </div>

                            {/* Dynamische Anzeige von Formularfeldern basierend auf der Auswahl */}
                            {formType === 'guest' && (
                                <form onSubmit={handleSubmitGuest} className="flex flex-col items-center">

                                    <input
                                        //<form onSubmit={handleSubmit}>
                                        type="text"
                                        name="firstName"
                                        placeholder="Vorname"
                                        value={formDataGuest.firstName}
                                        onChange={handleChangeGuest}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Nachname"
                                        value={formDataGuest.lastName}
                                        onChange={handleChangeGuest}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Adresse"
                                        value={formDataGuest.address}
                                        onChange={handleChangeGuest}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formDataGuest.email}
                                        onChange={handleChangeGuest}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <button type="submit"
                                            className="w-1/2 p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                                    >Register
                                    </button>
                                </form>
                            )}

                            {formType === 'register' && (

                                <form onSubmit={handleSubmitRegister} className="flex flex-col items-center">

                                    <input
                                        //<form onSubmit={handleSubmit}>
                                        type="text"
                                        name="firstName"
                                        placeholder="Vorname"
                                        value={formDataRegister.firstName}
                                        onChange={handleChangeRegister}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Nachname"
                                        value={formDataRegister.lastName}
                                        onChange={handleChangeRegister}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Adresse"
                                        value={formDataRegister.address}
                                        onChange={handleChangeRegister}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formDataRegister.email}
                                        onChange={handleChangeRegister}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Passwort"
                                        value={formDataRegister.password}
                                        onChange={handleChangeRegister}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <button type="submit"
                                            className="w-1/2 p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                                    >Registrieren
                                    </button>
                                </form>
                            )}

                            {formType === 'login' && (

                                <form onSubmit={handleSubmitLogin} className="flex flex-col items-center">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        name="username"
                                        onChange={handleChangeLogin}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        onChange={handleChangeLogin}
                                        required
                                        className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                    />
                                    <button
                                        type="submit"
                                        className="w-1/2 p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                                    >
                                        Login
                                    </button>
                                </form>
                            )}
                        </>
                    )}
                </div>
                <div
                    className="absolute top-16 left-1/2 transform -translate-x-1/2 text-gray-800 p-2 text-xl font-bold">
                    {!reservationExpired ? (
                        <>
                            <p>{formatTime(timer)}</p>
                        </>
                    ) : (
                        <p className="text-red-600">Die Reservierung ist abgelaufen!</p>
                    )}
                </div>

                <div className="mt-16 w-1/2 p-4 flex flex-col items-center">


                    <h2 style={{fontSize: '30px'}}>Bestellübersicht</h2>
                    <ul className="w-full flex flex-col items-center
                mb-5 max-h-[calc(100vh-250px)] h-[calc(100vh-250px)] overflow-y-auto"> {/* Scrollen hier */}
                        {selectedSeats.map((seat) => (
                            <li key={seat.id}
                                className="border border-gray-500 bg-white-400 p-4 my-2 shadow-sm rounded-md w-1/2 flex items-center justify-center">
                                <div className="flex items-center space-x-4">
                                    <span className="px-2 py-1">Reihe: {seat.rowNumber}</span>
                                    <span className="px-2 py-1">Sitz: {seat.seatNumber}</span>
                                    <span className="px-2 py-1">Gruppe: {seat.group.name}</span>
                                    <span className="px-2 py-1">Preis: {seat.price}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <h3>Gesamtbetrag: €{totalAmount}</h3>
                    <button
                        onClick={handlePayment}
                        className="mt-3 bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
                    >
                        Zahlung starten
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;
