import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import MainPage from "./components/General/Mainpage/MainPage";
import Login from './components/General/LoginView/Login';
import Register from './components/General/RegisterView/Register';
import AdminView from './components/Admin/AdminView';
import OrganizerView from './components/Organizer/OrganizerView';
import EventDetailedView from './components/Customer/EventDetailedView/EventDetailedView';
import SeatingPlanView from './components/Customer/SeatingPlanView/SeatingPlanView';
import QRCodeDemo from "./components/Customer/QR_CodeDemo/QRCode";
import ScanQRCode from "./components/Customer/QR_CodeDemo/scanqrcode";
import OrderOverview from './components/Customer/OrderOverview/OrderOverview.jsx';

import Dashboard from './components/Organizer/SidebarPages/Dashboard';
import Events from './components/Organizer/SidebarPages/Events';
import Members from './components/Organizer/SidebarPages/Members';
import SeatingPlanEditorOrganizer from "./components/Organizer/SidebarPages/SeatingPlanEditorOrganizer";
import OauthResponse from "./components/General/LoginView/OauthResponse";
import urlConfig from "./urlConfig";
import Location from "./components/Organizer/SidebarPages/Location";
import CustomerHomeView from "./components/Customer/CustomerHomeView/CustomerHomeView";
import {GeneralPopupProvider} from "./components/General/PopupComponents/GeneralPopupProvider";
import LocalSellerView from "./components/LocalSeller/LocalSellerView";
import ResetPassword from "./components/General/LoginView/ResetPassword";

const App = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [dynamicComponent, setDynamicComponent] = useState(null);
    const knownRoutes = new Set([
        "/",
        "/login",
        "/register",
        "/admin",
        "/organizer",
        "/organizer/overview",
        "/organizer/events",
        "/organizer/locationEditor",
        "/organizer/members",
        "/organizer/seatingPlanEditor",
        "/customer/home",
        "/localSeller",
        "/eventDetails",
        "/seatingPlan",
        "/qrcode",
        "/orderoverview",
        "/oauthResponse",
    ]);

    useEffect(() => {
        const checkDynamicRoute = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${urlConfig.apiBaseUrl}/public/url?url=${location.pathname.substring(1)}`);
                if (!response.ok) throw new Error("Fehler beim Abruf der URL");

                const data = await response.json();

                if (data === "TICKET_VALIDATOR") {
                    setDynamicComponent(<ScanQRCode />);
                } else if (data === "RESET_PASSWORD") {
                    setDynamicComponent(<ResetPassword />);
                } else {
                    setDynamicComponent(null); // Unbekannte Route -> 404
                }
            } catch (error) {
                console.error(error);
                setDynamicComponent(null);
            } finally {
                setLoading(false);
            }
        };

        if (!knownRoutes.has(location.pathname)) {
            checkDynamicRoute();
        } else {
            setDynamicComponent(null); // Reset für bekannte Routen
        }
    }, [location.pathname]);
    return (
        <GeneralPopupProvider>
            <div className="min-h-screen bg-white-100">
                <main className="text-center">
                    <Routes>
                        {/* MainPage Routen */}
                        <Route path="/" element={<MainPage/>}/> {/* MainPage */}
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/admin" element={<AdminView/>}/>

                        {/* Organizer Routen */}
                        <Route path="/organizer" element={<OrganizerView/>}>
                            <Route path="overview" element={<Dashboard/>}/>
                            <Route path="events" element={<Events/>}/>
                            <Route path="locationEditor" element={<Location/>}/>
                            <Route path="members" element={<Members/>}/>
                            <Route path="seatingPlanEditor" element={<SeatingPlanEditorOrganizer/>}/>
                        </Route>

                        {/* nicht zugeordnete */}
                        <Route path="/customer/home" element={<CustomerHomeView/>}/>
                        <Route path="/localSeller" element={<LocalSellerView/>}/>
                        <Route path="/eventDetails" element={<EventDetailedView/>}/>
                        <Route path="/seatingPlan" element={<SeatingPlanView/>}/>
                        <Route path="/qrcode" element={<QRCodeDemo/>}/>
                        <Route path="/orderoverview" element={<OrderOverview/>}/>
                        <Route path="/oauthResponse" element={<OauthResponse/>}/>

                        <Route path="*" element={loading ? <p>Laden...</p> : dynamicComponent || <p>404 - Seite nicht gefunden</p>} />
                    </Routes>
                </main>
            </div>
        </GeneralPopupProvider>
    );
};

export default App;