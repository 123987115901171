import React, {useState, useEffect} from "react";
import {Box, Button, TextField, Typography, Grid, FormControl, InputLabel, Select, MenuItem} from "@mui/material";

const AddSeatingGroup = ({onAdd, onCancel, seatClasses}) => {
    const [name, setName] = useState("");
    const [rotation, setRotation] = useState(0);
    const [rows, setRows] = useState(1);
    const [seatsPerRow, setSeatsPerRow] = useState(1);
    const [selectedSeatClass, setSelectedSeatClass] = useState("");


    useEffect(() => {
        if (seatClasses.length > 0) {
            setSelectedSeatClass(seatClasses[0].name); // Automatische Vorauswahl
        }
    }, [seatClasses]);

    const handleAdd = () => {
        if (!name.trim()) {
            alert("Bitte geben Sie einen Namen ein.");
            return;
        }

        if (!selectedSeatClass) {
            alert("Bitte wählen Sie eine Sitzklasse aus.");
            return;
        }

        onAdd({
            name,
            rotation: parseFloat(rotation),
            rows: parseInt(rows, 10),
            seatsPerRow: parseInt(seatsPerRow, 10),
            seatClass: seatClasses.find(seatClass => seatClass.name === selectedSeatClass), // Sitzklasse wird in SeatingGroup gespeichert
        });
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2,
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 3
        }}>
            <Typography variant="h6" gutterBottom>Neue Sitzgruppe hinzufügen</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth label="Name der Sitzgruppe" variant="outlined" value={name}
                               onChange={(e) => setName(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Rotation (Grad)" type="number" variant="outlined" value={rotation}
                               onChange={(e) => setRotation(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Anzahl der Reihen" type="number" variant="outlined" value={rows}
                               onChange={(e) => setRows(e.target.value)} inputProps={{min: 1}}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Sitze pro Reihe" type="number" variant="outlined" value={seatsPerRow}
                               onChange={(e) => setSeatsPerRow(e.target.value)} inputProps={{min: 1}}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel>Sitzklasse</InputLabel>
                        <Select value={selectedSeatClass} onChange={(e) => setSelectedSeatClass(e.target.value)}>
                            {seatClasses.map((seatClass) => (
                                <MenuItem key={seatClass.name} value={seatClass.name}>
                                    {seatClass.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{display: "flex", justifyContent: "flex-end", gap: 2, mt: 2}}>
                <Button variant="contained" color="primary" onClick={handleAdd}>Hinzufügen</Button>
                <Button variant="outlined" color="secondary" onClick={onCancel}>Abbrechen</Button>
            </Box>
        </Box>
    );
};

export default AddSeatingGroup;
