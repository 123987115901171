import api from "../../../api";

const getEventByEventId = async (id) => {
    try {
        const response = await api.get(`/public/event/${id}`);
        return response.data;
    } catch (error) {
        console.log("Error fetching events: ", error);
    }
};

const getEventsByOrganizer = async (organizer) => {
    try {
        console.log("organizer: " + JSON.stringify(organizer));
        const response = await api.get(`/organizer/events/${organizer.id}`);
        console.log("events by organizer: " + response.data);
        return response.data;
    } catch (error) {
        console.log("Error fetching events: ", error);
    }
};

const getStatusInformation = async (eventDateId) => {  // get status info for an events date
    try {
        const response = await api.get(`/public/date/status/${eventDateId}`);
        return response.data;
    } catch (error) {
        console.log("Error fetching date status: ", error);
    }
}

const addEvent = async (eventData, organizerId, setPopup) => {
    try {
        const response = await api.post(`/organizer/event/${organizerId}`, eventData);
        if (setPopup) setPopup({open: true, message: "Event hinzugefügt", severity: "success", position: "center"});
        return response.data;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Event konnte nicht hinzugefügt werden!",
            severity: "error",
            position: "center"
        });
        throw new Error('Error adding event');
    }
};

const deleteEvent = async (eventId, setPopup) => {
    try {
        const response = await api.delete(`/organizer/event/${eventId}`);
        if (response.status === 200) {
            if (setPopup) setPopup({open: true, message: "Event gelöscht", severity: "success", position: "center"});
            return response.data;
        }
        return null;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Event konnte nicht gelöscht werden!",
            severity: "error",
            position: "center"
        });
        throw new Error('Error deleting event');
    }
};

const modifyEvent = async (eventId, updatedEventData, setPopup) => {
    try {
        const response = await api.patch(`/organizer/event/${eventId}`, updatedEventData);

        if (setPopup) setPopup({
            open: true,
            message: "Event erfolgreich aktualisiert",
            severity: "success",
            position: "center"
        });

        return response.data;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Event konnte nicht aktualisiert werden!",
            severity: "error",
            position: "center"
        });
        throw new Error('Error modifying event');
    }
};


const setEventDates = async (eventDateId, data, setPopup) => {
    try {
        const response = await api.patch(`/organizer/dates/${eventDateId}`, data);
        if (setPopup) {
            setPopup({
                open: true,
                message: "Termin erfolgreich aktualisiert",
                severity: "success",
                position: "center",
                size: "big",
            });
        }
        return response.data;
    } catch (error) {
        if (setPopup) {
            setPopup({
                open: true,
                message: "Termin konnte nicht aktualisiert werden!",
                severity: "error",
                position: "center",
            });
        }
        console.error('Error modifying eventDate: ', error);
    }
}

const deleteEventDate = async (dateId, setPopup) => {
    try {
        const response = await api.delete(`/organizer/date/${dateId}`);
        if (response.status === 200) {
            if (setPopup) setPopup({open: true, message: "Event gelöscht.", severity: "success", position: "center"});
            return response.data;
        }
        return null;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Event konnte nicht gelöscht werden!",
            severity: "error",
            position: "center"
        });
        throw new Error('Error deleting event');
    }
}


const getEventLocations = async (organizer, setPopup) => {
    if (!organizer) {
        if (setPopup) {
            setPopup({
                open: true,
                message: "Veranstalter ist nicht definiert!",
                severity: "error",
                position: "center",
            });
        }
        console.error("Veranstalter ist nicht definiert");
        return [];
    }
    try {
        const response = await api.get(`/organizer/locations/${organizer.id}`, {
                headers: { "Content-Type": "application/json" } // WICHTIG
            });
        return response.data;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Fehler beim Laden der Standorte!",
            severity: "error",
            position: "center"
        });
        console.error(error);
        return [];
    }
};

const addEventLocation = async (organizer, data, setPopup) => {
    try {
        const response = await api.post(
            `/organizer/location/${organizer.id}`,
            data,
            { headers: { "Content-Type": "application/json" } }
        );

        if (setPopup) setPopup({open: true, message: "Standort hinzugefügt", severity: "success", position: "center"});
        return response.data;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Fehler beim Laden der Standorte!",
            severity: "error",
            position: "center"
        });
        console.error(error);
        return [];
    }
};

const setOrganizersLocations = async (data, setPopup) => {
    try {
        const response = await api.patch(`/organizer/locations`, data);
        if (setPopup) setPopup({
            open: true,
            message: "Event erfolgreich gespeichert",
            severity: "success",
            position: "center"
        });
        return response.data;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Fehler beim Speichern der Standorte!",
            severity: "error",
            position: "center"
        });
        console.error(error);
        return [];
    }
};

const deleteEventLocation = async (id, setPopup) => {
    try {
        const response = await api.delete(`/organizer/location/${id}`);
        if (response.status === 200) {
            if (setPopup) setPopup({
                open: true,
                message: "Standort gelöscht",
                severity: "success",
                position: "center"
            });
            return response.data;
        }
        return response.data;
    } catch (error) {
        if (setPopup) setPopup({
            open: true,
            message: "Standort konnte nicht gelöscht werden!",
            severity: "error",
            position: "center"
        });
        throw new Error('Error deleting event location');
    }
};


const getSeatClass = async (eventId, setPopup) => {
    try {
        console.log("eventId", eventId);
        const response = await api.get(`/public/event/seatClasses/${eventId}`);
        console.log("response ", JSON.stringify(response.data));
        if (setPopup) setPopup({
            open: true,
            message: "Sitzklassen erfolgreich geladen",
            severity: "error",
            position: "center"
        });
        return response.data;
    } catch (error) {
        console.log("Error fetching events: ", error);
        if (setPopup) setPopup({
            open: true,
            message: "Sitzklassen wurden nicht geladen",
            severity: "error",
            position: "center"
        });
    }
}

const deleteSeatClass = async (seatClassId, setPopup) => {
    try {
        const response = await api.delete(`/organizer/event/seatClass/${seatClassId}`);
        if (response.status === 200) {
            if (setPopup) setPopup({
                open: true,
                message: "Sitzklasse erfolgreich entfernt",
                severity: "success",
                position: "center"
            });
            return response.data;
        }
        return null;
    } catch (e) {
        console.error(e);
        if (setPopup) setPopup("Sitzklasse konnte nicht entfernt werden", "success", "center");
    }
}

const setEventsSeatClasses = async (seatClassId, data) => {
    try {
        const response = await api.patch(`/organizer/event/seatClasses/${seatClassId}`, data);
        return response.data;
    } catch (error) {
        console.error('Error modifying eventDate: ', error);
    }
}

export {
    getEventByEventId,
    getEventsByOrganizer,
    getStatusInformation,
    addEvent,
    deleteEvent,
    modifyEvent,

    setEventDates,
    deleteEventDate,

    getEventLocations,
    addEventLocation,
    setOrganizersLocations,
    deleteEventLocation,

    getSeatClass,
    deleteSeatClass,
    setEventsSeatClasses,
};