import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Switch} from "@mui/material";
import {Menu} from "lucide-react";
import {FaUserCircle} from "react-icons/fa";
import Sidebar from "./HeaderSidebar";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [userType, setUserType] = useState(sessionStorage.getItem('userType'));
    const [loggedInAs, setLoggedInAs] = useState("");
    const [isAnimating, setIsAnimating] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const {pathname} = location;

    useEffect(() => {
        const admin = JSON.parse(sessionStorage.getItem('admin'));
        const user = JSON.parse(sessionStorage.getItem('user'));

        if (admin) {
            if (userType === "ADMIN") setLoggedInAs(admin.email);
        } else if (user) {
            if (userType === "CUSTOMER") setLoggedInAs(user.firstName);
            if (userType === "ORGANIZER") setLoggedInAs(user.name);
            if (userType === "LOCALSELLER") setLoggedInAs(user.email);
        }
    }, [userType]);

    useEffect(() => {
        setIsChecked(location.pathname.startsWith('/organizer') ||
            location.pathname.startsWith('/customer') ||
            location.pathname.startsWith('/localSeller') ||
            location.pathname.startsWith('/admin')
        );
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdown = document.querySelector('.dropdown');
            if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.user-info')) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible((prev) => !prev);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => {
            return !prev;
        });
    };

    const handleLogout = () => {
        sessionStorage.clear();
        setLoggedInAs(null);
        setUserType(null);
        navigate('/');
    };

    const handleViewSwitch = () => {
        setDropdownVisible(false);
        setIsChecked((prev) => {
            let newChecked = !prev;
            let newPath = "/";
            if (userType === "ADMIN") {
                if (newChecked && pathname.startsWith("/organizer")) {
                    newChecked = false;
                }
                newPath = newChecked ? '/admin' : '/';
            } else if (userType === "CUSTOMER") {
                newPath = newChecked ? '/customer/home' : '/';
            } else if (userType === "ORGANIZER") {
                newPath = newChecked ? '/organizer/overview' : '/';
            } else if (userType === "LOCALSELLER") {
                newPath = newChecked ? '/localSeller' : '/';
            }

            navigate(newPath);
            return newChecked;
        });
    };

    const getPageLabel = () => {
        if (pathname === '/') return 'Hauptseite';
        if (pathname.startsWith('/eventDetails')) return 'Details & Termine';
        if (pathname.startsWith('/seatingPlan')) return 'Saalplan Übersicht';
        if (pathname.startsWith('/organizer')) return 'Organisation';
        if (pathname.startsWith('/login') || pathname.startsWith('/register')) return null;
        if (pathname.startsWith('/admin')) return 'Admin Ansicht';
        if (pathname.startsWith('/orderoverview')) return 'Bestellübersicht';
        if (pathname.startsWith('/localSeller')) return 'Externer Verkäufer';
        return 'Zurück';
    };

    const getUserLabel = () => {
        switch (userType) {
            case 'CUSTOMER':
                return 'Kundenansicht';
            case 'ORGANIZER':
                return 'Organisatorenansicht';
            case 'ADMIN':
                return 'Adminansicht';
            case 'LOCALSELLER':
                return 'POS-Verkäufer Ansicht';
            default:
                return 'Unbekannt';
        }
    };

    return (
        <nav className="min-h-[58px] bg-black-500 text-white fixed top-0 w-full z-50 shadow-md">
            <div className="flex items-center justify-between px-6 py-3"> {/* HIER */}
                {/* Section 1 / left */}
                <div>
                    {/* active site label / show only on big screens  */}
                    <p className="px-4 text-2xl font-semibold text-left hidden lg:block">
                        {getPageLabel() && (
                            <span className="cursor-pointer" onClick={() => {
                                if (window.location.pathname !== '/') {
                                    window.history.back();  // zurück wenn nicht auf Homepage
                                }
                            }}>
                            {getPageLabel()}
                        </span>
                        )}
                    </p>

                    {/* Burger-Menu für Mobile / show only on small screens */}
                    <button className="block lg:hidden" onClick={() => {
                        console.log("Burger-Menu Button wurde geklickt!");
                        toggleSidebar();
                    }}>
                        <Menu size={28}/>
                    </button>

                    <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
                </div>

                {/* Section 2 / Titel - Mittig platzieren */}
                <div
                    className="absolute inset-0 flex justify-center items-center text-2xl font-bold pointer-events-none">
                    <span className="cursor-pointer pointer-events-auto"
                          onClick={() => {
                              if (!pathname.startsWith('/orderoverview')) {
                                  navigate("/");
                              }
                          }}>
                        Ticket4U
                    </span>
                </div>

                {/* Section 3 / Profil-Icon mit Dropdown */}
                <div className="relative text-2xl font-semibold text-right px-4">
                    {loggedInAs ? (
                        <span className="relative cursor-pointer user-info flex items-center gap-2"
                              onClick={toggleDropdown}>
                            <h2 className="hidden lg:block">Hallo, {loggedInAs}</h2>
                            <FaUserCircle size={30} className="lg:hidden"/>
                            <span className="ml-2 hidden lg:block">▼</span>
                            {dropdownVisible && (
                                <div
                                    className="dropdown absolute right-0 top-full mt-2 bg-white text-black rounded-lg shadow-lg p-4 z-50 min-w-[150px] text-lg">
                                    {userType && (
                                        <div className="flex justify-between items-center mb-4">
                                            <span>{getUserLabel()}</span>
                                            <Switch
                                                checked={isChecked}
                                                onChange={handleViewSwitch}
                                                color="primary"
                                                disabled={isAnimating}/>
                                        </div>
                                    )}
                                    <button className="w-full py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                                            onClick={handleLogout}>Logout
                                    </button>
                                </div>
                            )}
                        </span>
                    ) : (
                        location.pathname !== "/login" && location.pathname !== "/register" && (
                            <span className="cursor-pointer flex row gap-5"
                                  onClick={() => navigate("/login", {state: {redirectTo: window.location.pathname}})}>
                                <h2 className="hidden lg:block">Login</h2>
                                <FaUserCircle size={30}/>
                            </span>
                        )
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Header;