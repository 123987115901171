import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import api from "../../api";
import Header from "../General/HeaderComponents/Header";
import TableComponent from "./components/TableComponent";
import Action from "./components/Actions";
import {Card, CardContent, CircularProgress, Container, Tabs, Tab, Grid, buttonClasses} from "@mui/material";
import PaperComponent from "./components/PaperComponent";

const AdminView = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [organizers, setOrganizers] = useState([]);
    const [events, setEvents] = useState([]);
    const [localSellers, setLocalSellers] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [showPaperComponent, setShowPaperComponent] = useState(false);
    const [paperContent, setPaperContent] = useState(null);

    const organizerColumns = [
        {id: "name", label: "Name"},
        {id: "email", label: "Email"},
        {id: "phoneNumber", label: "Telefonnummer"},
        {id: "localSellers", label: "POS-Verkäufer"},
        {id: "locations", label: "Standorte"},
        {id: "activeValidationLinks", label: "Aktive validation Links"},
        {id: "apiKey", label: "API Key"}
    ];
    const customerColumns = [
        {id: "name", label: "Name"},
        {id: "address", label: "Adresse"},
        {id: "email", label: "E-Mail"},
        {id: "tickets", label: "Gekaufte Tickets"},
        {id: "lastLogin", label: "Letzter Login"}
    ];
    const localSellerColumns = [
        {id: "name", label: "Name"},
        {id: "address", label: "Adresse"},
        {id: "phoneNumber", label: "Telefonnummer"},
        {id: "eventIds", label: "Bearbeitbare Events"},
    ];
    const eventColumns = [
        {id: "id", label: "ID"},
        {id: "name", label: "Name"},
        {id: "organizer", label: "Veranstalter"},
        {id: "picture", label: "Bild Link"},
    ];

    // GET data
    useEffect(() => {
        const fetchingData = async () => {
            try {
                const [organizerRes, customerRes, sellerRes, eventsRes] = await Promise.all([
                    api.get(`/admin/organizers`),
                    api.get(`/admin/customers`),
                    api.get(`/admin/localSellers`),
                    api.get(`/public/events`)
                ]);

                console.log("/admin/organizers: " + JSON.stringify(organizerRes.data));
                const formattedOrganizers = organizerRes.data.map(user => ({
                    id: user.organizer.id,
                    name: user.organizer.name,
                    email: user.email,
                    phoneNumber: user.organizer.phoneNumber,
                    localSellers: user.organizer.localSellers.map(seller => seller.name).join(", "),
                    locations: user.organizer.locations.map(location => location.address).join(", "),
                    activeValidationLinks: user.organizer.ticketValidators.length,
                    apiKey: user.organizer.mollie_API_KEY
                }));
                setOrganizers(formattedOrganizers);

                const formattedCustomers = customerRes.data.map(customer => ({
                    id: customer.id,
                    name: `${customer.firstName} ${customer.lastName}`,
                    address: customer.address,
                    email: customer.email,
                    lastLogin: customer.lastLogin ? new Date(customer.lastLogin).toLocaleString() : "Noch nie eingeloggt",
                    tickets: customer.tickets ? customer.tickets.length : 0
                }));
                setCustomers(formattedCustomers);

                //console.log("Seller data: " + JSON.stringify(sellerRes.data));
                const formattedLocalSellers = sellerRes.data.map(seller => ({
                    id: seller.id,
                    name: seller.name,
                    address: seller.address,
                    phoneNumber: seller.phoneNumber,
                    eventIds: seller.eventIds
                        ? seller.eventIds.map(eventId => {
                            const event = eventsRes.data.find(event => event.id === eventId);
                            return event ? event.name : "Unbekanntes Event";
                        }).join(", ")
                        : ["Keine Berechtigungen"],
                }))
                setLocalSellers(formattedLocalSellers);

                const formattedEvents = eventsRes.data.map(event => ({
                    id: event.id,
                    name: event.name,
                    organizer: event.organizer.name,
                    picture: event.picture,
                }))
                setEvents(formattedEvents);
            } catch (error) {
                console.error("Fehler beim Laden der Daten: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchingData();
    }, []);

    if (loading) {
        return (
            <Container className="flex justify-center items-center h-screen">
                <CircularProgress/>
            </Container>
        );
    }

    const handleOpenPaper = async (id, type, title) => {
        let detailedData;
        let detailedColumns;

        try {
            // DEBUG: console.log(`Type: ${type}, UserId: ${id}`);
            switch (type) {
                case "customer":
                    const customerPayments = await api.get(`/customer/payment/${id}`);
                    detailedData = customerPayments.data.map(payment => ({
                        paymentID: payment.paymentID,
                        amount: payment.amount,
                        event: `${payment.eventName} (ID: ${payment.eventID})`,
                        eventDate: payment.eventDate,
                        buyingDate: payment.buyingDate,
                        tickets: payment.tickets.map(ticket => ticket.id).join(", ")
                    }));
                    detailedColumns = [
                        {id: "paymentID", label: "Payment-ID"},
                        {id: "amount", label: "Summe (€)", format: (value) => `${value.toFixed(2)} €`},
                        {
                            id: "event",
                            label: "Event Name, ID",
                            format: (row) => row.event,
                        },
                        {
                            id: "eventDate",
                            label: "Eventdatum & Uhrzeit",
                            format: (value) => new Date(value).toLocaleString()
                        },
                        {
                            id: "buyingDate",
                            label: "Kaufdatum & Uhrzeit",
                            format: (value) => new Date(value).toLocaleString()
                        },
                        {
                            id: "tickets",
                            label: "Ticket-IDs",
                            format: (row) => row.tickets
                        }
                    ];
                    title = "Kunden Zahlungen";
                    break;
                case "organizer_accessOrgView":
                    const response = await api.get(`/admin/organizer/${id}`);
                    sessionStorage.setItem("user", JSON.stringify(response.data.organizer));

                    /* Button noch hinzufügen
                    <button className="absolute bottom-5 left-5 bg-blue-500 text-white py-2 px-4 rounded">
                        Zurück zu Admin
                    </button>*/

                    navigate("/organizer/overview", {
                        state: {
                            organizer: response.data.organizer,
                            message: "Zugriff erfolgreich",
                            severity: "success",
                        }
                    });
                    break;
                case "organizer_edit":
                    console.log("Edit organizer pressed!");
                    // TODO: edit von localsellerview in orgView abschauen
                    break;
                case "seller":
                    const sellerResponse = await api.get(`/admin/localSellers/${id}`);
                    detailedData = sellerResponse.data;
                    detailedColumns = [
                        {id: "name", label: "Name"},
                        {id: "address", label: "Adresse"},
                        {id: "phoneNumber", label: "Telefonnummer"},
                    ]; // Beispiel für Verkäufer-Spalten
                    break;
                default:
                    break;
            }

            if (detailedData && detailedColumns) {
                setPaperContent(
                    <TableComponent
                        data={detailedData}
                        columns={detailedColumns}
                        title={`Details zu ${title}`}
                        actions={() => null}
                    />
                );
                setShowPaperComponent(true);
            }
        } catch (error) {
            console.error("Fehler beim Laden der Details:", error);
        }
    };

    return (
        <div>
            <Header/>
            <div className="block md:hidden mt-[50%] text-xl">Bitte verwenden Sie die Desktop Ansicht</div>
            <div className="mt-[4rem] h-[95dvh] overflow-y-auto pb-10 hidden md:block">
                <CardContent>
                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}
                          indicatorColor="primary" textColor="primary">
                        <Tab label="Veranstalter"/>
                        <Tab label="POS-Verkäufer"/>
                        <Tab label="Kunden"/>
                        <Tab label="Alle Events"/>
                        <Tab label="Konfiguration"/>
                    </Tabs>
                    {activeTab === 0 && (
                        <Grid item>
                            <Card sx={{
                                borderRadius: '1rem',
                                marginTop: '2rem',
                                paddingY: '1rem',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                minHeight: 'calc(100dvh - 200px)'
                            }}>
                                <CardContent>
                                    <TableComponent
                                        data={organizers}
                                        columns={organizerColumns}
                                        actions={(user) => (
                                            <Action
                                                user={user.id}
                                                type="organizer"
                                                onOpenPaper={(type) => {
                                                    handleOpenPaper(user.id, type, "Veranstalter");
                                                }}
                                            />
                                        )}
                                        title="Veranstalter"
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {activeTab === 1 && (
                        <Grid item>
                            <Card sx={{
                                borderRadius: '1rem',
                                marginTop: '2rem',
                                paddingY: '1rem',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                minHeight: 'calc(100dvh - 200px)'
                            }}>
                                <CardContent>
                                    <TableComponent
                                        data={localSellers}
                                        columns={localSellerColumns}
                                        actions={(user) => (
                                            <Action
                                                user={user.id}
                                                type="seller"
                                                onOpenPaper={(type) => {
                                                    handleOpenPaper(user.id, type, "POS-Verkäufer");
                                                }}
                                            />
                                        )}
                                        title="POS-Verkäufer"
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {activeTab === 2 && (
                        <Grid item>
                            <Card sx={{
                                borderRadius: '1rem',
                                marginTop: '2rem',
                                paddingY: '1rem',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                minHeight: 'calc(100dvh - 200px)'
                            }}>
                                <CardContent>
                                    <TableComponent
                                        data={customers}
                                        columns={customerColumns}
                                        actions={(user) => (
                                            <Action
                                                user={user.id}
                                                type="customer"
                                                onOpenPaper={(type) => {
                                                    handleOpenPaper(user.id, type, "Kunden");
                                                }}
                                            />
                                        )}
                                        title="Kunden"
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {activeTab === 3 && (
                        <Grid item>
                            <Card sx={{
                                borderRadius: '1rem',
                                marginTop: '2rem',
                                paddingY: '1rem',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                minHeight: 'calc(100dvh - 200px)'
                            }}>
                                <CardContent>
                                    <TableComponent
                                        data={events}
                                        columns={eventColumns}
                                        actions={(user) => (
                                            <Action
                                                user={user.id}
                                                type="event"
                                                onOpenPaper={(type) => {
                                                    handleOpenPaper(user.id, type, "Events");
                                                }}
                                            />
                                        )}
                                        title="Alle Events"
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {activeTab === 4 && (
                        <div>Optional?</div>
                    )}

                    <PaperComponent open={showPaperComponent} onClose={() => setShowPaperComponent(false)}>
                        {paperContent}
                    </PaperComponent>

                </CardContent>
            </div>
        </div>
    );
};

export default AdminView;