import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    Divider,
    Grid,
    Box,
    TextField,
    Typography, IconButton
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useEventProvider} from "../../ServiceProviders/EventProvider";
import {usePopup} from "../../ServiceProviders/PopupProvider";
import {Delete, Save as SaveIcon} from "@mui/icons-material";
import {deleteSeatClass, setEventsSeatClasses} from "../../ServiceProviders/EventAPIService";

const EditEventComponent = () => {
    // CONSTANTS
    const {onEventChange, eventsData} = useEventProvider();
    const navigate = useNavigate();
    const routerLocation = useLocation();
    const [isModified, setIsModified] = useState(false);

    const [selectedEvent, setSelectedEvent] = useState(JSON.parse(sessionStorage.getItem('selectedEvent')) || '');

    // POPUP CONSTANTS
    const setPopup = usePopup();
    const showPopup = (message, severity, position) => {
        setPopup({open: true, message, severity, position});
    };

    // FORMS HANDLING
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        detailed_description: "",

        publishDate: "",
        publishTime: "",

        seatClasses: [],
    });

    const handleEventChange = (newValue, reason) => {
        if (reason === "clear") {
            setSelectedEvent('');
            sessionStorage.removeItem("selectedEvent");
            return;
        }
        setSelectedEvent(newValue);
        console.log("Chosen Event: " + JSON.stringify(newValue));
        if (newValue) {
            sessionStorage.setItem("selectedEvent", JSON.stringify(newValue));
            showPopup("Event erfolgreich geladen", "success", "center");
        }
    };

    const updateFormData = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        //const updatedEvent = {...selectedEvent, [name]: value};

        if (["name", "description", "detailed_description", "publishDate", "publishTime", "seatClassName", "seatClassPrice"].includes(name)) {
            setIsModified(true);
        }
    };

    // EVENT HANDLING
    useEffect(() => {
        try {
            if (routerLocation.state) {
                const {selectedEvent: selectedEventData, activeTab: tab} = routerLocation.state;

                if (selectedEventData) {
                    const matchedEvent = eventsData.find(event => event.id === selectedEventData.id);
                    setSelectedEvent(matchedEvent || null); // selectedEvent setzen
                    handleEventChange(matchedEvent, "selectOption"); // select Event in dropdown

                    if (matchedEvent) {
                        setFormData({
                            name: matchedEvent.name || "",
                            description: matchedEvent.description || "",
                            detailed_description: matchedEvent.detailed_description || "",
                            publishDate: selectedEvent.publishDate
                                ? new Date(selectedEvent.publishDate).toLocaleDateString('en-CA') // 'en-CA' gibt das Datum im Format 'YYYY-MM-DD' zurück
                                : "",
                            publishTime: selectedEvent.publishDate
                                ? new Date(selectedEvent.publishDate).toLocaleTimeString('en-AT', {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }) // 'HH:MM' Format
                                : ""
                        });
                        sessionStorage.setItem('selectedEvent', JSON.stringify(matchedEvent));
                        sessionStorage.setItem('activeTab', tab || 0);
                    }
                } else {
                    console.error("selectedEventData is undefined");
                }
            } else {
                const savedSelectedEvent = sessionStorage.getItem('selectedEvent');
                if (savedSelectedEvent) {
                    const parsedEvent = JSON.parse(savedSelectedEvent);
                    const matchedEvent = eventsData.find(event => event.id === parsedEvent.id);
                    setSelectedEvent(matchedEvent || null);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [eventsData, routerLocation.state]);

    useEffect(() => {
        if (selectedEvent) {
            setFormData({
                name: selectedEvent.name || "",
                description: selectedEvent.description || "",
                detailed_description: selectedEvent.detailed_description || "",
                publishDate: selectedEvent.publishDate
                    ? new Date(selectedEvent.publishDate).toLocaleDateString('en-CA') // 'en-CA' gibt das Datum im Format 'YYYY-MM-DD' zurück
                    : "",
                publishTime: selectedEvent.publishDate
                    ? new Date(selectedEvent.publishDate).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })  // 'HH:MM' Format
                    : "",
                seatClasses: selectedEvent.seatClasses
                    ? selectedEvent.seatClasses.map(seatClass => ({
                        id: seatClass.id,
                        name: seatClass.name,
                        price: seatClass.price
                    }))
                    : []
            });
        }
    }, [selectedEvent]);

    // EDIT SEAT CLASS CONSTANTS
    const updateSeatClass = (id, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            seatClasses: prevData.seatClasses.map((seatClass) =>
                seatClass.id === id ? {...seatClass, [field]: value} : seatClass
            )
        }));
        setIsModified(true);
    };

    const addSeatClass = () => {
        setFormData((prevData) => ({
            ...prevData,
            seatClasses: [...prevData.seatClasses, {id: 0, name: "", price: 0}]
        }));
    };

    const removeSeatClass = (id) => {
        const seatClassId = formData.seatClasses.find(seatClass => seatClass.id === id)?.id;  // Find ID, nicht Index

        setFormData((prevData) => {
            if (prevData.seatClasses.length <= 1) {
                showPopup("Es muss mindestens eine Sitzklasse geben!", "error", "center");
                return prevData;
            }
            return {
                ...prevData,
                seatClasses: prevData.seatClasses.filter((seatClass) => seatClass.id !== id),  // Entferne anhand der ID
            };
        });

        if (seatClassId) {
            deleteSeatClass(seatClassId, setPopup);  // API call nur, wenn ID existiert
        } else {
            console.warn("Warnung: Keine ID gefunden für Sitzklasse!");
        }
    };

    const handleEventSave = async () => {
        if (selectedEvent) {
            const changedEvent = {
                name: formData.name,
                description: formData.description,
                detailed_description: formData.detailed_description,
                publishDate: new Date(`${formData.publishDate}T${formData.publishTime}:00Z`),
            };
            onEventChange(changedEvent, selectedEvent.id);  // Sync event Info ohne seatClasses

            const changedSeatClasses = formData.seatClasses.map(sc => ({
                id: sc.id || 0,  // Falls keine ID existiert, standardmäßig 0 setzen
                name: sc.name,
                price: sc.price
            }));
            const savedSeatClasses = await setEventsSeatClasses(selectedEvent.id, changedSeatClasses);  // Sync seatClasses

            // Frontend direkt aktualisieren
            setFormData((prevData) => ({
                ...prevData,
                seatClasses: savedSeatClasses,
            }));

            const updatedEvent = {
                id: selectedEvent.id,
                ...changedEvent,
                seatClasses: savedSeatClasses,
            };

            sessionStorage.setItem("selectedEvent", JSON.stringify(updatedEvent));
            setSelectedEvent(updatedEvent);

            showPopup("Erfolgreich gespeichert!", "success", "center");
            setIsModified(false);
        } else {
            showPopup("Fehler beim Speichern!", "error", "center");
            console.error("Fehler beim Speichern!");
        }
    };

    return (
        <div>
            {/* Event bearbeiten */}
            <Grid item xs={12} sm={12} style={{marginBottom: '3%'}}
                  sx={{maxHeight: 'calc(100vh - 140px)', overflowY: 'auto'}}>
                <Card sx={{
                    borderRadius: '1rem',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    padding: 2,
                    marginBottom: '3%'
                }}>
                    <Grid container spacing={2} alignItems="center">

                        {/* Titel */}
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom
                                        style={{textAlign: 'center', flexGrow: 1, marginTop: '1%'}}>
                                Event bearbeiten
                            </Typography>
                        </Grid>

                        {/* Dropdown & Save button */}
                        <Grid item xs={12} sm={12} fullwidth="true">
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Autocomplete
                                    options={eventsData}
                                    getOptionLabel={(option) => option.name || ''}
                                    onChange={(event,
                                               newValue,
                                               reason) => handleEventChange(newValue, reason)}
                                    value={selectedEvent || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Event auswählen"
                                            fullWidth
                                            style={{width: '250%'}}
                                        />
                                    )}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEventSave}
                                    disabled={!isModified}
                                    style={{marginLeft: 'auto', fontSize: '16px'}}
                                >
                                    <SaveIcon fontSize="small" className="mr-2"/>
                                    Speichern
                                </Button>
                            </div>
                        </Grid>

                        {/* Sidenote for user */}
                        <Typography variant="body1" color="textSecondary" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '1.5%',
                            marginBottom: '3%'
                        }}>
                            Hinweis: Diese Einstellungen sind für alle Termine dieses Events gültig.
                        </Typography>

                        {/* Main Body - Input fields */}
                        <>
                            {selectedEvent ? (
                                <>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        style={{
                                            marginBottom: '10px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* Linke Spalte */}
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            container
                                            spacing={2}
                                            gap={2}
                                            direction="column"
                                            style={{
                                                justifyContent: 'space-between',
                                                height: '100%',
                                            }}
                                        >
                                            {/* Name des Events */}
                                            <Grid item>
                                                <TextField
                                                    label="Name des Events"
                                                    placeholder="Event Titel festlegen"
                                                    fullWidth
                                                    name="name"
                                                    value={formData?.name || ''}
                                                    onChange={updateFormData}
                                                />
                                            </Grid>
                                            {/* Kurzbeschreibung */}
                                            <Grid item>
                                                <TextField
                                                    label="Kurzbeschreibung"
                                                    placeholder="Kurze Beschreibung eingeben"
                                                    fullWidth
                                                    rows={2}
                                                    multiline
                                                    name="description"
                                                    value={formData?.description || ''}
                                                    onChange={updateFormData}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* Rechte Spalte */}
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label="Langbeschreibung"
                                                placeholder="Detaillierte Beschreibung eingeben"
                                                fullWidth
                                                multiline
                                                rows={6}
                                                name="detailed_description"
                                                value={formData?.detailed_description || ''}
                                                onChange={updateFormData}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Publish date & time */}
                                    <Grid item xs={12}>
                                        <Divider sx={{marginY: 2}}/>
                                        <Typography variant="h6" gutterBottom>
                                            Für Kunden sichtbar ab
                                        </Typography>
                                        <Box display="flex" gap={2}>
                                            <TextField
                                                label="Datum"
                                                type="date"
                                                name="publishDate"
                                                value={formData?.publishDate}
                                                onChange={updateFormData}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <TextField
                                                name="publishTime"
                                                label="Uhrzeit"
                                                type="time"
                                                value={formData?.publishTime}
                                                onChange={updateFormData}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Grid>

                                    {/* Sitzklassen verwalten */}
                                    <Grid item xs={12}>
                                        <Divider sx={{marginY: 2}}/>
                                        <Typography variant="h6" gutterBottom>
                                            Sitzklassen verwalten
                                        </Typography>

                                        {formData.seatClasses?.length === 0 ? (
                                            <Typography variant="body1" color="textSecondary">
                                                Sie <strong>müssen</strong> noch mindestens eine Sitzklasse anlegen!
                                            </Typography>
                                        ) : (
                                            formData.seatClasses.map((seatClass) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    key={seatClass.id}
                                                    style={{marginTop: '15px'}}
                                                >
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Name der Sitzklasse"
                                                            fullWidth
                                                            value={seatClass.name}
                                                            onChange={(e) => updateSeatClass(seatClass.id, "name", e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            label="Preis der Sitzklasse (€)"
                                                            type="number"
                                                            fullWidth
                                                            value={seatClass.price}
                                                            onChange={(e) => updateSeatClass(seatClass.id, "price", e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={1}>
                                                        <IconButton onClick={() => removeSeatClass(seatClass.id)}>
                                                            <Delete color="error"/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{marginTop: 2}}
                                            onClick={addSeatClass}
                                            style={{marginTop: '2%'}}
                                        >
                                            Neue Sitzklasse hinzufügen
                                        </Button>
                                    </Grid>

                                    {/* Saalplan bearbeiten */}
                                    <Grid item xs={12} style={{marginBottom: '2%'}}>
                                        <Divider sx={{marginY: 2, marginBottom: '3%'}}/>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => navigate('/organizer/seatingPlanEditor', {
                                                state: {eventsData: eventsData, selectedEvent: selectedEvent},
                                            })}
                                        >
                                            Saalplan Editor öffnen
                                        </Button>
                                        <Typography variant="body1" color="textSecondary" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '5px',
                                        }}>
                                            Hinweis: Der hier erstellte Saalplan gilt für alle Termine des Events.
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                // Alternativer Text
                                <Grid item xs={12}>
                                    <Divider sx={{marginY: 2}}/>
                                    <Typography variant="body1" color="textSecondary">
                                        Bitte wählen Sie das Event aus, welches Sie bearbeiten möchten!
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    </Grid>
                </Card>
            </Grid>
        </div>
    )
        ;
}

export default EditEventComponent;
