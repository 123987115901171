import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from "../../General/HeaderComponents/Header";

const EventDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        id,
        name,
        detailedDescription,
        picture,
        organizer,
        eventDates,
        eventLocation
    } = location.state || {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const filteredEventDates = Array.isArray(eventDates)
        ? eventDates.filter(date => new Date(date.date) >= new Date())
        : [];
    return (
        <div className="w-screen mx-auto mt-0">
            <div className="w-full mx-0">
                <Header/>
            </div>
            <div className="px-4 sm:px-6 md:px-10 lg:px-[10%] pt-[5%]
                max-h-[calc(100dvh)] h-[calc(100dvh)] overflow-y-auto"> {/* die Zeile für scrollen */}

                <div className="flex flex-col lg:flex-row justify-between items-center gap-5 mt-7">
                    {/* Event data */}
                    <div className="flex-1 text-center lg:text-left mb-3 lg:w-[50%] xl:w-[40%] max-w-[600px] md:max-w-[80%] break-words">
                        <h1 className="font-bold text-3xl md:text-2xl sm:text-xl mb-2 mt-8 sm:mt-0">
                            {name || 'Unbekanntes Event'}
                        </h1>
                        <p className="text-xl md:text-lg sm:text-base mb-2 break-all">
                            {detailedDescription || 'Keine detaillierte Beschreibung verfügbar.'}
                        </p>
                        <p className="text-xl md:text-lg sm:text-base">
                            Organisiert von: {organizer || 'Unbekannt'}
                        </p>
                    </div>

                    {/* Bild mit Blur & Farbverlauf */}
                    <div className="relative w-full sm:w-[80%] md:w-[60%] lg:w-[40%] h-[35vh] md:h-[30vh] sm:h-[25vh] flex justify-center items-center ml-0 lg:ml-[2%]">
                        {/* Hintergrund mit Blur */}
                        <div
                            className="absolute inset-0 bg-cover bg-center blur-xl scale-105"
                            style={{backgroundImage: `url(${picture})`}}
                        />

                        {/* Farbverlauf für den sanften Übergang */}
                        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/40"/>

                        {/* img selbst */}
                        <img
                            src={picture}
                            alt="Event Bild"
                            className="relative z-10 max-w-full h-auto rounded-lg"
                        />
                    </div>
                </div>

                <div className="mt-7">
                    <h2 className="text-2xl md:text-xl sm:text-lg mb-5">Termine</h2>
                    <div className="flex gap-7 flex-col">
                        {filteredEventDates.length > 0 ? (
                            filteredEventDates.map((date, index) => (
                                <div key={index}
                                     className="bg-white hover:bg-white-300 min-h-[12vh] p-5 flex flex-col justify-center items-center text-center rounded-xl shadow-md cursor-pointer"
                                     onClick={() => {
                                         if (date?.seatingPlan === null) {
                                             alert("Für dieses Event ist noch kein Sitzplan angelegt!");
                                         } else if (new Date(date.date) <= new Date()) {
                                             alert("Termin ist bereits vergangen");
                                             navigate(0);
                                         } else {
                                             navigate('/seatingPlan', {state: {date: date, eventid: id}});
                                         }
                                     }}
                                >
                                    <p className="text-lg md:text-base sm:text-sm mb-2">Datum: {new Date(date.date).toLocaleDateString() || 'Unbekannt'}</p>
                                    <p className="text-lg md:text-base sm:text-sm mb-2">Uhrzeit: {new Date(date.date).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }) || 'Unbekannt'}</p>
                                    <p className="text-lg md:text-base sm:text-sm mb-2">Standort: {date?.location?.description || 'Unbekannt'}</p>
                                </div>
                            ))
                        ) : (
                            <p>Keine Termine verfügbar</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventDetails;