import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from "../../General/HeaderComponents/Header";
import urlConfig from '../../../urlConfig';
import SeatingPlan from "./components/SeatingPlan";
import api from "../../../api";
import {useMediaQuery} from "react-responsive";

const SeatMap = ({date, eventid, isLocalSeller}) => {
    const isMd = useMediaQuery({minWidth: 768});
    const navigate = useNavigate();
    const location = useLocation();
    const [seatMapData, setSeatMapData] = useState(null);
    const [selectedSeats, setSelectedSeats] = useState(location.state?.selectedSeats || []);

    const containerRef = useRef(null);
    const [svgSize, setSvgSize] = useState({width: 0, height: 0});
    const [seatScale, setSeatScale] = useState(1);

    const seatSize = useMemo(() => svgSize.width * 0.03 * seatScale, [svgSize.width, seatScale]);
    const minSpacing = useMemo(() => seatSize / 3, [seatSize]);

    const finalDate = date || location.state?.date;
    const finalEventId = eventid || location.state?.eventid;

    useEffect(() => {
        if (finalDate?.id) {
            fetchSeatMapData(finalDate.id);
        }
    }, [finalDate]);

    const fetchSeatMapData = (i) => {
        api.get(`/public/date/${i}`).then((response) => {
            setSeatMapData(response.data);
            setSeatScale(response.data.seatingPlan.seatScale);
        }).catch(error => console.error('Error fetching seating plans:', error));
    };

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                let height = 0;
                let width = 0;

                if (isMd) {
                    height = containerRef.current.offsetHeight;
                    width = height / (3 / 4)
                } else {
                    width = containerRef.current.offsetWidth;
                    height = width / (4 / 3)
                }


                /*
                if (containerRef.current.offsetWidth / containerRef.current.offsetHeight > 1) {
                width = containerRef.current.offsetWidth;
                height = width / (4 / 3);
                if (height > containerRef.current.offsetHeight) {
                    height = containerRef.current.offsetHeight;
                    width = height / (3 / 4)
                }
                 } else {
                     height = containerRef.current.offsetHeight;
                     width = height / (4 / 3);
                     if(width>containerRef.current.offsetWidth) {
                         width = containerRef.current.offsetWidth;
                         height=width/(3/4)
                     }
                 }

                 */
                setSvgSize({width, height});
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const localSellerBooksTickets = () => {
        if (!selectedSeats.length) return;
        const selectedSeatsIds = selectedSeats.map(seat => seat.id);
        console.log("selectedSeatsIds:", selectedSeatsIds);
        api.post(`/localSeller/book`, selectedSeatsIds).then((response) => {
            console.log("response: " + response);
        });
        isLocalSeller = false;
    };

    const buySelectedSeats = () => {
        if (!selectedSeats.length) return;
        navigate("/orderoverview", {
            state: {
                selectedSeats,
                date: finalDate,
                eventid: finalEventId,
                totalAmount: selectedSeats.reduce((total, seat) => total + seat.price, 0)
            }
        });
    };

    const toggleSeat = (seat, isAvailable, rowNumber, group) => {
        console.log(group);
        if (isAvailable) {
            setSelectedSeats((prevSelected) => {
                const seatIndex = prevSelected.findIndex(s => s.id === seat.id);
                if (seatIndex === -1) {
                    return [...prevSelected, {...seat, rowNumber, group, price: group.seatClass.price}];
                } else {
                    return prevSelected.filter(s => s.id !== seat.id);
                }
            });
        }
    };

    const resetPurchasedSeats = () => {
        finalDate?.seatingPlan?.seatingGroups.forEach(group => {
            group.rows.forEach(row => {
                row.seats.forEach(seat => {
                    seat.status = "AVAILABLE";
                });
            });
        });

        fetch(`${urlConfig.apiBaseUrl}/public/date/${finalDate.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(finalDate.seatingPlan)
        })
            .then(response => {
                if (response.ok) {
                    console.log(`resetPurchasedSeats succeeded with status: ${response.status}`);
                } else {
                    console.error(`resetPurchasedSeats failed with status: ${response.status}, ${response.statusText}`);
                }
            })
            .catch(error => {
                console.error('Error during fetching resetPurchasedSeats:', error);
            });
    };

    const seatingGroups = seatMapData?.seatingPlan?.seatingGroups || [];
    const stages = seatMapData?.seatingPlan?.stages || [];

    return (
        <div>
            <Header/>
            <div className="flex flex-col md:flex-row justify-center mt-16 ml-2 mr-2 md:mt-20 md:ml-14 md:mr-0 md:gap-4">
                <div ref={containerRef} className="w-[100%] md:w-auto md:border-r border-gray-300 h-auto md:h-[80dvh]">
                    {svgSize.width > 0 && svgSize.height > 0 && (
                        <SeatingPlan
                            seatingGroups={seatingGroups}
                            seatSize={seatSize}
                            minSpacing={minSpacing}
                            svgWidth={svgSize.width}
                            svgHeight={svgSize.height}
                            toggleSeat={toggleSeat}
                            selectedSeats={selectedSeats}
                            stages={stages}
                        />
                    )}
                </div>

                <div className="w-[100%] md:w-[35%] flex flex-col justify-between md:mt-6 items-center">
                    <h2 id="selectedSeatsTitle" className="w-full text-xl">Ihre ausgewählten Sitzplätze:</h2>
                    <ul className="mb-5 max-h-[calc(78vh-300px)] md:max-h-[calc(100vh-250px)] h-[calc(100vh-250px)] overflow-y-auto">
                        {selectedSeats.map((seat) => (
                            <li key={seat.id} className="border border-gray-200 bg-white-200 p-4 my-2 shadow-md rounded-md">
                            <div className="flex items-center space-x-4 h-5 md:h-10">
                                    <span className="px-2 py-1"><strong>Reihe:</strong> {seat.rowNumber}</span>
                                    <span className="px-2 py-1"><strong>Sitz:</strong> {seat.seatNumber}</span>
                                    <span className="px-2 py-1"><strong>Gruppe:</strong> {seat.group.name}</span>
                                    <span className="px-2 py-1"><strong>Preis:</strong> {seat.price}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {isLocalSeller ? (
                        <button className="mt-auto bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
                                onClick={localSellerBooksTickets}>
                            {`${selectedSeats.length} Tickets für € ${selectedSeats.reduce((total, seat) => total + seat.price, 0)} reservieren`}
                        </button>
                    ) : (
                        <button className="mt-auto bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
                                onClick={buySelectedSeats}>
                            {`${selectedSeats.length} Tickets für € ${selectedSeats.reduce((total, seat) => total + seat.price, 0)}`}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SeatMap;
