import React from "react";

const Stage = ({stageInfo, svgWidth, svgHeight}) => {
    const stageWidth = stageInfo.relativeSizeX / 100 * svgWidth;
    const stageHeight = stageInfo.relativeSizeY / 100 * svgHeight;

    const stageCenterX = stageInfo.relativeCenterX * svgWidth;
    const stageCenterY = stageInfo.relativeCenterY * svgHeight;

    const text = stageInfo.text;

    const stageX = stageCenterX - stageWidth / 2;
    const stageY = stageCenterY - stageHeight / 2;


    return (
        <g>
            <rect
                x={stageX}
                y={stageY}
                width={stageWidth}
                height={stageHeight}
                fill="#a8a8a8"
                opacity={stageInfo.stageOpacity / 100}
            />
            <text
                x={stageCenterX}
                y={stageCenterY}
                fill="white"
                fontSize={stageWidth * stageInfo.fontSize}
                textAnchor="middle"
                dominantBaseline="middle" // Ensures the text is vertically centered
            >
                {text}
            </text>
        </g>
    );
};

export default Stage;
