import React, {useState, useEffect} from "react";
import {useEventProvider} from "../ServiceProviders/EventProvider";
import ProgressBar from "./ProgressBar";

const Calendar = () => {
    const eventProvider = useEventProvider();
    const eventsData = eventProvider?.eventsData || [];

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedEvents, setSelectedEvents] = useState([]);

    useEffect(() => {
        if (eventsData.length > 0) {
            setSelectedEvents(
                eventsData.filter((event) =>
                    event.eventDates?.some((eventDateObj) => {
                        const eventDate = new Date(eventDateObj.date);
                        return !isNaN(eventDate.getTime()) &&
                            eventDate.toLocaleDateString("de-AT") === selectedDate.toLocaleDateString("de-AT");
                    })
                )
            );
        }
    }, [selectedDate, eventsData]);

    if (!eventProvider || eventProvider.eventsData === undefined) {
        return <div>Lade Events...</div>;
    }


    const daysOfWeek = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

    const getDaysInMonth = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        let emptyDays = firstDay === 0 ? 6 : firstDay - 1;

        let daysArray = Array.from({length: emptyDays}, () => null);
        daysArray = daysArray.concat([...Array(daysInMonth).keys()].map((i) => i + 1));

        return daysArray;
    };

    const hasEventOnDate = (day) => {
        if (!eventsData || !Array.isArray(eventsData)) return false;
        const dateToCheck = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).toDateString();
        return eventsData.some((event) =>
            event.eventDates?.some((eventDateObj) => new Date(eventDateObj.date).toDateString() === dateToCheck)
        );
    };

    const handlePrevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const handleDateClick = (day) => {
        if (!day) return;
        setSelectedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
    };

    return (
        <div className="flex flex-col items-center p-4 shadow-lg bg-white rounded-2xl">
            {/* Pfeile */}
            <div className="flex justify-between w-full px-4 py-2">
                <button onClick={handlePrevMonth} className="text-gray-700 hover:text-gray-400">◀</button>
                <span className="text-lg font-bold">
                    {currentDate.toLocaleString("default", {month: "long", year: "numeric"})}
                </span>
                <button onClick={handleNextMonth} className="text-gray-700 hover:text-gray-400">▶</button>
            </div>

            {/* Kalender */}
            <table className="w-full mt-4">
                <thead>
                <tr>
                    {daysOfWeek.map((day) => (
                        <th key={day} className="text-center font-medium text-gray-600">{day}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {(() => {
                    let days = getDaysInMonth(currentDate);
                    let weeks = [];
                    for (let i = 0; i < days.length; i += 7) {
                        weeks.push(days.slice(i, i + 7));
                    }
                    return weeks.map((week, i) => (
                        <tr key={i}>
                            {week.map((day, j) => {
                                if (day === null) return <td key={j} className="h-12 w-12"></td>;

                                const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
                                const isToday = new Date().toDateString() === currentDay.toDateString();
                                const isSelected = selectedDate.toDateString() === currentDay.toDateString();
                                const hasEvent = hasEventOnDate(day);

                                return (
                                    <td key={j} className="h-12 w-12 text-center cursor-pointer"
                                        onClick={() => handleDateClick(day)}>
                                        <div className={`rounded-full h-8 w-8 flex items-center justify-center 
                                            ${isSelected ? "bg-blue-600 text-white"
                                            : isToday ? "bg-blue-300 text-white"
                                                : hasEvent ? "bg-green-200 text-black"
                                                    : "text-gray-700"}`}>
                                            {day}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    ));
                })()}
                </tbody>
            </table>

            {selectedDate && (
                <div className="mt-4 w-full p-4 border-t">
                    <h3 className="text-lg font-semibold">Events am {selectedDate.toLocaleDateString()}</h3>
                    {selectedEvents.length > 0 ? (
                        <ul className="mt-2">
                            {selectedEvents.map((event, index) => {
                                const matchingDate = event.eventDates.find((eventDateObj) => {
                                    const eventDate = new Date(eventDateObj.date);
                                    return eventDate.toLocaleDateString("de-AT") === selectedDate.toLocaleDateString("de-AT");
                                });

                                const eventTime = matchingDate
                                    ? new Date(matchingDate.date).toLocaleTimeString("de-AT", {
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    })
                                    : "Zeit unbekannt";

                                return (
                                    <li key={index} className="p-2 bg-gray-100 rounded mt-1">
                                        <div className="flex items-center justify-center gap-5">
                                            <span>{event.name} - {eventTime} Uhr</span>
                                            <span className="w-[20%]"><ProgressBar eventDateId={matchingDate?.id}/></span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p className="text-gray-500 mt-2">Keine Events an diesem Tag</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Calendar;