import React, {useState, useEffect} from "react";
import {
    ContentCopy as ContentCopyIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    Save as SaveIcon
} from "@mui/icons-material";
import {usePopup} from "../../ServiceProviders/PopupProvider";
import {useEventProvider} from "../../ServiceProviders/EventProvider";
import urlConfig from "../../../../urlConfig";
import api from "../../../../api";
import {Select, MenuItem, Checkbox} from "@mui/material";

const TicketValidatorComponent = () => {
    const [ticketValidators, setTicketValidators] = useState([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false); // Speichern-Button-Status
    const setPopup = usePopup();
    const events = useEventProvider().eventsData;
    const organizer = JSON.parse(sessionStorage.getItem("user"));

    useEffect(() => {
        if (events) {
            fetchAllTicketValidators();
        }
    }, [events]);

    const fetchAllTicketValidators = async () => {
        api.get(`/organizer/ticketValidators/${organizer.id}`).then((response) => {
            const newTicketValidators = response.data.map(row => {
                return {
                    id: row.id,
                    url: row.url,
                    notes: row.notes,
                    eventIds: row.eventIds.length === 0 ? [] : row.eventIds.map(event => event),
                }
            })
            setTicketValidators(newTicketValidators);
        }).catch((error) => {
            console.error('Fehler beim Abrufen der Ticket Validators:', error)
        });
    };

    const showPopup = (message, severity, position) => {
        setPopup({open: true, message, severity, position});
    };

    const addRow = async () => {
        try {
            await api.post(`/organizer/ticketValidator/${organizer.id}`, {
                id: 0,
                url: generateRandomURL(),
                eventIds: [],
                notes: ""
            });
            fetchAllTicketValidators();
        } catch (error) {
            console.error("Fehler beim Hinzufügen eines Ticket Validators:", error);
        }
    };

    const generateRandomURL = () => Math.random().toString(36).substring(2, 22);

    const handleChange = (index, field, value) => {
        const updatedRows = [...ticketValidators];

        if (field === "eventIds") {
            updatedRows[index][field] = value.includes("all") ? events.map(e => e.id) : value.map(Number);
        } else {
            updatedRows[index][field] = value;
        }

        setTicketValidators(updatedRows);
        setIsSaveEnabled(true);
    };

    const handleCopy = (link) => {
        navigator.clipboard.writeText(link);
        showPopup("Link kopiert!", "success", "center");
    };

    const handleDelete = async (row) => {
        console.log(row);
        try {
            await api.delete(`/organizer/ticketValidator/${row.id}`);
            showPopup("Erfolgreich aktualisiert", "success", "center");
            setTicketValidators(ticketValidators.filter((item) => item.id !== row.id));
        } catch (error) {
            console.error("Fehler beim Löschen des Ticket Validators:", error);
            showPopup("Fehler beim Löschen!", "error", "center");
        }
    };

    const handleSave = async () => {
        try {
            const requestBody = ticketValidators.map(row => ({
                id: row.id,
                url: row.url,
                eventIds: row.eventIds.length === 0 ? [] : row.eventIds,
                notes: row.notes
            }));

            await api.put(`/organizer/ticketValidators/${organizer.id}`, requestBody);
            showPopup("Erfolgreich aktualisiert!", "success", "center");
            setIsSaveEnabled(false);
            fetchAllTicketValidators();
        } catch (error) {
            console.error("Fehler beim Speichern der Ticket Validators:", error);
            showPopup("Fehler beim Speichern!", "error", "center");
        }
    };

    if (!events) return "Lade Events...";

    return (
        <div className="flex justify-center">
            <div className="relative bg-white shadow-lg rounded-2xl p-6 w-full mx-4">
                {/* Speicher-Button oben rechts */}
                <button
                    onClick={handleSave}
                    disabled={!isSaveEnabled}
                    className={`absolute top-4 right-4 px-4 py-2 rounded-lg shadow-md flex items-center gap-2 ${
                        isSaveEnabled ? "bg-blue-500 hover:bg-blue-600 text-white" : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}
                >
                    <SaveIcon fontSize="small"/>
                    Speichern
                </button>

                <h2 className="text-2xl font-semibold mb-4">Link zur Ticket-Kontrolle anlegen</h2>

                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border rounded-lg table-fixed">
                        <thead className="bg-gray-100">
                        <tr>
                            <th className="text-left px-4 py-2 w-[35%]">Link</th>
                            <th className="text-left px-4 py-2 w-[25%]">Prüfbare Events</th>
                            <th className="text-left px-4 py-2 w-[35%]">Notizen</th>
                            <th className="text-left px-4 py-2 w-[5%]">Aktionen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ticketValidators.map((row, index) => (
                            <tr key={index} className="border-t items-center">
                                {/* Link */}
                                <td className="px-4 py-2 w-[35%]">
                                    <input
                                        type="text"
                                        className="w-full border rounded p-2 bg-gray-100 cursor-pointer"
                                        readOnly
                                        value={`${urlConfig.reactBaseUrl}/${row.url}`}
                                        onClick={() => handleCopy(`${urlConfig.reactBaseUrl}/${row.url}`)}
                                    />
                                </td>
                                {/* Event select */}
                                <td className="px-4 py-2 w-[25%]">
                                    <Select
                                        multiple
                                        value={row.eventIds ?? []}
                                        onChange={(event) => handleChange(index, "eventIds", event.target.value)}
                                        renderValue={(selected) => selected.map(id => events.find(e => e.id === id)?.name).join(', ')}
                                        className="w-full border rounded p-2"
                                    >
                                        <MenuItem value="all">Alle Events</MenuItem>
                                        {events.map((event) => (
                                            <MenuItem key={event.id} value={event.id}>
                                                <Checkbox checked={row.eventIds.includes(event.id)}/>
                                                {event.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </td>
                                {/* Notes */}
                                <td className="pl-4 py-2 w-[35%]">
                                        <textarea
                                            value={row.notes}
                                            onChange={(e) => handleChange(index, "notes", e.target.value)}
                                            placeholder="Notizen..."
                                            rows="2"
                                            className="w-full border rounded p-2 resize-none"
                                        />
                                </td>
                                {/* Copy & Delete */}
                                <td className="pl-14 w-[5%] flex justify-center items-center h-20">
                                    <div className="flex flex-row gap-2 items-center">
                                        <button
                                            onClick={() => handleCopy(`${urlConfig.reactBaseUrl}/${row.url}`)}
                                            className="text-gray-600 hover:text-gray-900"
                                        >
                                            <ContentCopyIcon/>
                                        </button>
                                        <button
                                            onClick={() => handleDelete(row)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <DeleteIcon/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {/* New Link */}
                <button onClick={addRow}
                        className="mt-4 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg shadow-md flex items-center justify-center mx-auto gap-2">
                    Neue Zeile hinzufügen
                </button>
            </div>
        </div>
    );
};

export default TicketValidatorComponent;
