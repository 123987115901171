import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../HeaderComponents/Header";
import Slideshow from "./Slideshow";
import EventList from "./EventList";
import BigPopup from "../PopupComponents/BigPopup";

const MainPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const message = params.get('message');

        if (message ) {
            setMessage(message);
            setShowPopup(true);
        }
    }, [location]);

    const handleClosePopup = () => {
        setShowPopup(false);
        navigate("/");
    };

    return (
        <>
            <Header />
            <div className={`relative ${showPopup ? "blur-sm brightness-50" : ""}`}>
                <div className="max-h-[calc(100dvh)] h-[calc(100dvh)] overflow-y-auto">
                    <div className="lg:mt-[7%] mt-[18%] mx-[4%] lg:mx-auto">
                        <Slideshow />
                    </div>
                    <EventList />
                </div>
            </div>

            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <BigPopup
                        message={message}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </>
    );
};

export default MainPage;