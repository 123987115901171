import React, {useState} from 'react';
import api from "../../../api";
import Header from "../HeaderComponents/Header";

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const url = window.location.pathname.substring(1);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(url)
        try {
            const response = await api.post('/public/user/resetPassword', { url: url, password: password }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log('Password reset successful:', response);
        } catch (error) {
            console.error('Error resetting password:', error);
            setError('Es gab ein Problem beim Zurücksetzen des Passworts. Bitte versuche es erneut.');
        }
    }

    return (
        <div className="flex flex-col bg-white-200 min-h-screen overflow-hidden">
            <Header/>
            <div className="flex justify-center items-center h-screen">
                <div className="w-full max-w-md p-5">
                    <div className="bg-white p-10 shadow-xl rounded-xl text-center">
                        <h2 className="text-4xl font-bold mb-5 mt-7 ml-2">Passwort zurücksetzen</h2>
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <input
                                type="password"
                                placeholder="Neues Passwort"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            {error && <p className="text-red-500 mb-4">{error}</p>}
                            <button
                                type="submit"
                                className="w-full p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                            >
                                Passwort zurücksetzen
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
