import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import urlConfig from "../../../../urlConfig";
import {useEventProvider} from "../../ServiceProviders/EventProvider";
import {addEvent} from "../../ServiceProviders/EventAPIService";
import {usePopup} from "../../ServiceProviders/PopupProvider";
import {useNavigate} from "react-router-dom";
import {DeleteIcon} from "lucide-react";
import {Delete} from "@mui/icons-material";

const AddEventComponent = () => {
    // CONSTANTS
    const navigate = useNavigate();
    const setPopup = usePopup();
    const showPopup = (message, severity, position) => {
        setPopup({open: true, message, severity, position});
    };
    const organizer = JSON.parse(sessionStorage.getItem('user'));

    const {onEventChange} = useEventProvider();
    const {eventsData, onNewEvent, extractAnEventsImageData} = useEventProvider();
    const [newEvent, setNewEvent] = useState({
        name: '',
        date: '',
        description: '',
        detailedDescription: '',
        location: {id: '', description: ''},
        publishDate: '',  // Intern als publishDate
        publishTime: ''   // Neues Feld für die Uhrzeit
    });
    const [inputFields, setinputFields] = useState([{date: '', time: '', location: ''}]);
    const [formErrors, setFormErrors] = useState({
        name: false,
        description: false,
        detailedDescription: false,
        date: false,
        time: false,
        location: false,
        publishDate: false,  // Fehlerbehandlung für das Veröffentlichkeitsdatum (intern als publishDate)
        publishTime: false   // Fehlerbehandlung für die Veröffentlichungsuhrzeit
    });
    const [organizersLocations, setOrganizersLocations] = useState(JSON.parse(sessionStorage.getItem('organizersLocations')));

    const changeTab = () => {
        navigate('/organizer/events', {
            state: {activeTab: 0}
        });
    }

    const handleDateTimeChange = (index, field, value) => {
        const updatedRows = [...inputFields];
        updatedRows[index][field] = value;
        setinputFields(updatedRows);

        if (field === "location") {
            updateEventLocation(index, value);
        }
    };

    const updateEventLocation = (index, locationDescription) => {
        const updatedEvents = [...eventsData];

        const eventIndex = index;
        const eventDate = updatedEvents[eventIndex]?.eventDates?.[0];

        if (eventDate) {
            eventDate.location.description = locationDescription;
            onNewEvent(updatedEvents);
            onEventChange(updatedEvents);
        }
    };

    const handleAddRow = () => {
        setinputFields([...inputFields, {date: '', time: '', location: ''}]);
    };

    const handleEventChange = (field, value) => {
        setNewEvent({...newEvent, [field]: value});
    };

    const appendNewEvent = (updatedEvent) => {
        onEventChange(updatedEvent);
    };

    const validateForm = () => {
        const dateErrors = inputFields.map(row => !row.date);
        const timeErrors = inputFields.map(row => !row.time);
        const locationErrors = inputFields.map(row => !row.location);

        const errors = {
            name: !newEvent.name,
            description: !newEvent.description,
            detailedDescription: !newEvent.detailedDescription,
            date: dateErrors,
            time: timeErrors,
            location: locationErrors,
            publishDate: !newEvent.publishDate,
            publishTime: !newEvent.publishTime
        };

        setFormErrors(errors);

        return !Object.values(errors).some(error => Array.isArray(error) ? error.includes(true) : error);
    };

    const handleAddEvent = async () => {
        if (!validateForm()) {
            showPopup("Bitte alle erforderlichen Felder ausfüllen.", "error", "center");
            return;
        }

        const eventData = {
            name: newEvent.name,
            description: newEvent.description,
            detailed_description: newEvent.detailedDescription,
            publishDate: new Date(`${newEvent.publishDate}T${newEvent.publishTime}:00Z`),
            picture: "https://ticket4u.at/pictures/concert.jpg",
        };
        try {
            const createdEvent = await addEvent(eventData, organizer.id, setPopup);

            if (createdEvent && createdEvent.id) {
                const eventId = createdEvent.id;
                const dateData = inputFields.map(row => ({
                    date: new Date(`${row.date}T${row.time}:00Z`),
                    locationId: row.location,
                }));

                const eventDates = await addDatesToEventApiCall(eventId, dateData);

                const updatedEvent = {...createdEvent, eventDates};
                onEventChange(updatedEvent);
                console.log("updatedEvent: " + JSON.stringify(extractAnEventsImageData(updatedEvent)));
                appendNewEvent(updatedEvent);
                changeTab();

                setNewEvent({
                    name: '',
                    date: '',
                    description: '',
                    detailedDescription: '',
                    location: {id: '', description: ''},
                    publishDate: '', // Reset des Veröffentlichkeitsdatums (publishDate)
                    publishTime: ''   // Reset der Uhrzeit
                });
                setinputFields([{date: '', time: '', location: ''}]);
            } else {
                console.error('Failed to retrieve event ID');
            }
        } catch (error) {
            console.error('Error adding event and dates:', error);
        }
    };

    const addDatesToEventApiCall = async (eventId, dateData) => {
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/organizer/dates/${eventId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token'),
                },
                body: JSON.stringify(dateData),
            });
            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to add dates:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding dates to event:', error);
        }
    };

    const handleDeleteDateRow = (index) => {
        setinputFields(prevFields => prevFields.filter((_, i) => i !== index));
    };

    return (
        <div>
            {/* Add Event */}
            <Grid item xs={12} style={{marginBottom: '1%'}}>
                <Card sx={{borderRadius: '1rem', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'}}>
                    <CardContent>
                        {/* Titel */}
                        <Typography variant="h4" gutterBottom>
                            Event hinzufügen
                        </Typography>
                        <Box display="flex" alignItems="center" gap={2}>
                            {/* Description Box */}
                            <Box flex={1}>
                                <TextField
                                    label="Name des Events"
                                    value={newEvent.name}
                                    onChange={(e) => handleEventChange('name', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    error={formErrors.name}
                                />
                                <TextField
                                    label="Kurzbeschreibung"
                                    value={newEvent.description}
                                    onChange={(e) => handleEventChange('description', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={2}
                                    error={formErrors.description}
                                />
                                <TextField
                                    label="Detailierte Beschreibung"
                                    value={newEvent.detailedDescription}
                                    onChange={(e) => handleEventChange('detailedDescription', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={5}
                                    error={formErrors.detailedDescription}
                                />
                            </Box>
                            <Divider orientation="vertical" flexItem/>
                            {/* DateTime & Location Box */}
                            <Box flex="0 0 45%" sx={{position: 'relative'}}>
                                {/* Veröffentlichkeitsdatum und Uhrzeit */}
                                <Typography variant="h6" gutterBottom>
                                    Für Kunden sichtbar ab
                                </Typography>
                                <Box display="flex" gap={2}>
                                    <TextField
                                        label="Datum"
                                        type="date"
                                        value={newEvent.publishDate}
                                        onChange={(e) => handleEventChange('publishDate', e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={formErrors.publishDate}
                                    />
                                    <TextField
                                        label="Uhrzeit"
                                        type="time"
                                        value={newEvent.publishTime}
                                        onChange={(e) => handleEventChange('publishTime', e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={formErrors.publishTime}
                                    />
                                </Box>
                                <Typography variant="h6" gutterBottom sx={{marginTop: '16px'}}>
                                    Termine
                                </Typography>

                                {/* Scrollable Container für Event-Daten */}
                                <Box
                                    sx={{
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                        marginBottom: '20px',
                                        paddingRight: '10px',
                                        height: '200px'
                                    }}
                                >
                                    {inputFields.map((row, index) => (
                                        <Box key={index} display="flex" alignItems="center" gap={2} mt={2} mb={3}>
                                            <TextField
                                                label="Datum"
                                                type="date"
                                                value={row.date}
                                                onChange={(e) => handleDateTimeChange(index, 'date', e.target.value)}
                                                fullWidth
                                                style={{width: '40%'}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={formErrors.date[index]}
                                            />
                                            <TextField
                                                label="Uhrzeit"
                                                type="time"
                                                value={row.time}
                                                onChange={(e) => handleDateTimeChange(index, 'time', e.target.value)}
                                                fullWidth
                                                style={{
                                                    width: '20%',
                                                    flex: '0 1 auto'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={formErrors.time[index]}
                                            />
                                            <Autocomplete
                                                options={organizersLocations.length > 0
                                                    ? organizersLocations
                                                    : [{id: 'dummy', description: 'Bitte einen Standort erstellen'}]}
                                                getOptionLabel={(option) => option?.description || ''}
                                                value={organizersLocations.find(loc => loc.id === row.location) || null}
                                                onChange={(event, newValue) => handleDateTimeChange(index, 'location', newValue?.id === 'dummy' ? '' : newValue?.id || '')}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Standort"
                                                        fullWidth
                                                        error={formErrors.location[index]}
                                                    />
                                                )}
                                                style={{width: '35%'}}
                                            />

                                            {/* Delete-Button */}
                                            <button
                                                onClick={() => handleDeleteDateRow(index)}
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                <IconButton onClick={() => handleDeleteDateRow(index)}
                                                    className="text-red-500 hover:text-red-700">
                                                    <Delete color="error"/>
                                                </IconButton>
                                            </button>
                                        </Box>
                                    ))}
                                </Box>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleAddRow}
                                    style={{height: 'fit-content'}}
                                >
                                    Datum hinzufügen
                                </Button>
                            </Box>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddEvent}
                            style={{marginTop: '15px'}}
                        >
                            Event hinzufügen
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
}

export default AddEventComponent;
