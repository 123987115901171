import Header from "../General/HeaderComponents/Header";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import SeatMap from "../Customer/SeatingPlanView/SeatingPlanView";

const LocalSellerBookingComponent = ({events}) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();

    {/* Overview page */}
    if (!selectedEvent) {
        return (
            <div className="w-screen h-[100dvh] overflow-y-auto">
                <Header/>
                <div className="px-10 pt-10">
                    <h1 className="text-3xl font-bold mb-5">Verfügbare Events</h1>
                    <div className="grid gap-5">
                        {events.map((event) => (
                            <div key={event.id} className="mx-[10%] cursor-pointer p-5 border rounded-lg shadow-md bg-white-300"
                                 onClick={() => setSelectedEvent(event)}>
                                <h2 className="text-xl font-semibold">{event.name}</h2>
                                <p>{event.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    {/* Detailed information & booking page*/}
    return (
        <div className="max-w-full mx-auto">
            <Header/>
            <div className="px-10 pt-10 h-[100vh] overflow-y-auto">
                {/* Zurück button */}
                <button onClick={() => setSelectedEvent(null)} className="mb-5 px-4 py-2 bg-gray-200 rounded">
                    Zurück zur Übersicht
                </button>

                {/* Event Info & Image */}
                <div className="flex flex-col lg:flex-row justify-between items-center gap-5 mt-7">
                    {/* Event data */}
                    <div className="flex-1 text-center lg:text-left mb-3 lg:w-[50%] xl:w-[40%] max-w-[600px] md:max-w-[80%] break-words">
                        <h1 className="text-3xl font-bold">{selectedEvent.name}</h1>
                        <p className="text-lg">{selectedEvent.detailed_description}</p>
                        <p className="text-lg">Organisiert von: {selectedEvent.organizer.name}</p>
                    </div>

                    {/* Bild mit Blur & Farbverlauf */}
                    <div className="p-5 relative w-full sm:w-[80%] md:w-[60%] lg:w-[40%] h-[35vh] md:h-[30vh] sm:h-[25vh] flex justify-center items-center ml-0 lg:ml-[2%]">
                        {/* Hintergrund mit Blur */}
                        <div
                            className="absolute inset-0 bg-cover bg-center blur-xl scale-90"
                            style={{backgroundImage: `url(${selectedEvent.picture})`}}
                        />

                        {/* Farbverlauf für den sanften Übergang */}
                        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/40"/>

                        {/* img selbst */}
                        <img
                            src={selectedEvent.picture}
                            alt="Event Bild"
                            className="relative z-10 max-w-full h-auto rounded-lg"
                        />
                    </div>
                </div>

                {/* Date selection */}
                <div className="mt-5 flex justify-center">
                    <select className="p-3 border rounded-md"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedDate(value ? JSON.parse(value) : null);
                            }}>
                    <option value="">Termin wählen</option>
                        {selectedEvent.eventDates.map((date) => (
                            <option key={date.id} value={JSON.stringify(date)}>
                                {new Date(date.date).toLocaleDateString()} - {new Date(date.date).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit'
                            })} ({date.location.description})
                            </option>
                        ))}
                    </select>
                </div>

                {/* REPLACE WITH SEATING PLAN */}
                {selectedDate && (
                    <div className="pb-[8%]">
                        <SeatMap date={selectedDate} eventid={selectedEvent.id} isLocalSeller={true} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LocalSellerBookingComponent;